import React from "react";
import { Link } from "react-router-dom";
import styles from "./Sessions.module.css";
import moment from "moment";
const CompletedSessions = (props) => {
  const { allSessions, completedSession } = props;
  return (
    <React.Fragment>
      <div className="col-md-12 pad-0">
        <div className="row p-0">
          {
            completedSession.reverse().map((as) => {
              let start, end, startTime, endTime;
              if (as.start) {
                start = moment.parseZone(as.start).local().format("YYYY-MM-DD")
                startTime = moment.parseZone(as.start).local().format("HH:mm")
              }
              if (as.end) {
                end = moment.parseZone(as.end).local().format("YYYY-MM-DD")
                endTime = moment.parseZone(as.end).local().format("HH:mm")
              }
              if (as.status === "ACCEPTED") {
                return (
                  <div className="col-md-4 col-sm-6 px-2">
                    <div className={`cardDiv ${styles.sessionsDiv}`}>
                      <h2 style={{ fontFamily: "roboto" }}>{as.title}</h2>

                      <p style={{ fontFamily: "roboto" }}>{as.topic}</p>
                      <div className="ps-0">
                        <span className="me-2" style={{ color: "black",fontFamily:"roboto" }} >
                          Date -
                        </span>
                        {moment(as.start).format("DD-MM-YYYY")}
                      </div>

                      <div className="d-flex flex-wrap p-0">
                        <div className="me-3">
                          <span className="me-2" style={{ color: "black",fontFamily:"roboto" }}>
                            Start Time
                          </span>
                          <span style={{ color: "black",fontFamily:"roboto" }}>
                            {moment(as.start).format("hh:mm A")}
                          </span>
                        </div>
                        <div className=" ">
                          <span className="me-2" style={{ color: "black",fontFamily:"roboto" }}>
                            End Time
                          </span>
                          {/* <br /> */}
                          <span style={{ color: "black",fontFamily:"roboto" }}>
                            {moment(as.end).format("hh:mm A")}
                          </span>
                        </div>
                      </div>

                      {/* <button className="btn btn-sm">Subscribe</button> */}
                    </div>
                  </div>
                );
              }
            })}
                  </div>
      </div>
    </React.Fragment>
  );
};

export default CompletedSessions;
