import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { toCapitalWord } from "../../../utils/utils";
import { copyQuestion } from "../../../services/Admin/questionService";
import style from "./question.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Backdrop, CircularProgress } from "@mui/material";

const Curriculum = ({ questionsList, search, searchQuestion, open }) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <h1
          className="form-heading"
          style={{ color: "#4A1D75", fontFamily: "roboto" }}
        >
          Question Library
        </h1>
        {/* <div className="questionHeader"> */}
        {/* <div class="input-group row col-12   mb-2 "> */}
        {/* <div class="input-group-prepend"> */}
        {/* <div class="input-group-text" style={{ borderRadius: "40px" }}>
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchQuestion(text)}
              class="form-control"
              placeholder="Search Curriculum/Subject/Question"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
        {/* <div > */}
        {/* </div> */}
        {/* </div> */}
        {/* </div>
        <div>
          <Link
            to={`/admin/add-question`}
            className="btn btn-sm  add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            Add Question
          </Link>
        </div>
      </div> */}
        <div className="questionHeader">
          <div class="search">
            <span class="fa fa-search"></span>
            <input
              style={{ borderRadius: "30px" }}
              type="text"
              value={search}
              onChange={(text) => searchQuestion(text)}
              placeholder="Search "
            />
          </div>
          <div>
            <Link
              to={`/admin/add-question`}
              className="btn btn-sm  add-btn"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Add Question
            </Link>
          </div>
        </div>
        <div className="col-md-12 table_container">
          {/* <div className="card"> */}
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-">
                <thead>
                  <tr>
                    <th scope="col" style={{}}>
                      <span style={{ fontFamily: "roboto" }}> Question ID</span>
                    </th>
                    <th scope="col" style={{}}>
                      <span style={{ fontFamily: "roboto" }}> Question</span>
                    </th>
                    <th scope="col" style={{}}>
                      <span style={{ fontFamily: "roboto" }}>
                        {" "}
                        Question Type
                      </span>
                    </th>
                    <th scope="col" style={{}}>
                      <span style={{ fontFamily: "roboto" }}> Curriculum</span>
                    </th>
                    <th scope="col" style={{ color: "#20639B" }}>
                      <span style={{ fontFamily: "roboto" }}>Subject</span>
                    </th>
                    <th scope="col" style={{ color: "#20639B" }}>
                      <span style={{ fontFamily: "roboto" }}> Marks</span>
                    </th>
                    <th
                      scope="col"
                      style={{ color: "#20639B", minWidth: "240px" }}
                    >
                      <span style={{ fontFamily: "roboto" }}>Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {questionsList &&
                    questionsList.length > 0 &&
                    questionsList.reverse().map((q) => {
                      return (
                        <tr key={q._id}>
                          <td style={{ fontFamily: "roboto" }}>
                            {q.questionID}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>
                            {" "}
                            {q.question ? parse(q.question) : ""}{" "}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>
                            {q.questionType
                              ? toCapitalWord(q.questionType)
                              : ""}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>
                            {q.curriculum}
                          </td>
                          <td>{q.subject}</td>
                          <td style={{ fontFamily: "roboto" }}>
                            {" "}
                            {q.questionmarks}{" "}
                          </td>
                          <td>
                            <Link to={`/admin/edit-question/${q.questionID}`}>
                              <button
                                className="btn btn-sm mr-2"
                                style={{ backgroundColor: " #FFE7A8" }}
                              >
                                <span style={{ color: "#EAB731" }}>
                                  <BorderColorIcon
                                    style={{
                                      color: "#EAB731",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {" "}
                                    Edit
                                  </b>
                                </span>
                              </button>
                            </Link>
                            <Link to={`/admin/view-question/${q.questionID}`}>
                              <button
                                className="btn btn-sm ml-2"
                                style={{
                                  backgroundColor: "#DEF2E9",
                                  marginLeft: "10px",
                                }}
                              >
                                <span style={{ color: "#49AF48" }}>
                                  <RemoveRedEyeIcon
                                    style={{
                                      color: "#49AF48",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    View
                                  </b>
                                </span>
                              </button>
                            </Link>
                            {q.question[q.question.length - 1] ===
                            ")" ? null : (
                              <button
                                onClick={() => {
                                  async function test() {
                                    await copyQuestion(q._id);
                                    window.location.reload();
                                  }
                                  test();
                                }}
                                className="btn btn-sm "
                                style={{
                                  backgroundColor: " #E0F6F6",
                                  // marginTop: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                <span style={{ color: "#1DB9AA" }}>
                                  <CopyAllIcon
                                    style={{
                                      color: "#1DB9AA",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {" "}
                                    Copy
                                  </b>
                                </span>
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Curriculum;
