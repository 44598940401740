import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import styles from "./Assignments.module.css";
import Countdown from "react-countdown";
import { Button, Modal } from "react-bootstrap";
import { userID } from "../../../services/Authentication/AuthService";
import { CircularProgress } from "@material-ui/core";
import ChatBot from "../ChatBot/ChatBot";
const AssignmentPreview = ({
  assignmentIndividual,
  getTime,
  closeModal,
  meetingLink,
  getMeetingLink,
  studentTest,
}) => {
  const data = assignmentIndividual;
  console.log(data)

  const height = window.innerHeight - 155;
  const [showMss, setShowMss] = useState(true);
  const [show, setShow] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [testButton, setTestButton] = useState(true);
  const [loading, setloading] = useState(true);
  const [timer, setTimer] = useState(null);
  const history = useHistory();
  useEffect(async () => {
    timerr();
    console.log(show);
    if (data.testID) {
      let checkTest = await studentTest(data.testID, userID());
      if (checkTest) {
        setShow(checkTest.test ? false : true);
        setShowTest(checkTest ? checkTest.test : false);
        setTestButton(checkTest ? checkTest.test : false);
        setloading(false);
      }
    }
  }, [data]);
  if (!data) {
    return false;
  }

  const Completionist = () => {
    setShowMss(false);
    return <span>Start the Assessment now!</span>;
  };

  const timerr = () => {
    let event, now, remaning, h, m;
    try {
      if (data.startdate) {
        h = data.startTime.split(":")[0];
        m = data.startTime.split(":")[1];

        now = new Date().getTime();
        let ii = moment(data.startdate).format("MM/DD/YY");
        ii = ii + " " + h + ":" + m;

        let da = new Date(ii).getTime();
        remaning = da - now;
        if (remaning > 0) {
          let datee = Date.now() + remaning;
          setTimer(datee);
        } else {
          setShowMss(false);
        }
      }
    } catch (err) {
      console.log("error");
      // console.log(err)
    }
  };
  const getTestTime = () => {
    let now = getTime();
    let h, m, event;

    if (data.startdate) event = new Date();
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let da = new Date(event.toISOString());
    // let enGB = (da.toLocaleDateString('en-GB', options))

    // let time = moment(data.startdate).format("DD/MM/YYYY hh:mm")
    h = data.startdate.split("T")[1].split(":")[0];
    m = data.startdate.split("T")[1].split(":")[1];

    if (h < now.hours && m < now.min) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {!loading ? (
        <>
          <div className={`row ${styles.assignmentMain}`}>
            <div className="col-md-12 my-3">
              <div className="row pt-3">
                <div className="col-md-4"></div>
                <div className="col-md-4"><h2 className="text-center">Assessment Details</h2></div>
                <div className="col-md-4 text-end">
                </div>
              </div>
             <div className={`${styles.test}`}>
              <div className="w-100 d-flex justify-content-between">
              {data?.addressData?.length === 0 ?<button
                    className={`btn btn-success me-3`}
                    onClick={() => {
                      history.push(
                        `/student/test-view/${data.testID}`,
                        "_blank"
                      );
                      setloading(true);
                    }}
                    style={{ fontFamily: "roboto",padding:'7px 15px',fontSize:'16px' }}
                  >
                    Start Assessment
                  </button>:""}
              
                  <Link to={`/student/assignments`}>
                  <button
                    className={`btn btn-secondary theam-color ${styles.testBack1}`}
                  >
                    Back
                  </button>
                </Link>
              </div>
             <div
                className={`${styles.assignmentPreviewDiv} mt-4 py-5`}
              
              >
                <div
                  className="col-md-8 pad-0"

                >
                  <div style={{ margin: "40px 0" }}>

                    <table
                      class="table"
                      style={{ marginLeft: "10px" }}
                    >
                      <tr style={{ padding: "0 0 5px 0" }}>
                        <th
                          style={{ padding: "0 0 5px 0", fontFamily: "roboto" }}
                        >
                          Assessment Name
                        </th>
                        <td style={{ fontFamily: "roboto" }}>
                          {data.assessmentName ? data.assessmentName : ""}
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{ padding: "0 0 5px 0", fontFamily: "roboto" }}
                        >
                          Curriculum Name
                        </th>
                        <td style={{ fontFamily: "roboto" }}>
                          {data.curriculum}
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{ padding: "0 0 5px 0", fontFamily: "roboto" }}
                        >
                          Subject Name
                        </th>
                        <td style={{ fontFamily: "roboto" }}>
                          {data.subject ? data.subject : ""}
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{ padding: "0 0 5px 0", fontFamily: "roboto" }}
                        >
                          Start Date &nbsp;<small>(DD/MM/YYYY)</small>
                        </th>
                        <td style={{ fontFamily: "roboto" }}>
                          {moment(data.startdate).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{ padding: "0 0 5px 0", fontFamily: "roboto" }}
                        >
                          End Date &nbsp;<small>(DD/MM/YYYY)</small>
                        </th>
                        <td style={{ fontFamily: "roboto" }}>
                          {moment(data.enddate).format("DD/MM/YYYY")}
                        </td>
                      </tr>
                      {data.addressData.length !== 0 ? <tr>
                        <th style={{fontFamily: "roboto"}}>Address</th>
                        {`${data.addressData[0].area} ${data.addressData[0].branch} ${data.addressData[0].city} ${data.addressData[0].country}`}
                      </tr>:""}
                     
                    </table>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // border: "2px solid red",
                        width: "94%",
                      }}
                    >
                      <div
                        className="col-md-10 "
                        style={{ marginLeft: "10px" }}
                      >
                        {testButton ? (
                          <></>
                        ) : (
                          <>
                            {/* <Link
                             to={`/student/test-view/${data.testID}`}
                              onClick={() => {
                                

                                window.open(
                                  `/#/student/test-view/${data.testID}`
                                
                                );
                                setloading(true);
                              }}
                            > */}

                            {/* </Link> */}
                          </>
                        )}
                      </div>
                      {/* <div className={` col-md-5 `} style={{}}>
                        <Link to={`/student/assignments`}>
                          <button
                            className={`btn btn-sm btn-secondary theam-color mr-0 `}
                            style={{
                              marginRight: "20px",
                              fontFamily: "roboto",
                            }}
                          >
                            Back
                          </button>
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* 
          <div className="col-md-10 text-center">
            <Link
              onClick={() => {
               

                window.open(`/#/student/test-view/${data.testID}`, "_blank");
              }}
            >
              <button
                disabled={showMss}
                className={`btn btn-sm btn-success ${styles.testStart}`}
              >
                Start Assessmentest
              </button>
            </Link>
          </div> */}
              </div>
             </div>
            </div>
            {/* <Modal show={show}>
              <Modal.Header>
                <Modal.Title>Instructions</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ol>
                  <li>Make sure you have good internet connection</li>
                  <li>Make sure to join Teams meet and share your screen</li>
                  <li>Do not minimize the browser during Assessment</li>
                  <li>
                    Time remaning-{" "}
                    {timer && (
                      <Countdown date={timer}>
                        <Completionist />
                      </Countdown>
                    )}
                  </li>
                </ol>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={closeModal}>
                  Go Back
                </Button>

                <Button
                  variant="primary"
                  onClick={() =>
                    getMeetingLink().then((res) => {
                      setShowMss(false);
                      setShow(false);
                      // setloading(true)
                    })
                  }
                >
                  Join teams Meeting
                </Button>
              </Modal.Footer>
            </Modal> */}
            <Modal show={showTest}>
              <Modal.Header>
                <Modal.Title style={{ fontFamily: "roboto" }}>
                  ALERT
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li style={{ fontFamily: "roboto" }}>
                    You have already completed the Assessment. Please try again
                    later
                  </li>
                  <li style={{ fontFamily: "roboto" }}>
                    Once you submit the Assessment, it will be considered as
                    completed.
                  </li>
                </ul>
                <div style={{ marginLeft: "20px" }}>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      fontFamily: "roboto",
                    }}
                  >
                    Important:-
                  </label>
                  <p style={{ fontFamily: "roboto" }}>
                    The Assessment can only be taken once. Attempting to retake
                    the Assessment <br />
                    is not allowed.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => setShowTest(false)}
                  style={{ fontFamily: "roboto" }}
                >
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <CircularProgress />
          </div>
        </>
      )}
    </>
  );
};

export default AssignmentPreview;
