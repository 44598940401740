import React, { useState, useEffect, Fragment } from "react";
import Profile from "../../components/Share/Profile"
import { getUserInfo, } from "../../services/Admin/commonService";
import { userID } from "../../services/Authentication/AuthService";
import { getUserCurriculum } from "../../services/Student/CurriculumStudentService"


const StudentProfileContainer = props => {
    const [userInfo, setUserInfo] = useState('')
    const [usercur, setUserCur] = useState('')
    const [userCurCode, setUserCurCode] = useState('')
    const [curList, setList] = useState([])
    useEffect(() => {
        getUser()
        getcur()
    }, [])

    const getUser = () => {
        getUserInfo(+userID()).then(res => {
            if (res.status === 200) {
                console.log(res.data.data)
                setUserInfo(res.data.data)

            } else {
                setUserInfo('')
            }
        }).catch(err => { console.log('err') })
    }
    const getcur = () => {
        getUserCurriculum(userID()).then(res => {
            let curArr = [];
            let curCode = [];
            let cur = []
            console.log(res.data.data)
            let currectData = res.data.data.filter(e => new Date(e.endDate).getTime() <= new Date().getTime())
            currectData.map((e) => {
                curArr.push(e.curriculum);
                curCode.push(e.curriculumCode);
                cur.push(e);
            })

            setUserCur(curArr.toString())
            setUserCurCode(curCode.toString())
            setList(cur);
        })
    };
    console.log(userInfo, "<-info");

    return <Fragment>
        {userInfo && <Profile role={userInfo.role}
            firstName={userInfo.firstName}
            middleName={userInfo.middleName}
            lastName={userInfo.lastName}
            mobile={userInfo.mobile}
            dob={userInfo.dob}
            email={userInfo.email}
            gender={userInfo.gender}
            curriculum={usercur}
            curriculumCode={userCurCode}
            address={userInfo.address}
            country={userInfo.country}
            state={userInfo.state}
            city={userInfo.city}

            list={curList}
        />
        }
    </Fragment>
}

export default StudentProfileContainer;