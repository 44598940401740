import React, { useEffect, useRef, useState } from "react";
import "./AddAddress.css";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "react-bootstrap";
import { addAddressService } from "../../../services/Admin/AddressService";
import { getCurriculumList } from "../../../services/Admin/commonService";
import { getSubjectsByCur } from "../../../services/Admin/commonService";
import { Country, State, City } from "country-state-city";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AddAddress() {
  const history = useHistory();
  const addresData = useRef({
    curriculum: "",
    curriculumCode: "",
    subject: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    houseNo: "",
    area: "",
    nearBy: "",
    university: "",
    branch: "",
    room_no: "",
    floor: "",
    block: "",
    title: "",
  });
  const [curriculum, setCurriculum] = useState([]);
  const [subject, setSubject] = useState([]);
  let [country, setcountry] = useState([]);
  let [changes, setChanges] = useState([]);
  let [loader, setLoader] = useState(false);
  let [state, setstate] = useState([]);
  let [city, setcity] = useState([]);
  let [countrycode, setcountrycode] = useState("");
  let [Statedis, setStatedis] = useState(false);
  let [Citydis, setCitydis] = useState(false);

  
  function trimStrings(obj) {
    if (Array.isArray(obj)) {
      return obj.map(trimStrings);
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, trimStrings(value)])
      );
    } else if (typeof obj === 'string') {
      return obj.trim();
    } else {
      return obj;
    }
  }
  
  async function addAddress(e) {
    e.preventDefault();
    setLoader(true);
  
    const trimmedData = trimStrings({
      ...addresData.current,
      facilities: items
    });
  
    console.log(trimmedData);
  
    await addAddressService(trimmedData).then(() => {
      history.push("/admin/address");
      setLoader(false);
    });
  }
  const [inputText, setInputText] = useState("");
  const [items, setItems] = useState([]);

  const addItem = () => {
    if (inputText.trim() === "") {
      alert("Please enter a valid item.");
      return;
    }

    setItems([...items, inputText]);
    setInputText("");
  };

  const removeItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  async function curriculumLists() {
    const data = await getCurriculumList().then((e) => {
      return e.data.data;
    });
    setCurriculum(data);
    console.log(data);
  }
  async function subjectLists(curr) {
    addresData.current.curriculumCode = curr;
    const data = await getSubjectsByCur({ curriculumCode: curr }).then((e) => {
      return e.data.data;
    });
    setSubject(data);
  }

  function countrychange(e) {
    setStatedis(true);
    setcountrycode(e.split(",")[0]);
    addresData.current.country = e.split(",")[1];
    setChanges(e);
    addresData.current.state = "";
    addresData.current.city = "";
    // setDataa(e.split(",")[1])
    setstate([...State.getStatesOfCountry(e.split(",")[0])]);
  }

  function statechange(e) {
    setCitydis(true);
    // setStateData(e.split(",")[1]);
    addresData.current.state = e.split(",")[1];
    addresData.current.city = "";
    setcity([...City.getCitiesOfState(countrycode, e.split(",")[0])]);
  }
  function ss(e, c) {
    let sta = e.filter((res) => res.name == addresData.current.state);
    addresData.current.state = `${sta[0]?.isoCode},${sta[0]?.name}`;
    let arr = [
      ...City.getCitiesOfState(c, addresData?.current?.state.split(",")[0]),
    ];
    setcity([...arr]);
  }
  function citychange(e) {
    // setCityData(e)
    addresData.current.city = e;
  }
  useEffect(() => {
    curriculumLists();

    setcountry([...Country.getAllCountries()]);

    let coun = country.filter((res) => res.name == addresData.current.country);
    setcountrycode(coun[0]?.isoCode);
    addresData.current.country = `${coun[0]?.isoCode},${coun[0]?.name}`;
    let arr = [
      ...State.getStatesOfCountry(addresData?.current?.country.split(",")[0]),
    ];
    setstate([...arr]);
    ss(arr, coun[0]?.isoCode);
  }, [Country, State, City]);
  console.log(country);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container ">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            width: "98%",
            alignItems: "center",
          }}
        >
          <div>
            <h1
              className="form-heading"
              style={{ color: "#4A1D75", fontFamily: "roboto" }}
            >
              Add Address
            </h1>
          </div>
          <div
          // className="add-btn-div"
          >
            <Link
              to={`/admin/address`}
              className="btn btn-sm "
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Cancel
            </Link>
          </div>
        </div>

        <div className="container23 px-5 border pb-5">
          <form onSubmit={addAddress}>
            <div className="row pt-3">
              <div className="col-md-4">
                <label htmlFor="inputname">Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Enter Your Title"
                  required
                  defaultValue={addresData?.current?.title}
                  onChange={(e) => (addresData.current.title = e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">Curriculum Name</label>
                <select
                  className="form-select"
                  required
                  onChange={(e) => {
                    let curriculumCode = e.target.value;
                    subjectLists(e.target.value);
                    let data = curriculum?.filter(
                      (s) => s?.curriculumCode === curriculumCode
                    );
                    addresData.current.curriculum = data[0]?.curriculum;
                  }}
                >
                  <option value={"Select"} required selected disabled>
                    {"Select "}
                  </option>

                  {curriculum.map((e) => (
                    <option value={e?.curriculumCode}>{e?.curriculum}</option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">Subject Name</label>
                <select
                  className="form-select"
                  onChange={(e) => {
                    addresData.current.subject = e.target.value;
                  }}
                >
                  <option value={"Select"} selected disabled>
                    {"Select "}
                  </option>
                  {subject.map((e) => (
                    <option value={e?.subject}>{e?.subject}</option>
                  ))}
                </select>
                <KeyboardArrowDownIcon className="drop_icon" />
              </div>
            </div>

            <div className="row pt-2">
              <div className="col-md-4">
                <label htmlFor="inputname">Country Name</label>
                <select
                  className="select-box"
                  required
                  value={changes}
                  onChange={(e) => countrychange(e.target.value)}
                >
                  <option value="" disabled={Statedis}>
                    {" "}
                    Select country{" "}
                  </option>{" "}
                  {country &&
                    country.map((e, index) => {
                      return (
                        <option key={index} value={`${e.isoCode},${e.name}`}>
                          {e.name}
                        </option>
                      );
                    })}
                </select>
                <KeyboardArrowDownIcon className="drop_icon" />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">State Name</label>
                <select
                  className="form-select"
                  required
                  onChange={(e) => statechange(e.target.value)}
                >
                  <option value="" disabled={Statedis}>
                    {" "}
                    Select State{" "}
                  </option>
                  {state &&
                    state.map((e, index) => {
                      return (
                        <option key={index} value={`${e.isoCode},${e.name}`}>
                          {e.name}
                        </option>
                      );
                    })}
                </select>
                <KeyboardArrowDownIcon className="drop_icon" />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">City Name</label>
                <select
                  className="form-select"
                  onChange={(e) => citychange(e.target.value)}
                >
                  <option value="" disabled={Citydis}>
                    {" "}
                    Select City{" "}
                  </option>
                  {city &&
                    city.map((e, index) => {
                      return (
                        <option key={index} value={e.name}>
                          {e.name}
                        </option>
                      );
                    })}
                </select>
                <KeyboardArrowDownIcon className="drop_icon" />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-4">
                <label htmlFor="inputname">Campus</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Enter Your Campus"
                  required
                  defaultValue={addresData?.current?.branch}
                  onChange={(e) => (addresData.current.branch = e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">University</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Enter Your University"
                  required
                  defaultValue={addresData?.current?.university}
                  onChange={(e) =>
                    (addresData.current.university = e.target.value)
                  }
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">Block</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Enter Your Block"
                  required
                  defaultValue={addresData?.current?.block}
                  onChange={(e) => (addresData.current.block = e.target.value)}
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-4">
                <label htmlFor="inputname">Area</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Area"
                  required
                  defaultValue={addresData?.current?.area}
                  onChange={(e) => (addresData.current.area = e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">Near By</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Near By"
                  required
                  defaultValue={addresData?.current?.nearBy}
                  onChange={(e) => (addresData.current.nearBy = e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">Building No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Building No"
                  required
                  defaultValue={addresData?.current?.houseNo}
                  onChange={(e) =>
                    (addresData.current.houseNo = e.target.value)
                  }
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-md-4">
                <label htmlFor="inputname">Floor No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ente Your Floor"
                  required
                  defaultValue={addresData?.current?.floor}
                  onChange={(e) => (addresData.current.floor = e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">Room No</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Room No"
                  required
                  defaultValue={addresData?.current?.room_no}
                  onChange={(e) =>
                    (addresData.current.room_no = e.target.value)
                  }
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">Pincode</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Your Pincode"
                  required
                  defaultValue={addresData?.current?.pincode}
                  onChange={(e) =>
                    (addresData.current.pincode = e.target.value)
                  }
                />
              </div>

              <div className="row">
                <div className="col-md-4 mt-2 px-0 ">
                  <label htmlFor="inputname">Add Facilities</label>
                  <div className="add-facalities-div">
                    <input
                      className="form-control custom-css-add"
                      type="text"
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                      placeholder="Enter Text"
                    />
                    <button type="button" onClick={addItem} className="add-btn">
                      Add
                    </button>
                  </div>

                  <div
                    style={{
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {items.map((item, index) => (
                      <div key={index} className="add-input-div">
                        <span>{item}</span>
                        <span
                          style={{ cursor: "pointer", color: "red" }}
                          onClick={() => removeItem(index)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-sm  mt-4"
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
