import React from 'react';
import { useHistory } from "react-router-dom";


const QueryType = (props) => {
    const { queryType } = props
    const path = useHistory().location.pathname
    console.log('hos' + path)
    return (
      <div className="p-3 pt-2">
        <div className="row col-md-12 p-3 pb-0 query_head">
          <div
            className={`tabItem ${
              path === "/teacher/queries-list" ? "tabItemActive" : ""
            } mb-3`}
            onClick={() => queryType("open")}
            style={{ fontFamily: "roboto" }}
          >
            OPEN
          </div>
          <div
            className={`tabItem ${
              path === "/teacher/queries-inprogess" ? "tabItemActive" : ""
            } mb-3`}
            onClick={() => queryType("inprogress")}
            style={{ fontFamily: "roboto" }}
          >
            INPROGRESS
          </div>

          <div
            className={`tabItem ${
              path === "/teacher/queries-closed" ? "tabItemActive" : ""
            } mb-3`}
            onClick={() => queryType("closed")}
            style={{ fontFamily: "roboto" }}
          >
            CLOSED
          </div>
        </div>
      </div>
    );
}
export default QueryType;