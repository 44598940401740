import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "../../../common/select";

import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
} from "../../../utils/utils";
import quer from "../../../assets/Banners/group3.png";
import styles from "./Curriculum.module.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Modal, Dropdown, Button } from "react-bootstrap";
import ChatBot from "../ChatBot/ChatBot";

const Curriculum = ({
  curriculumList,
  subjectsList,
  topicsList,
  chaptersList,
  handleInput,
  curriculumData,
  handleClose,
  handleShow,
  modalShow,
  chapterRaiseQuery,
  chapterRequestSession,
}) => {
  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  console.log(chaptersList);
  const [modalShows, setModel] = useState(false)
function handleCloses(){
  setModel(false)
}
  const [urls, setUrl] = useState("")
  return (
    <React.Fragment>
      <div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShows}
          backdrop="static"
          onHide={handleCloses}
          
        >
          <Modal.Header closeButton= {handleCloses}  >
            <h5 className="mt-1 mb-0">{"PDF"}</h5>
          </Modal.Header>
          <Modal.Body className={styles.modalBody}>
            <div className="row" ><iframe src={urls+"#toolbar=0"} style={{height:"450px"}}/>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="row mb-5">
        {/* <div className="position-relative mb-5 w-100"> */}
        <div className={`${styles.imagetheme}`}>
          {/* <img src={quer} alt="bannerImg" className="w-100" /> */}
        </div>
        {/* </div> */}
      </div>
      <div className="row px-4">
        <div className="col-md-4">
          <div className={styles.relative}>
            <Select
              name="curriculum"
              optname="curriculum"
              label="Curriulum Name"
              placeholder="Select Curriculum"
              value={curriculumData.curriculum ? curriculumData.curriculum : ""}
              options={curriculumOptions}
              onChange={(e) => handleInput(e, "curriculum")}
              className=""
              type=""
            />
            <KeyboardArrowDownIcon className={styles.drop_icon} />
          </div>
        </div>

        <div className="col-md-4">
          <div className={styles.relative}>
            <Select
              name="subject"
              optname="subject"
              label="Subject Name"
              placeholder="Select Subject"
              value={curriculumData.subject ? curriculumData.subject : ""}
              options={subjectOptions}
              onChange={(e) => handleInput(e, "subject")}
            />
            <KeyboardArrowDownIcon className={styles.drop_icon} />
          </div>
        </div>

        <div className="col-md-4">
          <div className={styles.relative}>
            <Select
              name="topic"
              optname="topic"
              label="Topic Name"
              placeholder="Select Topic"
              value={curriculumData.topic ? curriculumData.topic : ""}
              options={topicOptions}
              onChange={(e) => handleInput(e, "topic")}
              className={`${styles.selectinput}`}
            />
            <KeyboardArrowDownIcon className={styles.drop_icon} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className={styles.chaptersDiv}>
            {/* <h4 className="text-heading ml-0 mt-2 mb-3">Chapters List</h4> */}
            <div className={styles.resTable}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {/* <th style={{ width: "15%" }}>Chapter Name</th>
                  <th style={{ width: "15%" }}>Video Link</th>
                  <th style={{ width: "15%" }}>Raise</th>

                  <th style={{ width: "15%" }}>Reading Material</th>
                  <th style={{ width: "15%" }}>Exercise Material</th> */}

                    {/* <th>History</th> */}
                  </tr>
                </thead>
                <tbody>
                  {chaptersList !== undefined &&
                    chaptersList.length > 0 &&
                    chaptersList.map((c, i) => {
                      console.log("cccc", c);
                      return (
                        <tr key={i + 1}>
                          <td style={{ fontFamily: "roboto" }}>{c.chapter}</td>
                          <td>
                            <Link to={"#"} onClick={() => handleShow(c)}>
                              Link
                            </Link>
                          </td>
                          <td>
                            <button
                              onClick={() => chapterRaiseQuery(c.chapter)}
                              className="btn btn-primary btn-sm me-md-4 me-0 mb-2 md-md-0 mb-sm-0 me-sm-2 theam-color"
                              style={{ fontFamily: "roboto" }}
                            >
                              Raise Query
                            </button>
                            <button
                              onClick={() => chapterRequestSession(c.chapter)}
                              className="btn btn-primary btn-sm  theam-color"
                              style={{ fontFamily: "roboto" }}
                            >
                              Request Session
                            </button>
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-button-dark-example1"
                                variant="secondary"
                              >
                                Reading Material
                              </Dropdown.Toggle>

                              <Dropdown.Menu variant="dark">
                                {c.norArr &&
                                  c.norArr.length > 0 &&
                                  c.readArr.map((data) => {
                                    console.log("999999999999999999999999999");
                                    // return <a href={data.location} target="_blank">{data.name}</a>
                                    return (
                                      <React.Fragment>
                                        <Dropdown.Item
                                          // href={data.location}
                                          // target="_blank"
                                          onClick={() => {
                                           
                                            setUrl(data.location);
                                            setModel(true)
                                          }}
                                        >
                                          {data.name}
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                      </React.Fragment>
                                    );
                                  })}
                                {c.norArr && c.norArr.length <= 0 && (
                                  <React.Fragment>
                                    <Dropdown.Item>
                                      No Reading Material
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                  </React.Fragment>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* <Select></Select> */}
                            {/* <a href={} download="reading material">Read here</a> */}
                          </td>
                          <td style={{ width: "15%" }}>
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-button-dark-example1"
                                variant="secondary"
                              >
                                Exercise Material
                              </Dropdown.Toggle>

                              <Dropdown.Menu variant="dark">
                                {c.exercise &&
                                  c.exercise.length > 0 &&
                                  c.exercise.map((data) => {
                                    // return <a href={data.location} target="_blank">{data.name}</a>
                                    return (
                                      <React.Fragment>
                                        <Dropdown.Item
                                          // href={data.location}
                                          // target="_blank"
                                          onClick={() => {
                                            
                                            setUrl(data.location);
                                            setModel(true)
                                          }}
                                        >
                                          {data.name}
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                      </React.Fragment>
                                    );
                                  })}
                                {c.exercise && c.exercise.length <= 0 && (
                                  <React.Fragment>
                                    <Dropdown.Item>
                                      No Exercise Material
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                  </React.Fragment>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>

                            {/* <a href={} download="reading material">Read here</a> */}
                          </td>

                          {/* <td>
                          <div className={styles.progress}>
                            <div
                              className={`progress-bar ${styles.progressBar}`}
                              style={{ width: "30%" }}
                            ></div>
                          </div>
                        </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <br />
            {/* <div className={styles.box}>
              <div className="col-md-12 mb-4">
                <span className={`${styles.curri_headings}`}>Chapter Name</span>
                <div className={styles.txt}>
                  <p>Enter Chapter Name</p>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <span className={`${styles.curri_headings}`}>Video Link</span>
                <div className={styles.txt}>
                  <p>Enter Video link</p>
                </div>
                <div
                  className={`${styles.area} mt-3 d-flex justify-content-center align-items-center px-4`}
                >
                  <input type="file"></input>
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <span className={styles.curri_headings}>Raise Query</span>
                <div className={styles.area}>
                  <p></p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Curriculum;
