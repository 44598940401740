import React from "react";
import parse from "html-react-parser";
import moment from "moment";
import { Backdrop, CircularProgress } from "@mui/material";


const ViewCurriculm = ({
  curriculumListIndividual,
  closeViewCurriculum,
  deleteEntireCurriculum,
  open,
}) => {
  const data = curriculumListIndividual;
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            View Curriculum
          </h3>
          <button
            className="btn btn-sm btn-danger "
            onClick={deleteEntireCurriculum}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-sm btn-secondary mr-2"
            onClick={closeViewCurriculum}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>

        <div className="col-md-12">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="col-12 d-flex  align-items-center">
                  <div>
                    <h5 className="m-2" style={{ fontFamily: "roboto" }}>
                      Organization Logo
                    </h5>
                    <div className="logo d-flex m-2  justify-content-center align-items-center">
                      {/* {! data.imgLink && } */}
                      {data.imgLink ? (
                        <>
                          <img className="imageLogo" src={data.imgLink} />
                        </>
                      ) : (
                        <>
                          <p>No Image Available</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Organization</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.organizationName ? data.organizationName : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Organization Code
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.organizationCode ? data.organizationCode : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.curriculum ? data.curriculum : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Award
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.curriculumAward ? data.curriculumAward : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Code
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.curriculumCode ? data.curriculumCode : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Short Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.shortName ? data.shortName : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Long Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.longName ? data.longName : ""}
                  </span>
                </div>
                <div className="clearfix"></div>
                {/* <div className="text-view">
                <label>
                  Created Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>
                  {data.createDate
                    ? moment(data.createDate).format("MM-DD-YYYY")
                    : ""}
                </span>
              </div> */}
                {/* <div className="text-view">
                <label>
                  Effective Date <small>(DD-MM-YYYY)</small>
                </label>
                <span>
                  {data.effectiveDate
                    ? moment(data.effectiveDate).format("MM-DD-YYYY")
                    : ""}
                </span>
              </div> */}
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Start Date <small>(DD-MM-YYYY)</small>
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.startDate
                      ? moment(data.startDate).format("MM-DD-YYYY")
                      : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    End Date <small>(DD-MM-YYYY)</small>
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.endDate
                      ? moment(data.endDate).format("MM-DD-YYYY")
                      : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Duration <small>(in Years)</small>
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.duration ? data.duration : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Eligibility</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.eligibility ? parse(data.eligibility) : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Pass Criteria</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.passCriteria ? parse(data.passCriteria) : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Price</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.price ? data.price : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCurriculm;
