
import moment from "moment";
import parse from "html-react-parser";
import { deleteSubject } from "../../../services/Admin/subjectService";
import { Backdrop, CircularProgress } from "@mui/material";


const ViewSubject = ({ subjectIndividual, closeViewCourse, open }) => {
  const deleteSubjects = (e) => {
    deleteSubject({
      curriculum: e.curriculum,
      curriculumCode: e.curriculumCode,
      subject: e.subject,
    });
    console.log(e.curriculum, e.curriculumCode, e.subject, "dataaaaaaaaaaaaaa");
  };
  const data = subjectIndividual ? subjectIndividual : [];

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            View Subject
          </h3>
          <button
            className="btn btn-sm "
            onClick={() => {
              deleteSubjects(data);
              closeViewCourse();
            }}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-sm  mr-2"
            onClick={closeViewCourse}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "10px",
            }}
          >
            Close
          </button>
        </div>

        <div className="col-md-12">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.curriculum}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Code
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.curriculumCode}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.subject}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject Info</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.subjectInfo ? parse(data.subjectInfo) : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Start Date <small>(DD-MM-YYYY)</small>
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && moment(data.startDate).format("MM-DD-YYYY")}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    End Date <small>(DD-MM-YYYY)</small>
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && moment(data.endDate).format("MM-DD-YYYY")}
                  </span>
                </div>
                {/* <div className="text-view">
                <label>Min Credits</label>
                <span>{data && data.minCredits}</span>
              </div>
              <div className="text-view">
                <label>Max Credits</label>
                <span>{data && data.maxCredits}</span>
              </div>
              <div className="text-view">
                <label>Practical Session</label>
                <span>{data && data.practicalSession}</span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSubject;
