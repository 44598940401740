import React from "react";
import { Link } from "react-router-dom";
import style from "./video.css"
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";


const Video = ({ videosList, deleteVideo, search, searchVideo }) => {
  console.log(videosList);
  return (
    <div className="row">
      <h1
        className="form-heading"
        style={{ color: "#4A1D75", fontFamily: "roboto" }}
      >
        Videos
      </h1>

      {/* <div className="add-btn-div d-flex   flex-row-reverse  add-btn">
        <Link
          to={`/admin/add-video`}
          className="btn btn-sm btn-primary  col-1 "
        >
          Add Video
        </Link>
        <Link
          to={`/admin/add-read`}
          className="btn btn-sm btn-primary mr-2 col-1"
        >
          Add reading Material
        </Link>
      </div> */}
      {/* <div className="add-btn-div">
        
     
      </div> */}
      {/* <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "40%" }}
            />
          </div>
        </div>
        <input
          type="text"
          value={search}
          onChange={(text) => searchVideo(text)}
          class="form-control"
          placeholder="Search Video/Curriculum/Subject/Topic"
        />
      </div> */}
      <div className="videoHeader">
        <div class="search">
          <span class="fa fa-search"></span>
          <input
            placeholder="Search"
            style={{ borderRadius: "30px" }}
            type="text"
            value={search}
            onChange={(text) => searchVideo(text)}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <Link
              to={`/admin/add-exercise`}
              className="btn btn-sm mr-2"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "200px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}
            >
              Add Exercise Material
            </Link>
          </div>
          <div>
            {" "}
            <Link
              to={`/admin/add-video`}
              className="btn btn-sm   "
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}
            >
              Add Video
            </Link>
          </div>
          <div>
            <Link
              to={`/admin/add-read`}
              className="btn btn-sm  mr-2"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "200px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}
            >
              Add Reading Material
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-12 table_container">
        {/* <div className="card"> */}
        <div className="card-body border scroll-div overflowList">
          <table className="table table">
            <thead>
              <tr>
                <th style={{ fontFamily: "roboto" }}>Video ID</th>
                <th style={{ fontFamily: "roboto" }}>Video Name</th>
                <th style={{ fontFamily: "roboto" }}>Curriculum</th>
                <th style={{ fontFamily: "roboto" }}>Subject</th>
                {/* <th >Topic</th>
                  <th >Chapter</th> */}
                <th style={{ fontFamily: "roboto" }}>Read file</th>
                <th style={{ fontFamily: "roboto" }}>Exercise file</th>
                <th style={{ fontFamily: "roboto",minWidth:"220px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {videosList &&
                videosList.reverse().map((v) => {
                  return (
                    <tr key={v._id}>
                      <td style={{ fontFamily: "roboto" }}>{v.videoID}</td>
                      <td style={{ fontFamily: "roboto" }}>
                        <a
                          href={v.fileLocation}
                          target="_blank"
                          style={{ fontFamily: "roboto" }}
                        >
                          {v.videoName}
                        </a>
                      </td>
                      <td style={{ fontFamily: "roboto" }}>{v.curriculum}</td>
                      <td style={{ fontFamily: "roboto" }}>{v.subject}</td>
                      {/* <td>{v.topic}</td>
                        <td>{v.chapter}</td> */}
                      {v.readArr && (
                        <td>
                          {v.readArr.map((data) => {
                            return (
                              <a href={data.location} target="_blank">
                                <p style={{ fontFamily: "roboto" }}>
                                  {data.name}
                                </p>
                              </a>
                            );
                          })}
                        </td>
                      )}
                      {!v.readArr && <td>No file</td>}
                      {v.exercise && (
                        <td>
                          {v.exercise.map((data) => {
                            return (
                              <a href={data.location} target="_blank">
                                <p style={{ fontFamily: "roboto" }}>
                                  {data.name}
                                </p>
                              </a>
                            );
                          })}
                        </td>
                      )}
                      <td
                       
                      >
                        {/* <div className="d-flex justify-content-between"> */}
                        <Link to={`/admin/edit-video/${v._id}`}>
                          <button
                            className="btn btn-sm btn-info"
                            style={{
                              backgroundColor: "#FFE7A8",
                              marginRight: "8px",
                            }}
                          >
                            <span style={{ color: "#EAB731" }}>
                              <BorderColorIcon
                                style={{
                                  color: "#EAB731",
                                  fontSize: "13px",
                                }}
                              />
                              <b
                                style={{
                                  fontSize: "11px",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {" "}
                                Edit
                              </b>
                            </span>
                          </button>
                        </Link>
                        <Link to={`/admin/view-video/${v._id}`}>
                          <button
                            className="btn btn-sm btn-info"
                            style={{
                              backgroundColor: "rgb(222, 242, 233)",
                              marginRight: "8px",
                            }}
                          >
                            <span style={{ color: "rgb(73, 175, 72)" }}>
                              <RemoveRedEyeIcon
                                style={{
                                  color: "rgb(73, 175, 72)",
                                  fontSize: "13px",
                                }}
                              />
                              <b
                                style={{
                                  fontSize: "11px",
                                  fontFamily: "Roboto",
                                }}
                              >
                                View
                              </b>
                            </span>
                          </button>
                        </Link>
                        {/* <button
                              className="btn btn-danger btn-lg mr-2"
                              onClick={() => deleteVideo(v.videoID, v.fileName)}
                              style={{
                                backgroundColor: "#DEF2E9",
                              }}
                            >
                              <span
                                style={{ color: "#49AF48", fontSize: "8px" }}
                              >
                                {" "}
                                <DeleteIcon
                                  style={{
                                    color: "#49AF48",
                                    fontSize: "10px",
                                  }}
                                />{" "}
                                <b>Delete</b>
                              </span>
                            </button> */}
                        {/* </div> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Video;
