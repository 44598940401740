import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";
// import { io } from "socket.io-client";
// var socket =io(apiUrl)
const headers = {
  "x-access-token": getAuthToken(),
};

export const sessionInsert = (payload) => {
  return http.post(apiUrl + "/session/sessionInsert", payload, {
    headers: headers,
  });
};

export const sessionUpdate = (payload) => {
  return http.post(apiUrl + "/sessionUpdate",payload, {
    headers: headers,
  });
};

export const getAllSessions = (payload) => {
  return http.post(apiUrl + "/session/getAllSessions", payload, {
    headers: headers,
  });
};
export const getOpenSessions = (payload) => {
  return http.post(apiUrl + "/session/data/helpFromTutor", payload, {
    headers: headers,
  });
};

export const getSessionByID = (payload) => {
  return http.post(apiUrl + "/session/"+payload.sessionID, {
    headers: headers,
  });
};

export const getAllTeachers = (payload) => {
  return http.post(apiUrl + "/user/teachers", payload, {
    headers: headers,
  });
};


export const getSession = (payload) => {
  return http.post(apiUrl + "/getSessionByCode", payload, {
    headers: headers,
  });
};
export const getUpcomingSession = (payload) => {
  return http.post(apiUrl + "/session/data/getAllUpcomingSession", payload, {
    headers: headers,
  });
};
export const getCompletedSession = (payload) => {
  return http.post(apiUrl + "/session/data/completeSessions", payload, {
    headers: headers,
  });
};
export const getusercur = (payload) => {
  return http.post(apiUrl + "/getusercur", payload, {
    headers: headers,
  });
};

export const sessionStatus = (payload) => {

  return http.post(apiUrl + "/session/data/sessionStatus", payload, {
    headers: headers,
  });
};

export const addStudent = (payload) => {

  return http.put(apiUrl + "/session/data/addStudent", payload, {
    headers: headers,
  });
};
export const setVideolink = (payload) => {
  return http.post(apiUrl + "/teams/getRecordingVideo", payload, {
    headers: headers,
  });
};
