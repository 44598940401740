import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import style from "./adminStudent.css";
import { Backdrop, CircularProgress } from "@mui/material";
const StudentList = (props) => {
  const { studentList, search, searchStudent, changeStatus, open } = props;
  const history = useHistory();
  console.log(studentList);

  // useEffect(() => {
  //     window.scrollTo(0, 0)
  // }, [])

  return (
    <Fragment> 
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <div className="col-md-12">
          <div
            className="col-md-12"
            style={{ padding: "16px 0px", marginBottom: "10px" }}
          >
            <h3
              style={{
                color: "#4A1D75",
                fontFamily: "roboto",
                fontWeight: "600px",
              }}
            >
              Student List
            </h3>
          </div>
          <div className="col-md-12 d-flex "></div>
          {/* <div class="input-group row col-12   mb-2 ">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <img
                  src="https://img.icons8.com/search"
                  style={{ maxWidth: "40%" }}
                />
              </div>
            </div>
            <input
              type="text"
              value={search}
              onChange={(text) => searchStudent(text)}
              class="form-control"
              placeholder="Search Student"
            />
          </div> */}
          <div class="search">
            <span class="fa fa-search"></span>
            <input
              placeholder="Search"
              style={{ borderRadius: "30px" }}
              type="text"
              value={search}
              onChange={(text) => searchStudent(text)}
            />
          </div>
          <div className="col-md-12 table_container">
            {/* <div className="card" style={{ marginTop: "10px" }}> */}
            <div
              className="card-body"
              // style={{ display: "block", overflowX: "scroll" }}
            >
              <div className="table-responsive">
                <table className="table table ">
                  <thead>
                    <tr>
                      <th
                        style={{
                          color: "#20639B",

                          fontFamily: "roboto",
                        }}
                      >
                        Student Name
                      </th>
                      <th
                        style={{
                          color: "#20639B",

                          fontFamily: "roboto",
                        }}
                      >
                        Role
                      </th>
                      <th
                        style={{
                          color: "#20639B",

                          fontFamily: "roboto",
                        }}
                      >
                        Email
                      </th>
                      {/* <th style={{ color: "#20639B", width: "14%" }}>
                      Curriculum code
                    </th> */}
                      {/* <th style={{ color: "#20639B", width: "7%" }}>
                      Curriculum
                    </th> */}
                      <th
                        style={{
                          color: "#20639B",

                          fontFamily: "roboto",
                        }}
                      >
                        Gender
                      </th>
                      <th
                        style={{
                          color: "#20639B",

                          fontFamily: "roboto",
                        }}
                      >
                        D.O.B
                      </th>
                      <th
                        style={{
                          color: "#20639B",

                          fontFamily: "roboto",
                        }}
                      >
                        Mobile No.
                      </th>
                      <th
                        style={{
                          color: "#20639B",

                          fontFamily: "roboto",
                        }}
                      >
                        Address
                      </th>
                      <th style={{ width: "", fontFamily: "roboto" }}>
                        Status
                      </th>
                      <th style={{ fontFamily: "roboto", minWidth: "260px" }}>
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    {studentList &&
                      studentList.length > 0 &&
                       [...studentList].reverse().map((q) => {
                        // studentList.reverse().map((q) => {
                       
                        if (q.role === "student") {
                          return (
                            <tr>
                              <td
                                className="text-capitalize"
                                style={{ fontFamily: "roboto" }}
                              >
                                {q.firstName} {q.middleName} {q.lastName}{" "}
                              </td>
                              <td
                                className="text-capitalize"
                                style={{ fontFamily: "roboto" }}
                              >
                                {q.role}
                              </td>
                              <td style={{ fontFamily: "roboto" }}>
                                {q.email}
                              </td>
                              {/* <td>{q.curriculumCode ? q.curriculumCode : ""}</td> */}
                              {/* <td>{q.curriculum ? q.curriculum : ""}</td> */}
                              <td style={{ fontFamily: "roboto" }}>
                                {q.gender}
                              </td>
                              <td style={{ fontFamily: "roboto" }}>
                                {q.dob && q.dob.split("T")[0]}
                              </td>
                              <td style={{ fontFamily: "roboto" }}>
                                {q.mobile}
                              </td>
                              <td style={{ fontFamily: "roboto" }}>
                                {q.address}
                              </td>
                              <td>
                                {q.isActivate ? (
                                  <button
                                    className="btn btn-sm btn-info float-right ml-3"
                                    onClick={() => changeStatus(q._id, false)}
                                    style={{
                                      backgroundColor: "rgb(224, 246, 246)",
                                    }}
                                  >
                                    <span
                                      style={{ color: "rgb(29, 185, 170)" }}
                                    >
                                      <b
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        {" "}
                                        Active
                                      </b>
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-info float-right ml-3"
                                    onClick={() => changeStatus(q._id, true)}
                                    style={{
                                      backgroundColor: "rgb(224, 246, 246)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(29, 185, 170)",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      <b
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        {" "}
                                        InActive
                                      </b>
                                    </span>
                                  </button>
                                )}
                              </td>

                              <td
                               
                              >
                                <Link to={`/admin/student-view/${q.userID}`}>
                                  <button
                                    className="btn btn-sm btn-info"
                                    style={{
                                      backgroundColor: "#DEF2E9",
                                      marginRight: "15px",
                                      /*  padding:'10px 15px' */
                                    }}
                                  >
                                    <span style={{ color: "#49AF48" }}>
                                      <RemoveRedEyeIcon
                                        style={{
                                          color: "#49AF48",
                                          fontSize: "13px",
                                        }}
                                      />
                                      <span
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        View
                                      </span>
                                    </span>
                                  </button>
                                </Link>
                                <Link to={`/admin/student-edit/${q.userID}`}>
                                  <button
                                    className="btn btn-sm"
                                    style={{
                                      backgroundColor: "rgb(255, 231, 168)",
                                    }}
                                  >
                                    <span style={{ color: "#EAB731" }}>
                                      <BorderColorIcon
                                        style={{
                                          color: "#EAB731",
                                          fontSize: "13px",
                                        }}
                                      />
                                      <span
                                        style={{
                                          fontSize: "11px",
                                          fontFamily: "Roboto",
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </span>
                                    </span>
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          );
                        }
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StudentList;
