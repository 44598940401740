import { Delete } from "@mui/icons-material";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import style from "./staff.css";
import { useState } from "react";
export const DeleteStaff = ({ id, deleteStaff2 }) => {
  const [modal, setModal] = useState(false);

  function ok() {
    deleteStaff2(id);
    setModal(false);
  }

  return (
    <>
   <button
        className="btn btn-sm "
        onClick={() => {
          setModal(true);
        }}
        style={{
          backgroundColor: "rgb(228, 152, 152)",
          padding:'4px 7px'
        }}
      >
        <span style={{ color: "#ed2d2d", fontSize: "9px" }}>
          <Delete
            style={{
              color: "#ed2d2d",
              fontSize: "13px",
              paddingBottom: "2px",
            }}
          />
          <b style={{ fontSize: "11px", fontFamily:"Roboto" }}>Delete</b>
        </span>
      </button>

      <Modal
        size="md"
        isOpen={modal}
        toogle={() => {
          setModal(!modal);
        }}
        style={{ marginTop: "18%" }}
      >
        <ModalHeader
          toogle={() => {
            setModal(!modal);
          }}
        ></ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <h3>Are you sure you want to Delete?</h3>
          </div>
          <div
            class="confirm"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                ok();
              }}
              style={{ margin: "12px" }}
            >
              Yes
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                setModal(false);
              }}
              style={{ margin: "12px" }}
            >
              No
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
