import React, { useEffect, useRef, useState } from "react";
import Button from "../common/button";
import Input from "../common/input";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { getVideosId, editVedio, multipleVideoUpload } from "../../../services/Admin/videoService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { videoUpload } from "../../../services/Admin/videoService";
import {
  getCurriculumList,
  getSubjectsByCur,
  getTopicByCurSub,
  getAllChapters,
} from "../../../services/Admin/commonService";
import {
  submitRead,
  submitExercise,
  checkVideoStatus,
} from "../../../services/Admin/videoService";
import { fileUploadpdf } from "../../../services/Admin/videoService";
import { chapterListFun } from "../../../utils/utils";
import { Backdrop, CircularProgress } from "@mui/material";

export default function EditVideo() {
  const history = useHistory();
  let videoListData = useRef({
    curriculum: "",
    curriculumCode: "",
    subject: "",
    topic: "",
    chapter: "",
    fileName: "",
    fileLocation: "",
    videoName: "",
    videoID: "",
    videofileName: "",
    readArr: [],
    exercise: [],
  });
  const [open, setOpen] = useState(false);
  const [drop, setdrop] = useState(false);
  const [drop1, setdrop1] = useState(false);
  const [drop2, setdrop2] = useState(false);

  const [getEditVideo, setgetEditVideo] = useState();
  const [curriculam, setCurriculam] = useState([]);
  const [subjectlist, setSubjectList] = useState([]);
  const [topiclist, setTopicList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [data, setData] = useState(false);
  const [videoFile, setVideoFile] = useState();
  const [exercise, setExercise] = useState([]);
  const [subjectData, setSubject] = useState("");

  const [changes, setchanges] = useState(false);
  const [readFile, setReadFile] = useState([{ file: '', location: '', name: '' }])
  const [exerciseFile, setExerciseFile] = useState([{ file: '', location: '', name: '' }])

  const [pdfFile, setpdfFile] = useState([]);
  const [pdfFile2, setpdfFile2] = useState([]);

  // const [fileArr,setFileArr] = useState([])


  const { id } = useParams();
  const handleClick = () => {
    setReadFile([...readFile, { file: '', location: '', name: '' }])
    console.log(readFile)
  };
  const handleDecrement = (index) => {
    let arr = [...readFile]
    arr.splice(index, 1)
    setReadFile(arr)
  };



  const fileUpload = async (uploadFile, file, index) => {
    if (uploadFile) {
      const data = new FormData();

      data.append("profileImage", uploadFile, uploadFile.name);
      let headers = {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      };
      await videoUpload(data, headers)
        .then((response) => {
          if (200 === response.status) {
            // If file size is larger than expected.
            if (response.data.error) {
              if ("LIMIT_FILE_SIZE" === response.data.error.code) {
                console.log("Max size: 2MB -----------------");
                // this.ocShowAlert( 'Max size: 2MB', 'red' );
              } else {
                console.log("response Data ---------------", response.data.error);
                // If not the given file type
                // this.ocShowAlert( response.data.error, 'red' );
              }
            } else {
              // Success
              let fileData = response.data;
              let fileName = fileData.fileName;
              let fileLocation = fileData.fileLocation;

              // console.log(fileLocation);
              if (file === "video") {
                videoListData.current.videofileName = fileName;
                videoListData.current.fileLocation = fileLocation;
                videoListData.current.fileName = fileName;
              }
              if (file === "pdf") {
                let pdfData = {
                  name: fileName,
                  location: fileLocation,
                };

                videoListData.current.readArr[index] = pdfData;
                setpdfFile2(pdfData)


              }
              if (file === "exercise") {
                let exerciseData = {
                  name: fileName,
                  location: fileLocation,
                };
                videoListData.current.exercise[index] = exerciseData;
              }
              // return pdfData
            }
          }
        })
        .catch((error) => {
          // If another error
          console.log("error ---------------", error);
          // this.ocShowAlert( error, 'red' );
        });
    }
  };

  const getAllCurriculamList = async () => {
    getCurriculumList()
      .then((res) => {
        // console.log(res);
        setCurriculam(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubjectList = async (data) => {
    // console.log(data);
    const payload = {
      curriculumCode: videoListData.current.curriculumCode,
    };

    getSubjectsByCur(payload)
      .then((res) => {
        // console.log(res);
        setSubjectList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllTopicsList = async (data) => {
    const payload = {
      curriculumCode: videoListData.current.curriculumCode,
      subject: data,
    };
    getTopicByCurSub(payload)
      .then((res) => {
        // console.log(res);
        setTopicList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllChaptersList = async (data) => {
    setData(true);
    setOpen(true)
    const payload = {
      curriculumCode: videoListData.current.curriculumCode.trim(),
      subject: videoListData.current.subject,
      topic: data,
    };
    // console.log(payload);

    getAllChapters(payload)
      .then((res) => {
        let chapter = chapterListFun(res.data.data);
        setChapterList(chapter);
        setData(false);
        setOpen(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getvideoItemById = async () => {
    let payload = {
      _id: id,
    };
    getVideosId(payload, id)
      .then((res) => {
        console.log(res)
        if (res.data.data.readArr.length !== 0) {
          let read = res.data.data.readArr.map(res => {
            return { ...res, file: '' }
          })
          // console.log(read)
          setReadFile(read)
        }

        if (res.data.data.exercise.length !== 0) {
          let exer = res.data.data.exercise.map(res => {
            return { ...res, file: '' }
          })
          // console.log(exer)
          setExerciseFile(exer)
        }
        videoListData.current = res.data.data;
        setgetEditVideo(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const videoEditData = async () => {
    setOpen(true);
    let arr = readFile
    let fileForUpload = arr.filter(res => res.file.name !== undefined)
    let fileForNotUpload = arr.filter(res => res.file.name == undefined)
    let arrr = fileForUpload.map(res => res.file)
    let newArrr = fileForNotUpload.map(res => {
      return {
        location: res.location,
        name: res.name
      }
    })
    let fileArr = []
    if(arrr.length !== 0){
      fileArr = await multipleVideoUpload(arrr)
    }


    let arr1 = exerciseFile
    let fileForUpload1 = arr1.filter(res => res.file.name !== undefined)
    let fileForNotUpload1 = arr1.filter(res => res.file.name == undefined && res.name !== '' && res.location !== '')
    let arrr1 = fileForUpload1.map(res => res.file)
    let newArrr1 = fileForNotUpload1.map(res => {
      return {
        location: res.location,
        name: res.name
      }
    })
    let fileArr1 = []
    if(arrr1.length !== 0){
      fileArr1 = await multipleVideoUpload(arrr1)
    }




    await fileUpload(videoFile, "video", 0);

    let payload = {
      curriculum: videoListData.current.curriculum,
      curriculumCode: videoListData.current.curriculumCode,
      subject: videoListData.current.subject,
      topic: videoListData.current.topic,
      chapter: videoListData.current.chapter,
      fileName: videoListData.current.fileName,
      fileLocation: videoListData.current.fileLocation,
      videoName: videoListData.current.videoName.trim(),
      videoID: videoListData.current.videoID,
      videofileName: videoListData.current.videofileName,
      readArr: [...newArrr, ...fileArr],
      exercise: [...newArrr1, ...fileArr1],
    };
    console.log(payload)
    // return
    editVedio(payload, id)
      .then((res) => {
        setOpen(false);
        history.push("/admin/video");
        // console.log(res, "kookoookk");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getvideoItemById();
  }, []);
  useEffect(() => {
    getAllCurriculamList();
    getAllSubjectList();
    getAllTopicsList(videoListData.current.subject);
    getAllChaptersList(videoListData.current.topic);
  }, [getEditVideo]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="d-flex flex-row">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  width: "99%",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1
                    className="form-heading"
                    style={{ color: "#4A1D75", fontFamily: "roboto" }}
                  >
                    Edit Video
                  </h1>
                </div>
                <div>
                  <button
                    className="btn btn-sm  mb-4"
                    onClick={() => history.goBack()}
                    style={{
                      borderRadius: "40px",
                      backgroundColor: "#4A1D75",
                      border: "2px solid #4A1D75",
                      width: "140px",
                      textAlign: "center",
                      padding: "6px",
                      color: "white",
                      height: "auto",
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <label class="label-heading">curriculums</label>

                    <select
                      className="w-100 form-select mt-1"
                      defaultValue={getEditVideo ? getEditVideo.curriculum : ""}
                      value={videoListData.current.curriculum}
                      onChange={(e) => {
                        if (e.target.value) {
                          let list = curriculam.filter(
                            (s) => s.curriculum.trim() === e.target.value.trim()
                          );

                          videoListData.current.curriculumCode =
                            list[0].curriculumCode;
                          videoListData.current.curriculum = e.target.value;
                          setdrop(false);
                          setdrop1(false);
                          setdrop2(false);
                          getAllSubjectList(list[0].curriculumCode);
                        }
                      }}
                    >
                      {/* <option disabled selected>
                      select
                    </option> */}
                      {curriculam.map((item) => (
                        <option value={item.curriculum}>
                          {item.curriculum}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="label-heading">Subject</label>
                    <select
                      className="w-100 form-select mt-1"
                      // defaultValue={getEditVideo.subject?.subject}
                      value={videoListData.current.subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                        // console.log(e.target.value);
                        getAllTopicsList(e.target.value);
                        videoListData.current.subject = e.target.value;
                        setdrop(true);
                        setdrop1(false);
                        setdrop2(false);
                      }}
                    >
                      <option selected disabled={drop}>
                        select
                      </option>
                      {subjectlist.map((item) => (
                        <option value={item.subject}>{item.subject}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="label-heading">Topic</label>
                    <select
                      className="w-100 form-select mt-1"
                      value={videoListData.current.topic}
                      onChange={(e) => {
                        // console.log(e.target.value);
                        getAllChaptersList(e.target.value);
                        videoListData.current.topic = e.target.value;
                        setdrop1(true);
                        setdrop2(false);
                      }}
                    >
                      <option selected disabled={drop1}>
                        select
                      </option>
                      {topiclist.map((item) => (
                        <option value={item.topic}>{item.topic}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label className="label-heading">Chapter</label>
                    <select
                      className="w-100 form-select mt-1"
                      // defaultValue={getEditVideo.chapter?.chapter}
                      value={videoListData.current.chapter}
                      onChange={(e) => {
                        videoListData.current.chapter = e.target.value;

                        setdrop2(true);
                      }}
                    >
                      <option disabled={drop2} selected>
                        select
                      </option>
                      {chapterList.map((item) => {
                        return (
                          <>
                            <option value={item ? item.chapter : ""}>
                              {item ? item.chapter : ""}
                            </option>
                            ;
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      label="Video Name"
                      onChange={(e) => {
                        videoListData.current.videoName = e.target.value;
                      }}
                      defaultValue={
                        videoListData.current.videoName !== ""
                          ? videoListData.current.videoName
                          : videoListData.current.videofileName.split("/")[1]
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="file"
                      // value={getEditVideo.videofileName}
                      accept="video/mp4,video/x-m4v,video/*"
                      name="video"
                      label="Video"
                      onChange={(e) => {
                        setVideoFile(e.target.files[0]);
                      }}
                    />
                  </div>
                  {readFile?.map((res, index) => (
                    <>
                      <div className="col-md-6">
                        <Input
                          type="text"
                          label="Read File Name"
                          value={
                            res.file.name !== undefined
                              ? res.file.name
                              : res?.name?.split("/")[1]
                          }
                          disabled={true}
                          onChange={(e) => {
                            videoListData.current.read_name = e.target.value;
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <Input
                          type="file"
                          accept="application/pdf,application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          name="Upload file"
                          label="Read File"
                          onChange={(e) => {
                            let arr = [...readFile]
                            arr[index].file = e.target.files[0];
                            setReadFile(arr)
                            // pdfFile[index] = e.target.files[0];
                            setchanges(true);
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        {index == readFile.length - 1 ? <button
                          className="btn btn-sm"
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            width: "140px",
                            textAlign: "center",
                            padding: "6px",
                            color: "white",
                            height: "auto",
                          }}
                          onClick={() => {
                            handleClick();
                            // setMoreDrop([...moreDrop, 1]);
                          }}
                          disabled={res.file.name !== undefined || res.name.length !== 0 ? false : true}
                        >
                          Add More
                        </button> : ''}

                      </div>
                      <div className="col-md-6">
                        {index == 0 ? '' : (
                          <button
                            className="btn btn-sm"
                            style={{
                              borderRadius: "40px",
                              backgroundColor: "#4A1D75",
                              border: "2px solid #4A1D75",
                              width: "140px",
                              textAlign: "center",
                              padding: "6px",
                              color: "white",
                              height: "auto",
                            }}
                            onClick={() => {
                              handleDecrement(index);
                              // Additional logic for removing
                            }}
                          >
                            Remove
                          </button>
                        )}
                      </div>



                    </>
                  ))}
                  {exerciseFile.map((s, index) => (
                    <>
                      <div className="col-md-6 mt-3">
                        <Input
                          type="text"
                          label="Exercise Name"
                          value={s.file.name !== undefined ?s.file.name : s.name.split("/")[1]}
                          disabled={true}
                          onChange={(e) => {
                            videoListData.current.exercise_name =
                              e.target.value;
                          }}
                        />
                      </div>
                      <div className="col-md-6 mt-3">
                        <Input
                          type="file"
                          accept="application/pdf,application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          name="Upload file"
                          label="Excercise File"
                          onChange={(e) => {
                              let arr = [...exerciseFile]
                            arr[index].file = e.target.files[0];
                            setchanges(true);
                          }}
                        />
                      </div>

                      <div className="col-md-6 ">
                        {index == exerciseFile.length - 1 ? <button
                          className="btn btn-sm"
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            width: "140px",
                            textAlign: "center",
                            padding: "6px",
                            color: "white",
                            height: "auto",
                          }}
                          onClick={() => {
                            setExerciseFile([...exerciseFile, { file: '', location: '', name: '' }])
                          }}
                          disabled={s.file.name !== undefined || s.name.length !== 0 ? false : true}
                        >
                          Add More
                        </button> : ''}

                      </div>
                      <div className="col-md-6">
                        {index == 0 ? '' : (
                          <button
                            className="btn btn-sm"
                            style={{
                              borderRadius: "40px",
                              backgroundColor: "#4A1D75",
                              border: "2px solid #4A1D75",
                              width: "140px",
                              textAlign: "center",
                              padding: "6px",
                              color: "white",
                              height: "auto",
                            }}
                            onClick={() => {
                              let arr = [...exerciseFile]
                              arr.splice(index, 1)
                              setExerciseFile(arr)
                            }}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </>
                  ))}
                </div>
                <div className="text-center mt-5">
                  <Button
                    className="btn btn-sm "
                    label={"Submit"}
                    style={{
                      borderRadius: "40px",
                      backgroundColor: "#4A1D75",
                      border: "2px solid #4A1D75",
                      width: "140px",
                      textAlign: "center",
                      padding: "6px",
                      color: "white",
                      height: "auto",
                    }}
                    onClick={() => {
                      videoEditData();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
