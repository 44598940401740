import React from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";


import styles from "./record.module.css";

const RecordVideo = (props) => {
    const { chapterSingle, modalShow, handleClose } = props;
    let modalContent;
    // console.log(chapterSingle)
    if (chapterSingle.fileLocation) {
        modalContent = (
            <div className="player-wrapper">
                <ReactPlayer
                    className="react-player"
                    url={chapterSingle.fileLocation}
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </div>
        );
    } else {
        modalContent = (
            <div className={styles.notAvailable}>
                Recording not available 
            </div>
        );
    }

    return (
        <React.Fragment>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={handleClose}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <h5 className="mt-1 mb-0">{chapterSingle.chapter}</h5>
                </Modal.Header>
                <Modal.Body className={styles.modalBody}>
                    <div className="row">{modalContent}</div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default RecordVideo;
