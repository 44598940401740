import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const videosList = (payload) => {
  return http.post(apiUrl + "/video/videosList", payload, {
    headers: headers,
  });
};

export const deleteVideo = (payload) => {
  return http.post(apiUrl + "/video/data/deleteVideo", payload, {
    headers: headers,
  });
};

export const videoUpload = (payload, fileUpload) => {
  console.log(payload)
  const videoHeaders = { headers, "x-access-token": getAuthToken() };
  return http.post(apiUrl + "/video/addMultipleImage/add", payload, {
    headers: videoHeaders,
  });
};
export const multipleVideoUpload = async (payload) => {
  console.log(payload)
  
  
  try {
    const newArr = payload;
    return await Promise.all(
      newArr.map(async (file) => {
        const data = new FormData();
        data.append("profileImage", file, file?.name);
        let headers = {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        };
        const response = await videoUpload(data, headers)
        console.log(response)
        let fileData = response.data;
        let fileName = fileData.fileName;
        let fileLocation = fileData.fileLocation;
        return {
          name: fileName,
          location: fileLocation,
        };
      })
    );

  } catch (e) {
    console.error(e);
  }



};

export const submitVideo = (payload) => {
  return http.post(apiUrl + "/video/submitVideo", payload, {
    headers: headers,
  });
};

export const getVideo = (payload) => {
  return http.post(apiUrl + "/getvideo", payload, {
    headers: headers,
  });
};
export const getVideoList = (payload) => {
  return http.post(apiUrl + "/video/getVideoList/data", payload, {
    headers: headers,
  });
};

export const submitRead = (payload) => {
  return http.post(apiUrl + '/video/submitRead/data', payload, {
    headers: headers,
  })
}
export const submitExercise = (payload) => {
  return http.post(apiUrl + '/video/addExercise/data', payload, {
    headers: headers,
  })
}
export const checkVideoStatus = (payload) => {
  return http.post(apiUrl + '/video/checkVideo/data', payload, {
    headers: headers,
  })
}
export const fileUpload = (payload, head) => {
  const videoHeaders = { headers, "x-access-token": getAuthToken() };

  return http.post(apiUrl + "/video/addMultipleImage/add", payload, {
    headers: head,
  });
};
export const fileUploadpdf = (payload, head) => {
  const videoHeaders = { headers, "x-access-token": getAuthToken() };

  return http.post(apiUrl + "/video/addMultipleImage/add", payload, {
    headers: head,
  });
};
export const getVideosId = (payload, id) => {
  return http.post(apiUrl + `/video/${id}`, payload, {
    headers: headers,
  });
};
export const editVedio = (payload, id) => {
  return http.post(apiUrl + `/video/updateVideo/${id}`, payload, {
    headers: headers,
  });
};

export const deleteViewVideo = (payload) => {
  return http.post(apiUrl + "/video/data/deleteVideo", payload, {
    headers: headers,
  });
}