import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getStaffList } from '../../../services/Admin/staffService';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "../../Students/Assignments/Assignments.module.css";
import parse from "html-react-parser";

const StudentData = (props) => {
  const history = useHistory()
  const {
    assignmentIndividual: data,
    pendingQuestions,
    submitCorrection,
    manualMarks,
    studentQues,
    manualCorrectionData,
    navigate
  } = props;
  if(navigate){
    history.push("/teacher/assignments-list")
  }
  const [studQues, setStudQues] = useState([]);
  const [question, setQuestion] = useState([]);
   const [loading, setloading] = useState(true);
  let { id, testId } = useParams()
 

  const getQuestion = (ques) => {
    if (studentQues.length !== 0 && question.length === 0) {

      let student = ques.filter((e) => e.userID === Number(id));

      if (question.length === 0) {

        setQuestion(student[0].questions);
      }
    }
  };
  getQuestion(studentQues)

  useEffect(async () => {
    setStudQues(studentQues);

  }, [studentQues]);

  useEffect(() => {
    if (manualCorrectionData.length !== 0) {
      setQuestion(manualCorrectionData);
    }
    setStudQues(studentQues);
  }, [manualCorrectionData]);

  return (
    <div>
      {/*  <h1>StudentData</h1> */}
      <div className='container-fluid py-5'>
        <div className='row'>
          <div className='col-lg-6'>
            <form >
              <div className="scroll_container">
                {question &&
                  question.length > 0 &&
                  question.map((q, i) => {
                    if (q.questionType === "Multi") {
                      return (
                        <div className={`${styles.quesItem}`} key={q._id}>
                          <div className={`${styles.quesTitle}`}>
                            {q.question ? (
                              <React.Fragment>
                                <span>Q{i + 1} :</span> {parse(q.question)}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="ui form">
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-times-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.answer1 === q.response ? true : false
                                }
                                disabled={true}
                              />
                              <label>{q.answer1}</label>
                            </div>
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-times-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.answer2 === q.response ? true : false
                                }
                                disabled={true}
                              />
                              <label>{q.answer2}</label>
                            </div>
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-times-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.answer3 === q.response ? true : false
                                }
                                disabled={true}
                              />
                              <label>{q.answer3}</label>
                            </div>
                            <div className={`${styles.radioCheckbox}`}>
                              <i className="las la-check-circle"></i>
                              &nbsp;
                              <input
                                type="radio"
                                name={`question${i + 1}`}
                                checked={
                                  q.correctanswer === q.response
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />
                              <label>{q.correctanswer}</label>
                            </div>
                          </div>
                          <React.Fragment>
                            <div className="form-group mt-4">
                              <div className="row">
                                <div className="col-md-2 pl-0">
                                  <label>Marks</label>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      q.valid === true
                                        ? q.questionmarks
                                        : "0"
                                    }
                                    disabled={true}
                                  />
                                </div>
                                <div className="col-md-4">
                                  Out of {q.questionmarks}
                                  <span className="badge badge-info">
                                    {/* {q.questionmarks} */}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      );
                    } else {
                      return (
                        <div className={`${styles.quesItem}`} key={q._id}>
                          <div className={`${styles.quesTitle}`}>
                            {q.question ? (
                              <React.Fragment>
                                <span>Q{i + 1} :</span> {parse(q.question)}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="ui form">
                            <CKEditor
                              editor={ClassicEditor}
                              data={q.response}
                            />
                          </div>
                          <React.Fragment>
                            <div className="form-group mt-4">
                              <div className="row">
                                <div className="col-md-2  pl-0">
                                  <label>Marks</label>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                      manualMarks(e, q, "marks", question)
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-md-4">
                                  Out of {q.questionmarks}
                                  <span className="badge badge-info">
                                    {/* {q.questionmarks} */}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mt-4">
                              <div className="row">
                                <div className="col-md-2  pl-0">
                                  <label>Comments</label>
                                </div>
                                <div className="col-md-10">
                                  <textarea
                                    className="form-control"
                                    row="3"
                                    onChange={(e) =>
                                      manualMarks(
                                        e,
                                        q,
                                        "comments",
                                        question
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      );
                    }
                  })}
              </div>
              <div className="col-md-12 text-center mb-4">
                <button
                  className="cancel me-3"
                  style={{ color: "red" }}
                  onClick={() => { submitCorrection("Reject") }}
                >
                  Reject
                </button>
                <button
                  className="cancel me-3"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </button>
                <button
                type='submit'
                  className="btn btn-primary"
                  onClick={() => submitCorrection()}
                >
                  Submit
                </button>

              </div>
            </form>

          </div>
          <div className='col-lg-6'>
            <div className='w-100 d-flex justify-content-center'>
              {question.length !== 0 && (<>
                {
                  (question[question.length-1].testVideo) ? (<>

                    <iframe width="420" height="315"
                      src={question[question.length-1].testVideo.videoLink}>
                    </iframe>
                  </>) : (<>

                    <img src="/static/media/Group 24445.7600c88d.png" class="100 w-100" />
                  </>)
                }
              </>
              )
              }
            </div>
            <div className='w-100 d-flex justify-content-center my-4'>
              <div id="carouselExampleIndicators" className="carousel slide w-100" data-ride="carousel">
                <ol className="carousel-indicators" style={{ listStyle: 'none', position: "absolute", bottom: "-45px" }}>
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active toggle_dot"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1" className='toggle_dot'></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2" className='toggle_dot'></li>
                </ol>
                <div className="carousel-inner h-100">
                  <div className="carousel-item customH active">
                    <img className="d-block" src="./slide3.jpg" alt="First slide" />
                  </div>
                  <div className="carousel-item customH">
                    <img className="d-block" src="/slide03.jpg" alt="Second slide" />
                  </div>
                  <div className="carousel-item customH">
                    <img className="d-block" src="/slide4.jpg" alt="Third slide" />
                  </div>
                  <div className="carousel-item customH">
                    <img className="d-block" src="/slide1.jpg" alt="forth slide" />
                  </div>
                  <div className="carousel-item customH">
                    <img className="d-block" src="/slide2.jpg" alt="forth slide" />
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudentData;