import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";
import { remove } from "lodash";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getAllCourse = () => {
  return http.post(apiUrl + "/chapter/getAllChapter", {
    headers: headers,
  });
};

export function saveCourse(payload, courseId) {
  if (!courseId) {
    //Add Course
    return http.post(apiUrl + "/chapter/addChapter", payload, {
      headers: headers,
    });
  } else {
    //Edit Course
    delete payload._id;

    return http.post(apiUrl + "/chapter/updateChapter/" + courseId, payload, {
      headers: headers,
    });
  }
}

export const getSubjectsForCourse = (payload) => {
  return http.post(apiUrl + "/subject/getsubjectByCurriculum", payload, {
    headers: headers,
  });
};

export const getCourse = (courseId) => {
  return http.post(apiUrl + "/chapter/getChapter", courseId, {
    headers: headers,
  });
};

export const getSubjectinfo = (payload) => {
  return http.post(apiUrl + "/subject/getSubject", payload, {
    headers: headers,
  });
};

export const copyCourse = (id) => {
  return http.post(apiUrl + "/chapter/copyChapter/" + id, {
    headers: headers,
  });
};
export const deleteCourse = (id) => {
  return http.post(apiUrl + "/chapter/delete/" + id, {
    headers: headers,
  });
};
