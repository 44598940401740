import http from "../httpService";
import { apiUrl, api } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getCurriculumList = () => {
  return http.get(apiUrl + "/curriculum/getAllCurriculum", {
    headers: headers,
  });
};

export const getSubjectsByCur = (payload) => {
  return http.post(apiUrl + "/subject/getsubjectByCurriculum", payload, {
    headers: headers,
  });
};

export const getTopicByCurSub = (payload) => {
  return http.post(apiUrl + "/chapter/getTopicByCurSub", payload, {
    headers: headers,
  });
};

export const getAllChapters = (payload) => {
  return http.post(apiUrl + "/chapter/getChapterByCurSubTopic", payload, {
    headers: headers,
  });
};
export const getChapters = (payload) => {
  return http.post(apiUrl + "/chapter/chapterData", payload, {
    headers: headers,
  });
};
export const getInactiveUser = (payload) => {
  return http.get(api + "/getInactiveUser", {
    headers: headers,
  })
};
export const postActiveUser = (payload) => {
  return http.post(api + "/activateUser", payload, {
    headers: headers,
  })
}

export const postRejectUser = (payload) => {
  return http.post(api + "/rejectUser", payload, {
    headers: headers,
  })
}
export const getUserInfo = (payload) => {

  return http.get(api + "/user/userInfo/" + payload, {
    headers: headers,
  })
}

export const getLogo = () => {
  return http.get(api + "/logo/getImage", {
    headers: headers,
  })
}

export const updateImage = (payload, id) => {
  return http.post(api + "/logo/updateImage/" + id, payload, {
    headers: headers,
  })
}
export const getAdminMeet = () => {
  return http.get(api + "/teams/getMeeting", {
    headers: headers,
  })
}

export const adminDash = () => {
  return http.get(api + "/user/getDashboard", {
    headers: headers,
  })
}

export const getNotify = (payload) => {
  return http.post(api + "/notification/getNotifications", payload, {
    headers: headers,
  })
}

export const deleteNotify = (payload) => {
  return http.delete(api + "/notification/deleteNotifications/" + payload, {
    headers: headers,
  })
}

export const addNotify = (payload) => {
  return http.post(api + "/notification/addNotify", payload, {
    headers: headers,
  })
}

export const addNews = (payload) => {
  return http.post(api + "/news/addNews", payload, {
    headers: headers,
  })
}


export const getAllNews = () => {
  return http.get(api + "/news/getAllNews", {
    headers: headers,
  })
}

export const getByIdNews = (id) => {
  return http.get(api + "/news/getByIdNews/" + id, {
    headers: headers,
  })
}

export const deleteNews = (id) => {
  return http.delete(api + "/news/deleteNews/" + id, {
    headers: headers,
  })
}

export const updateByIdNews = (id, payload) => {
  return http.post(api + "/news/updateByIdNews/" + id, payload, {
    headers: headers,
  })
}

export const sessionUpdate = (id, payload) => {
  return http.post(api + "/session/updateSession/" + id, payload, {
    headers: headers,
  })
}
export const DateUpdate = ( payload,id) => {
  return http.put(api + "/session/data/updateReminder/" + id, payload, {
    headers: headers,
  })
}