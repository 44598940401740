import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import PleaseWaitModal from "./PleaseWaitModal";
// import SystemCheckModal from "./SystemCheckModal";
import { getCertify } from "../../services/Student/AssignmentService";
import { userID } from "../../services/Authentication/AuthService";
import { Modal } from "reactstrap";
// import { Modal } from "react-bootstrap";

const Profile = (props) => {
  console.log(props.list);
  const [visible, setVisible] = useState(false);
  const [certificate, setCertificate] = useState("");
  // const [id,setids]=useState("")
  const history = useHistory();
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const toggel = () => setModalOpen(!modalOpen);

  async function checkingMarks(id) {
    let data = props.list.filter((e) => e.id === id);

    await getCertify({
      curriculum: data.curriculum,
      curriculumCode: data.curriculumCode,
      studentId: userID(),
    })
      .then((res) => {
        let propsdata = res.data.data;
        if (propsdata.totalMarks === 0) {
          setError("No assignment ");
          setModalOpen(true);
          console.log(propsdata, "asdasdadadada");
        } else {
          let percent = (propsdata.achievedMarks / propsdata.totalMarks) * 100;
          if (data.minimumMarks <= percent) {
            setError(alert("You are failed"));
            setModalOpen(true);
          }else{
            history.push(`/student/certificate/$`);
          }
        }
        console.log(res);
        setCertificate(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // useEffect(() => {
  //   checkingMarks();
  // }, [props.list]);
  return (
    <>
      {modalOpen ? <h1>You are failed</h1> : ""}
      <Modal
        isOpen={modalOpen}
        toggel={() => setModalOpen(!modalOpen)}
        top={true}
        className="p-4"
      >
        {" "}
        <div className=" mt-4">
          <div className=" text-center">{error}</div>
          <div className="d-flex justify-content-end me-3 mb-3 mt-3">
            {" "}
            <button
              className="btn btn-primary"
              onClick={() => setModalOpen(!modalOpen)}
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>

      <div className="mt-8" style={{ marginTop: "80px" }}>
        {/* <Modal isOpen={true}></Modal> */}
        <div>
          <h1
            className="form-heading d-flex justify-content-center"
            //   style={{ display: "flex", justifyContent: "center" }}
          >
            Profile
          </h1>
        </div>

        <div className="container" style={{ marginBottom: "10px" }}>
          {/* <div
          style={{
            display: "flex",
            marginLeft: "100px",
          }}
        >
          {props.role === "student" && (
            <button
              onClick={props.proptoCertificate}
              className="btn btn-primary btn-sm  mb-3 "
              style={{ marginLeft: "10px" }}
            >
              Get Certificate
            </button>
          )}
        
        </div> */}

          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card">
                <div className="card-body">
                  <div className="text-view">
                    <label>Name</label>
                    <span>
                      {props.firstName} {props.middleName} {props.lastName}
                    </span>
                  </div>
                  <div className="text-view">
                    <label>Mobile</label>
                    <span>{props.mobile}</span>
                  </div>
                  <div className="text-view">
                    <label>Email</label>
                    <span>{props.email}</span>
                  </div>
                  <div className="text-view">
                    <label>Address</label>
                    <span>{props.address}</span>
                  </div>
                  {props.role !== "admin" && (
                    <Fragment>
                      <div className="text-view">
                        <label>Curriculum</label>
                        <span>{props.curriculum}</span>
                      </div>

                      {props.role !== "teacher" && (
                        <Fragment>
                          {" "}
                          <div className="text-view">
                            <label>Gender</label>
                            <span>{props.gender}</span>
                          </div>
                          <div className="text-view">
                            <label>Date of birth</label>
                            <span>{props.dob && props.dob.split("T")[0]}</span>
                          </div>
                          <div className="text-view">
                            <label>Country</label>
                            <span>{props.country}</span>
                          </div>
                          <div className="text-view">
                            <label>State</label>
                            <span>{props.state}</span>
                          </div>
                          <div className="text-view">
                            <label>City</label>
                            <span>{props.city}</span>
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 d-flex justify-content-end ">
          <div
            style={{
              // display: "flex",
              // marginLeft: "220px",
              paddingBottom: "100px",
            }}
            className="d-flex justify-content-end"
          >
            <div style={{}}>
              {/* {console.log(props.role)} */}
              {props.role === "student" ? (
                <button
                  className="btn btn-success btn-lg  pointer mb-3 "
                  onClick={() => {
                    setVisible(true);
                  }}
                  disabled={false}
                >
                  Get Certificate
                </button>
              ) : (
                // <div className="btn btn-primary pointer" onClick={()=>{console.log("hhhhhhhhhhhhhhh")}} > get certificate</div>
                ""
              )}
              {visible ? (
                <select
                  className="w-100 form-select"
                  onChange={(e) => {
                    checkingMarks(e.target.value);
                    // history.push(`/student/certificate/${e.target.value}`);
                  }}
                >
                  <option>select certificate</option>
                  {/* if(res.) */}
                  {props.list.map((res) => (
                    <option value={res.id}>{res.curriculum}</option>
                  ))}
                </select>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
