import React, { useEffect, useState } from "react"; 
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAllStudentReports } from "../../../services/Teacher/AssignmentService";

export default function StudentReports() {

const [studentList,setStudentList] = useState([]);

let params = useParams()
const getAllStudentList = () =>{
console.log(params.testId)
  getAllStudentReports({
    testID: params.testId,
  })
    .then((res) => {
      console.log(res);
      setStudentList(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

useEffect (()=>{

  getAllStudentList()
},[])

    const data = [
      {
        id: 111,
        name: "varun",
        curricluman_name: "IIT curriculam",
        subjectnName: "Math",
        marks: 45,
        percente: "45%",
        accept: "status",
        remark: "",
      },
      {
        id: 222,
        name: "Paras",
        curricluman_name: "IIM curriculam",
        subjectnName: "Reasoning",
        marks: 0,
        reject: "",
        percente:0,
        remark: "student caught cheat in exam",
      },
      {
        id: 333,
        name: "Deepshikha",
        curricluman_name: "Neet curriculam",
        subjectnName: "Biology",
        marks: 70,
        percente: "70%",
        accept: "status",
        remark: "",
      },
      {
        id: 444,
        name: "Ankush",
        curricluman_name: "IIT curriculam",
        subjectnName: "Math",
        marks: 85,
        percente: "85%",
        accept: "status",
        remark: "",
      },
    ];
  return (
    <div>
      <div className="col-md-12 p-3 table_container">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ fontFamily: "roboto" }}>Id</th>
                  <th style={{ fontFamily: "roboto" }}>Student Name</th>
                  <th style={{ fontFamily: "roboto" }}>Curriculam Name</th>
                  <th style={{ fontFamily: "roboto" }}>Subject Name</th>
                  <th style={{ fontFamily: "roboto" }}>Marks</th>
                  <th style={{ fontFamily: "roboto" }}>Percentage</th>
                  <th style={{ fontFamily: "roboto" }}>Status</th>
                  {/* <th>Remark</th> */}
                </tr>
              </thead>
              <tbody>
                {studentList.map((res) => {
                  return (
                    <tr>
                      <td style={{ fontFamily: "roboto" }}>{res.studentId}</td>
                      <td style={{ fontFamily: "roboto" }}>
                        {res.firstName + " " + res.lastName}
                      </td>
                      <td style={{ fontFamily: "roboto" }}>{res.curriculum}</td>
                      <td style={{ fontFamily: "roboto" }}>{res.subject}</td>
                      <td style={{ fontFamily: "roboto" }}>
                        {res.achievedMarks}
                      </td>
                      <td style={{ fontFamily: "roboto" }}>
                        {(Math.round(res.percent * 100) / 100).toFixed(2)}
                        {"%"}
                      </td>
                      <td>
                        {res.status === "Accept" ? (
                          // <button
                          //   className="btn btn-sm mt-1"
                          //   style={{
                          //     backgroundColor: "#E0F6F6",
                          //     cursor: "auto",
                          //   }}
                          // >
                          <span
                            style={{
                              color: "#1DB9AA",
                            }}
                          >
                            {" "}
                            <b style={{ fontFamily: "roboto" }}>Accept</b>
                          </span>
                        ) : (
                          // </button>
                          // <button
                          //   className="btn btn-sm  mt-1"
                          //   style={{
                          //     backgroundColor: "#fcd2dd",
                          //     cursor: "auto",
                          //   }}
                          // >
                          <span
                            style={{
                              color: "#f77e9e",
                              fontFamily:"roboto"
                            }}
                          >
                            {" "}
                            Reject
                          </span>
                          // </button>
                        )}
                      </td>
                      {/* <td>{res.remark}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
