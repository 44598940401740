import React from "react";
import { Formik, Form } from "formik";

import { staffRoleValues } from "../../../constants/AdminInitialValues";
import { staffRoleValidation } from "../../../validations/AdminValidations";
import FormikControl from "../../../common/Formik/FormikControl";
import { useHistory } from "react-router-dom";

const AddStaffRole = (props) => {
  const history = useHistory();
  const { handleSubmitRole,err } = props;
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h1
            className="form-heading"
            style={{ color: "#4A1D75", fontFamily: "roboto" }}
          >
            Add Staff Role
          </h1>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={staffRoleValues}
                validationSchema={staffRoleValidation}
                validateOnMount
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <div className="row">
                      <div className="col-md-12">
                        <FormikControl
                          control="input"
                          type="text"
                          label="Role Name"
                          id="roleName"
                          name="roleName"
                        />
                        <span className="text-danger">{err}</span>
                      </div>

                      <div className="text-center col-md-12 d-flex justify-content-center gap-3 mt-5">
                        <button
                          type="submit"
                          className="btn btn-sm"
                          onClick={() => handleSubmitRole(formik)}
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            width: "140px",
                            textAlign: "center",
                            padding: "6px",
                            color: "white",
                            height: "auto",
                          }}
                        >
                          Submit
                        </button>

                        <button
                          className="btn btn-sm"
                          onClick={() => history.goBack()}
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            width: "140px",
                            textAlign: "center",
                            padding: "6px",
                            color: "white",
                            height: "auto",
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaffRole;
