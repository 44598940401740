import React, { Fragment } from 'react'
import parse from "html-react-parser";
import styles from "../../Students/MyQueries/MyQueries.module.css";
import { userID } from "../../../services/Authentication/AuthService";
import DisplayErrorMessage from '../../Share/DisplayErrorMessage';
import ReactStars from "react-rating-stars-component";

const QueriesClosed = props => {
    const { queriesClosed, statchange } = props
    if (queriesClosed && queriesClosed.length === 0) {
        return (
            <DisplayErrorMessage
                message="No Closed queries found"
                errorSize="big"
            />
        );


        
    }

    
    return <Fragment>
        {queriesClosed.length > 0 &&
            queriesClosed.map(q => {
                console.log("Checking Q", q  )
                return (
                  <div className="p-3">
                    <div className={`cardDiv ${styles.queryDiv}`}>
                      <h5 title="Curriculum" className={styles.curriculum}>
                        <p style={{ fontFamily: "roboto" }}>{q.curriculum}</p>{" "}
                        {"-"}
                      </h5>
                      <div title="Subjet" className={styles.subject}>
                        <p style={{ fontFamily: "roboto" }}>{q.subject}</p>
                        {"-"}
                      </div>
                      <div title="Topic" className={styles.topic}>
                        <p style={{ fontFamily: "roboto" }}>{q.topic}</p>
                        {"-"}
                      </div>
                      <div title="Chapter" className={styles.chapter}>
                        <p style={{ fontFamily: "roboto" }}>{q.chapter}</p>
                        {"-"}
                      </div>

                      <hr className={styles.hr} />
                      {q.messages &&
                        q.messages.map((m) => {
                          console.log(
                            "idd" +
                              m.author_id +
                              "===" +
                              userID() +
                              "//" +
                              (m.author_id === userID())
                          );
                          return (
                            <Fragment>
                              {m.author_id === +userID() ? (
                                <div className="col-md-12">
                                  <p
                                    key={m.createdAt}
                                    className={styles.studentReview}
                                    style={{ fontFamily: "roboto" }}
                                  >
                                    {parse(m.message)}
                                  </p>
                                </div>
                              ) : (
                                <div className="col-md-12 text-right">
                                  <p
                                    key={m.createdAt}
                                    className={styles.teacherReview}
                                    style={{ fontFamily: "roboto" }}
                                  >
                                    {parse(m.message)}
                                  </p>
                                </div>
                              )}
                            </Fragment>
                          );
                        })}
                      {/* <div className="d-flex flex-row  justify-content-end">
                        <button
                            className="btn btn-success  mt-3 mr-2"
                            onClick={() => statchange(1, q.chatID)}
                        >
                           <i class="fa-solid fa-rotate-right"></i> open again
                        </button>

                    </div> */}
                      <hr />
                      <div className="d-flex flex-row justify-content-start">
                        <p
                          className="font-weight-bold"
                          style={{ fontFamily: "roboto" }}
                        >
                          Feedback :
                        </p>
                        <p style={{ fontFamily: "roboto" }}>
                          {q.feedback ? q.feedback : "No Feedback"}
                        </p>
                      </div>
                      <div className="row">
                        <ReactStars
                          count={5}
                          value={q.rating ? q.rating : 0}
                          size={24}
                          activeColor="#ffd700"
                        />
                      </div>
                    </div>
                  </div>
                );
            })
        }
    </Fragment>

}

export default QueriesClosed;
