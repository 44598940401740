import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CopyCurriculumm,
  CopyCurrSubCourseQuestion,
} from "../../../services/Admin/curriculumService";
// import style from "./curriculum.css";
// import style from "./curriculum.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Backdrop, CircularProgress } from "@mui/material";

const Curriculum = ({ curriculumList, searchCurr, search, open }) => {
  let [st, setst] = useState(true);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <div className="row" style={{ height: "" }}>
          <h1
            className="form-heading"
            style={{ color: "#4A1D75", fontFamily: "roboto" }}
          >
            Curriculum List
          </h1>
          {/* <div
       
        className="CurriculamHeader"
      > */}
          {/* <div class="input-group row col-12   mb-2 "> */}
          {/* <div class="input-group-prepend"> */}
          {/* <div className="input-group-text" style={{ borderRadius: "40px" }}>
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchCurr(text)}
              class="form-control"
              placeholder="Search Curriculum"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div
         
          style={{ borderRadius: "40px" }}
        > */}
          {/* <Link
            to={`/admin/add-curriculum`}
            className="btn btn-sm  add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75", searchCur
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            <span> Add Curriculum</span>
          </Link>
        </div>
      </div> */}
          <div className="CurriculamHeader">
            <div class="search">
              <span class="fa fa-search"></span>
              <input
                placeholder="Search"
                style={{ borderRadius: "30px" }}
                type="text"
                value={search}
                onChange={(text) => searchCurr(text)}
                className="searchInput"
              />
            </div>
            <div>
              <Link
                to={`/admin/add-curriculum`}
                className="btn btn-sm add-btn"
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                }}
              >
                Add Curriculum
              </Link>
            </div>
          </div>
          <div className="col-md-12 table_container">
            {/* <div className="card"> */}
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span
                          style={{ color: "#20639B", fontFamily: "roboto" }}
                        >
                          Curriculum Name
                        </span>
                      </th>
                      {/* <th scope="col">Curriculum Award</th> */}
                      {/* <th scope="col">Short Name</th>  */}
                      <th scope="col" style={{ minWidth: "166px" }}>
                        <span
                          style={{ color: "#20639B", fontFamily: "roboto" }}
                        >
                          {" "}
                          Actions
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {curriculumList.length > 0 &&
                      curriculumList.reverse().map((c) => {
                        return (
                          <tr key={c._id}>
                            <td style={{ fontFamily: "roboto" }}>
                              {c.curriculum}
                            </td>
                            <td>
                              <Link to={`/admin/edit-curriculum/${c._id}`}>
                                <button
                                  className="btn btn-sm "
                                  style={{
                                    backgroundColor: "#FFE7A8",
                                    marginRight: "10px",
                                  }}
                                >
                                  <span style={{ color: "#EAB731" }}>
                                    <BorderColorIcon
                                      style={{
                                        color: "#EAB731",
                                        fontSize: "13px",
                                      }}
                                    />
                                    <b
                                      style={{
                                        fontSize: "11px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {" "}
                                      Edit
                                    </b>
                                  </span>
                                </button>
                              </Link>
                              <Link to={`/admin/view-curriculum/${c._id}`}>
                                <button
                                  className="btn btn-sm btn-info"
                                  style={{
                                    backgroundColor: "rgb(222, 242, 233)",
                                  }}
                                >
                                  <span style={{ color: "rgb(73, 175, 72)" }}>
                                    <RemoveRedEyeIcon
                                      style={{
                                        color: "rgb(73, 175, 72)",
                                        fontSize: "13px",
                                      }}
                                    />
                                    <b
                                      style={{
                                        fontSize: "11px",
                                        fontFamily: "roboto",
                                      }}
                                    >
                                      View
                                    </b>
                                  </span>
                                </button>
                              </Link>

                              {c.curriculum[c.curriculum.length - 1] ===
                              ")" ? null : (
                                <span className="copy_btn">
                                  <span className="threedotbtn border px-2">
                                    ⋮
                                  </span>

                                  <span className="copy_menu">
                                    <ul>
                                      <li
                                        onClick={() => {
                                          document
                                            .querySelectorAll(".copy_menu")
                                            .forEach((e) => {
                                              e.style.display = "none";
                                            });
                                          async function test() {
                                            await CopyCurriculumm(c._id);
                                            window.location.reload();
                                          }
                                          test();
                                        }}
                                        style={{ fontFamily: "roboto" }}
                                      >
                                        Copy curriculum
                                      </li>
                                      <li
                                        onClick={() => {
                                          document
                                            .querySelectorAll(".copy_menu")
                                            .forEach((e) => {
                                              e.style.display = "none";
                                            });
                                          async function test1() {
                                            await CopyCurrSubCourseQuestion(
                                              c._id
                                            );
                                            window.location.reload();
                                          }
                                          test1();
                                        }}
                                        style={{ fontFamily: "roboto" }}
                                      >
                                        Copy curriculum+Subject+Course+Question
                                      </li>
                                    </ul>
                                  </span>
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Curriculum;
