import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import ImageCompent from '../../components/admin/image/imageList';
import { useDispatch, useSelector } from 'react-redux';
import * as Img from '../../store/actions/logoImage'
import { fileUpload } from "../../services/Admin/videoService";
import { getLogo, updateImage } from "../../services/Admin/commonService";
const ImagesContainer = () => {
    const [logoImg, setLogoImg] = useState('')
    const dispatch = useDispatch()
    const [upImg, setUpImg] = useState('')
    const [loading, setLoading] = useState(false)
    const [logoData, setlogoData] = useState({})
    const [open, setOpen] = useState(true);

    const uploadFileChange = (event) => {
        setUpImg(event.target.files[0])
    }

    const submitupload = () => {
        const data = new FormData();
        console.log(1)
        console.log(upImg)

        if (upImg) {
            data.append(
                "profileImage",
                upImg,
                upImg.name
            );
            let headers = {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            };
            setLoading(true)
            fileUpload(data).then(res => {
                console.log('imf-' + res.data.fileLocation)
                let payload = {
                    "fileName": res.data.fileName,
                    "imageUrl": res.data.fileLocation
                }
                updateImage(payload,logoData._id).then(result => {
                    if (result.status === 200) {
                        dispatch(Img.getlogo())

                    } else {
                        console.log('error---')
                    }
                    setLoading(false)
                }).catch(err => console.log('da'))
            }).catch(err => console.log('data-img'))
        }
    }
    const getLogoData = async () => {
        const response = await getLogo();
        setlogoData(response.data.data)
        setOpen(false)
    }
    useEffect(() => {
        getLogoData()
        dispatch(Img.getlogo())

    }, [])


    let image = useSelector(state => {
        return state.logo.logo
    })
    useEffect(() => {
        console.log('image logo')
        setLogoImg(image)
    }, [image])
    return <Fragment>
        <ImageCompent logoImg={logoImg} uploadFileChange={uploadFileChange} submitupload={submitupload} loading={loading} open={open} />
    </Fragment>
}

export default ImagesContainer