import React from "react";
import parse from "html-react-parser";
import { deleteCourse } from "../../../services/Admin/courseService"
import '../course/course.css'
import { Backdrop, CircularProgress } from "@mui/material";


const ViewCourseContent = ({ courseListIndividual, closeViewCourse,open }) => {
  const data = courseListIndividual ? courseListIndividual : [];

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="row justify-content-center">
        <div className="closePage">
          {/* <h3>View Course Content</h3> */}
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            View Chapter
          </h3>
          <button
            className="btn btn-sm "
            onClick={() => {
              deleteCourse(data._id);
              closeViewCourse();
            }}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-sm  mr-2"
            onClick={closeViewCourse}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>
        <div className="col-md-12">
          <div>
            <div className="card mb-3">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.curriculum}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.subject}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject Info</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.subjectInfo ? parse(data.subjectInfo) : ""}
                  </span>
                </div>
                {/* <div className="text-view">
                <label>Min Credits</label>
                <span>{data && data.minCredits}</span>
              </div>
              <div className="text-view">
                <label>Max Credits</label>
                <span>{data && data.maxCredits}</span>
              </div>
              <div className="text-view">
                <label>Practical Session</label>
                <span>{data && data.practicalSession}</span>
              </div> */}
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Topic Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data && data.topic}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Topic Info</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.topicInfo ? parse(data.topicInfo) : ""}
                  </span>
                </div>
              </div>

              <div
                className="col-md-12 mb-3 table_container"
                style={{ padding: "0px 22px" }}
              >
                <h5 style={{ fontSize: "18px", fontFamily: "roboto" }}>
                  Chapters Details
                </h5>
                <table className="table table">
                  <thead>
                    <tr>
                      <th style={{ width: "35%", fontFamily: "roboto" }}>
                        Chapter Name
                      </th>
                      <th style={{ fontFamily: "roboto" }}>Chapter Info</th>
                      {/* <th>Chapter Video</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.chapters &&
                      data.chapters.map((ch, index) => {
                        return (
                          <tr>
                            <td style={{ fontFamily: "roboto" }}>
                              {" "}
                              {ch.chapter}{" "}
                            </td>
                            {/* <td>{ch.chapterInfo ? parse(ch.chapterInfo) : ""}{ch.chapterInfo.split("oembed")[1].split(">")[0]}</td> */}
                            {/* {console.log(ch.chapterInfo.split('oembed')[1])}  */}
                            <td style={{ fontFamily: "roboto" }}>
                              {" "}
                              {ch.chapterInfo ? parse(ch.chapterInfo) : ""}{" "}
                              {ch.chapterInfo.split("oembed")[1] !== undefined
                                ? ch.chapterInfo
                                    .split("oembed")[1]
                                    .split(">")[0]
                                : null}{" "}
                            </td>
                            {/* <td style={{ width: "70%" }}>
                            {ch.fileLocation ? ch.fileLocation : ""}
                          </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCourseContent;
