import React from "react";
import { useHistory } from "react-router-dom";
import help from '../../../assets/Banners/Banner5.png'
import styles from "./HelpFromTutor.module.css";

const RequestType = ({ requestType, raiseRequest }) => {
  const path = useHistory().location.pathname;
  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "70px",
        }}
      >
        <div>
          <div
            className={`tabItem ${
              path === "/student/help-from-tutor/open" ? "tabItemActive" : ""
            }`}
            onClick={() => requestType("open")}
            style={{ marginLeft: "20px", fontFamily: "roboto" }}
          >
            Open
          </div>
          <div
            className={`tabItem ${
              path === "/student/help-from-tutor/scheduled"
                ? "tabItemActive"
                : ""
            }`}
            onClick={() => requestType("scheduled")}
            style={{ fontFamily: "roboto" }}
          >
            Scheduled
          </div>
          {/* <div
          className={`tabItem ${path === "/student/help-from-tutor/completed" ? "tabItemActive" : ""
            }`}
          onClick={() => requestType("completed")}
        >
          Completed
        </div> */}
        </div>
        <div>
          <button
            className={`btn  mb-3 btn-right mr-0 ${styles.requestQuery}`}
            onClick={raiseRequest}
            style={{ fontFamily: "roboto" }}
          >
            Request A Session
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RequestType;
