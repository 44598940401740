import React from 'react'
import ChatBott from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';


const steps = [
    {
        id: '0',
        message: 'Hey!',
        trigger: '1',
    }, {
        id: '1',
        message: 'Please write your username',
        trigger: '2'
    }, {
        id: '2',
 
        // Here we want the user
        // to enter input
        user: true,
        trigger: '3',
    }, {
        id: '3',
        message: " hi {previousValue}, how can I help you?",
        trigger: 4
    }, {
        id: '4',
        options: [
             
            // When we need to show a number of
            // options to choose we create alist
            // like this
            { value: 1, label: 'View Courses' },
            { value: 2, label: 'Read Articles' },
 
        ],
        end: true
    }
];

// Creating our own theme
const theme = {
    background: '#F1E3FE',
    headerBgColor: '#4a1e75',
    headerFontSize: '20px',
    botBubbleColor: '#0F3789',
    headerFontColor: 'white',
    botFontColor: 'white',
    userBubbleColor: '#F5F2B9',
    userFontColor: 'black',
};

const config = {
    botAvatar: "https://static-00.iconduck.com/assets.00/profile-circle-icon-1023x1024-ucnnjrj1.png",
    floating: true,
};

const ChatBot = () => {
  return (
    <>
    <div className="App">
            <ThemeProvider theme={theme}>
                <ChatBott
                    headerTitle="ChatBot"
                    steps={steps}
                    {...config}
 
                />
            </ThemeProvider>
        </div>
    </>
  )
}

export default ChatBot;