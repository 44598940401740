import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const ViewStudent = props => {
    const { studentValues, staffDelete, changeStatus,curriculumData,active,open } = props
   
    let cur=["This student not buy any curriculum"];;
    let curCode=["This student not buy any curriculum"];
    if(curriculumData){

       cur=curriculumData.map(e=>e.curriculum);
      curCode=curriculumData.map(e=>e.curriculumCode);
    }
const history = useHistory();


    return (
      <>
      
        <div className="row justify-content-center">
          <div className="closePage">
            <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
              Student Detail
            </h3>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => staffDelete(studentValues._id)}
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              
              
                <b style={{ fontSize: "11px", fontFamily: "roboto" }}>Delete</b>
              
            </button>
            <button
              className="btn btn-sm btn-secondary mr-2"
              onClick={() => history.push("/admin/student")}
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
                marginRight: "5px",
              }}
            >
              Close
            </button>
          </div>
          {/* <div style={{ marginTop: "20px", display: "flex" }}>
            <div style={{ marginLeft: "30px" }}>
              <button
                className="btn btn-sm btn-danger float-right ml-3"
                onClick={() => staffDelete(studentValues._id)}
                style={{
                  backgroundColor: "rgb(228, 152, 152)",
                }}
              >
                <span style={{ color: "#ed2d2d" }}>
                  <DeleteIcon style={{ color: "#ed2d2d", fontSize: "13px" }} />
                  <b style={{ fontSize: "11px", fontFamily: "roboto" }}>
                    Delete
                  </b>
                </span>
              </button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <button
                className="btn btn-sm btn-secondary mr-2"
                onClick={() => history.push("/admin/student")}
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                  marginRight: "5px",
                }}
              >
                Close
              </button>
             
            </div>
          </div> */}
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* <h1
                className="form-heading"
                style={{ color: "#4A1D75", fontFamily: "roboto" }}
              >
                Student Detail
              </h1> */}

              <div className="card mb-3">
                <div className="card-body">
                  <div className="text-view">
                    <label style={{ fontFamily: "roboto" }}>Name </label>
                    <span style={{ fontFamily: "roboto" }}>
                      {studentValues.firstName} {studentValues.middleName}{" "}
                      {studentValues.lastName}
                    </span>
                  </div>
                  <div className="text-view">
                    <label style={{ fontFamily: "roboto" }}>mobile</label>
                    <span style={{ fontFamily: "roboto" }}>
                      {studentValues.mobile}
                    </span>
                  </div>
                  <div className="text-view">
                    <label style={{ fontFamily: "roboto" }}>email</label>
                    <span style={{ fontFamily: "roboto" }}>
                      {studentValues.email}
                    </span>
                  </div>
                  <div className="text-view">
                    <label style={{ fontFamily: "roboto" }}>address</label>
                    <span style={{ fontFamily: "roboto" }}>
                      {studentValues.address}
                    </span>
                  </div>
                  {props.role !== "admin" && (
                    <Fragment>
                      <div className="text-view">
                        <label style={{ fontFamily: "roboto" }}>
                          Curriculum
                        </label>
                        <span style={{ fontFamily: "roboto" }}>
                          {cur.join()}
                        </span>
                      </div>
                      <div className="text-view">
                        <label style={{ fontFamily: "roboto" }}>
                          curriculum Code
                        </label>
                        <span style={{ fontFamily: "roboto" }}>
                          {curCode.join()}
                        </span>
                      </div>
                      {props.role !== "teacher" && (
                        <Fragment>
                          {" "}
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              gender
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.gender}
                            </span>
                          </div>
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              Date of birth
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.dob &&
                                studentValues.dob.split("T")[0]}
                            </span>
                          </div>
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              country
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.country}
                            </span>
                          </div>
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              state
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.state}
                            </span>
                          </div>
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>city</label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.city}
                            </span>
                          </div>
                          <hr />
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              Parent/Gaurdian Name
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.parentOrGardien &&
                                studentValues.parentOrGardien.parentFirstName}
                              {studentValues.parentOrGardien &&
                                studentValues.parentOrGardien.parentMiddleName}
                              {studentValues.parentOrGardien &&
                                studentValues.parentOrGardien.parentLastName}
                            </span>
                          </div>
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              Parent/Gaurdian Email
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.parentOrGardien &&
                                studentValues.parentOrGardien.parentEmail}
                            </span>
                          </div>
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              Parent/Gaurdian Mobile
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.parentOrGardien &&
                                studentValues.parentOrGardien.parentMobile}
                            </span>
                          </div>
                          <div className="text-view">
                            <label style={{ fontFamily: "roboto" }}>
                              Parent/Gaurdian Address
                            </label>
                            <span style={{ fontFamily: "roboto" }}>
                              {studentValues.parentOrGardien &&
                                studentValues.parentOrGardien.parentAddress}
                            </span>
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default ViewStudent