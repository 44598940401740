import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export const getAssignments = (payload) => {
  return http.post(apiUrl + "/assessment/getAssessmentByCur", payload, {
    headers: headers,
  });
};

export const getAssignmentByID = (payload) => {
  return http.post(apiUrl + "/assessment/getAssessment", payload, {
    headers: headers,
  });
};
export const getMeeting = (payload) => {
  return http.post(apiUrl + "/teams/createMeeting", payload, {
    headers: headers,
  });
};
export const getTestQuesList = (payload) => {
  return http.post(apiUrl + "/assessment/getTestQuesList", payload, {
    headers: headers,
  });
};

export const getAssignmentResult = (payload) => {
  return http.post(apiUrl + "/transaction/getAssignmentsResult", payload, {
    headers: headers,
  });
};
export const testSubmit = (payload) => {
  return http.post(apiUrl + "/transaction/testSubmit", payload, {
    headers: headers,
  });
};

export const getResult = (payload) => {
  return http.post(apiUrl + "/transaction/getResult", payload, {
    headers: headers,
  });
};

export const getTestsList = (payload) => {
  return http.post(apiUrl + "/getTestsList", payload, {
    headers: headers,
  });
};

export const checkStudentTest = (payload) => {
  return http.post(apiUrl + "/transaction/checkStudentTest", payload, {
    headers: headers,
  });
};


export const getCertify = (payload) => {
  return http.post(apiUrl + "/transaction/data/getCertify", payload, {
    headers: headers,
  });
};

export const AddDocumentStudent = (payload,userId) => {
  return http.post(apiUrl + `/user/addUploadFileData/${userId}`, payload, {
    headers: headers,
  });
};