import React, { useEffect } from 'react'
import styles from "./TeacherReport.module.css"
import vector1 from "../../..//assets/Images/vectorbook.png"
import vector2 from "../../..//assets/Images/vector2.png"
import vector3 from "../../..//assets/Images/vector3.png"
import vector4 from "../../..//assets/Images/vector4.png"
import logoreport from "../../..//assets/Images/logotestreport.png"
export default function TeacherReport() {

  return (
    <div className={`container p-0 ${styles.MainDiv}`}>
      <div className="session_heading mt-2 w-100">
        {/* <h1></h1> */}
        <div className={`${styles.heading}`}>
          <img src={logoreport} />
        </div>
      </div>
      <div className={`${styles.assessmentHeading}`}>
        <h2>ASSESSMENT REPORT</h2>
      </div>
      <div className={`${styles.mainContainer}`}>
        <div className={`${styles.firstRow}`}>
          <div className={`${styles.vector1}`}>
            <div className={`${styles.img1}`}>
              <img src={vector1} className={`${styles.imgaICon1}`} />
            </div>
            <br />
            {/* <div> */}
            <span style={{ display: "flex", justifyContent: "center" }}>
              Curriculum Name
            </span>
            {/* <br /> */}
            <h5 style={{ display: "flex", justifyContent: "center" }}>
              <b>IIT -Organisation</b>
            </h5>

            {/* </div> */}
          </div>
          <div className={`${styles.vector2}`}>
            <div className={`${styles.img2}`}>
              <img src={vector2} className={`${styles.imgaICon2}`} />
            </div>
            <br />
            <span style={{ display: "flex", justifyContent: "center" }}>
              Subject Name
            </span>
            {/* <br /> */}
            <h5 style={{ display: "flex", justifyContent: "center" }}>
              <b>Computer Science</b>
            </h5>
          </div>
        </div>
        <div className={`${styles.secondRow}`}>
          <div className={`${styles.vector3}`}>
            <div className={`${styles.img3}`}>
              <img src={vector3} className={`${styles.imgaICon3}`} />
            </div>
            <br />
            <span style={{ display: "flex", justifyContent: "center" }}>
              Assessment Name
            </span>
            {/* <br /> */}
            <h5 style={{ display: "flex", justifyContent: "center" }}>
              <b>Sample 1</b>
            </h5>
          </div>
          <div className={`${styles.vector4}`}>
            <div className={`${styles.img4}`}>
              <img src={vector4} className={`${styles.imgaICon3}`} />
            </div>
            <br />
            <span style={{ display: "flex", justifyContent: "center" }}>
              Total Marks
            </span>
            {/* <br /> */}
            <h5 style={{ display: "flex", justifyContent: "center" }}>
              <b>95/100 </b>
            </h5>
          </div>
        </div>
      </div>
      <div className={`${styles.LastBox}`}>
        <div className={`${styles.testDuration}`}>
          <h6>Total Assessment Duration</h6>
          <p>10 mins</p>
        </div>
      </div>
      <br />
      <div className={`${styles.LastBox}`}>
        <div className={`${styles.testDuration}`}>
          <h6>Total Time Taken</h6>
          <p>8.15 mins</p>
        </div>
      </div>
    </div>
  );
}
