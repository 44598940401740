
import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getStaffList } from "../../../services/Admin/staffService";
import { userID } from "../../../services/Authentication/AuthService";
// import { DeleteStaff } from "./DeleteStaff";
import { deleteStaff } from "../../../services/Admin/staffService";
const ViewStaff = props => { 
  const { staffDelete } = props;
  const params = useParams()
  const [staffData, setStaffData] = useState({});
  const payload = {
    userID: params.id,

  };

  const fetchStaffData = async () => {
    try {
      const response = await getStaffList({ userID: params.id });
      console.log(response);
      if (response.data.data.length !== 0) {

        setStaffData(response.data.data[0]); // Assuming the data is in the 'data' property
      }
    } catch (error) {
      console.error("Error fetching staff data:", error);
      // Handle error, show a message, etc.
    }
  };



  console.log(staffData.assignCurrculum)



  const history = useHistory();

  useEffect(() => {
    fetchStaffData()
    console.log(params.id)
  }, [])

  // if (!staffData.data) {
  //   return <div>Loading...</div>; // You can customize the loading state
  // }
  return (
    <>

      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            Staff Detail
          </h3>
          {/* <button
            className="btn btn-sm btn-danger"
            onClick={() => staffDelete(params.id)}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >


            <b style={{ fontSize: "11px", fontFamily: "roboto" }}>Delete</b>

          </button> */}
          {/* <DeleteStaff
        id={params.id}
        deleteStaff2={staffDelete}
      /> */}
          <button
            className="btn btn-sm btn-secondary mr-2"
            onClick={() => history.push("/admin/staff")}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>
        {/* <div style={{ marginTop: "20px", display: "flex" }}>
            <div style={{ marginLeft: "30px" }}>
              <button
                className="btn btn-sm btn-danger float-right ml-3"
                onClick={() => staffDelete( ._id)}
                style={{
                  backgroundColor: "rgb(228, 152, 152)",
                }}
              >
                <span style={{ color: "#ed2d2d" }}>
                  <DeleteIcon style={{ color: "#ed2d2d", fontSize: "13px" }} />
                  <b style={{ fontSize: "11px", fontFamily: "roboto" }}>
                    Delete
                  </b>
                </span>
              </button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <button
                className="btn btn-sm btn-secondary mr-2"
                onClick={() => history.push("/admin/student")}
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                  marginRight: "5px",
                }}
              >
                Close
              </button>
             
            </div>
          </div> */}
        <div className="row justify-content-center">
          <div className="col-md-12">
            {/* <h1
                className="form-heading"
                style={{ color: "#4A1D75", fontFamily: "roboto" }}
              >
                Student Detail
              </h1> */}

            <div className="card mb-3">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Staff Name </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {staffData.firstName} {staffData.lastName}

                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Roll</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {staffData.role}


                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Email</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {staffData.email}



                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Curriculum</label>
                  <span style={{ fontFamily: "roboto" }} key={params.id}>
                    {staffData?.assignCurrculum?.map((res) => {
                      return (
                        <>
                          <span>{`${res?.curriculum},`}</span>
                        </>
                      );
                    })}


                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {staffData?.assignCurrculum?.map((res) => {
                      return (
                        <>
                          <span>{`${res?.subject},`}</span>
                        </>
                      );
                    })}

                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Mobile no</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {staffData.mobile}

                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Address</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {staffData.address}

                  </span>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
      
    

    </>
  );
}

export default ViewStaff