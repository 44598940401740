import React, { useEffect, useState, useRef } from "react";
import "./AddAddress.css";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  editAddressService,
  getAddressById,
} from "../../../services/Admin/AddressService";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { addAddressService } from "../../../services/Admin/AddressService";
import { getCurriculumList } from "../../../services/Admin/commonService";
import { getSubjectsByCur } from "../../../services/Admin/commonService";
import { City, Country, State } from "country-state-city";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function EditAddress() {
  const history = useHistory();
  const addresData = useRef({
    curriculum: "",
    curriculumCode: "",
    subject: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    houseNo: "",
    area: "",
    nearBy: "",
    university: "",
    branch: "",
    room_no: "",
    floor: "",
    block: "",
    title: "",
  });
  const [curriculum, setCurriculum] = useState([]);
  const [subject, setSubject] = useState([]);
  let [country, setcountry] = useState([]);
  let [changes, setChanges] = useState([]);

  const { id } = useParams();
  const [address, setAddress] = useState({});

  async function addresses() {
    const adre = await getAddressById(id);
    addresData.current = adre.data.data;
    setLoader(false);
    setItems(adre.data.data.facilities)
    console.log(adre.data.data);
    setAddress(adre.data.data);
  }
  let [state, setstate] = useState([]);
  let [city, setcity] = useState([]);
  let [loader, setLoader] = useState(true);
  let [countrycode, setcountrycode] = useState("");
  let [Statedis, setStatedis] = useState(false);
  let [Citydis, setCitydis] = useState(false);
  const [countrySelect, setCountrySelect] = useState("");
  const [stateSelect, setStateSelect] = useState("");

  async function curriculumLists() {
    const data = await getCurriculumList().then((e) => {
      return e.data.data;
    });
    setCurriculum(data);
    // console.log(data)
  }
  async function subjectLists(curr) {
    addresData.current.curriculumCode = curr;
    const data = await getSubjectsByCur({ curriculumCode: curr }).then((e) => {
      return e.data.data;
    });
    setSubject(data);
  }
  function citychange(e) {
    const selectedCity = e.target.value;
    addresData.current.city = selectedCity;
    setCitydis(false); // Clear Citydis if needed
  }

  async function editaddres(e) {
    e.preventDefault();
    setLoader(true);
console.log(items)

    addresData.current.country = countrySelect.split(",")[1];
    addresData.current.state = stateSelect.split(",")[1];
    // console.log(addresData.current)

    try {
      const edit = await editAddressService({...addresData.current,facilities:items}, id);
      history.push("/admin/address");
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
    // edit(editaddres)
  }

  const [inputText, setInputText] = useState("");
  const [items, setItems] = useState([]);

  const addItem = () => {
    if (inputText.trim() === "") {
      alert("Please enter a valid item.");
      return;
    }

    setItems([...items, inputText]);
    setInputText("");
  };

  const removeItem = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  function countrychange(e) {
    setCountrySelect(e);
    setStateSelect("");
    addresData.current.city = "";
    setstate([...State.getStatesOfCountry(e.split(",")[0])]);
  }

  function statechange(e) {
    setStateSelect(e);
    addresData.current.city = "";
    setcity([
      ...City.getCitiesOfState(countrySelect.split(",")[0], e.split(",")[0]),
    ]);
  }
  function ss(e, c) {
    let sta = e.filter((res) => res.name == addresData.current.state);
    setStateSelect(`${sta[0]?.isoCode},${sta[0]?.name}`);
    let arr = [...City.getCitiesOfState(c, sta[0]?.isoCode)];
    setcity([...arr]);
  }
  function citychange(e) {
    // setCityData(e)
    addresData.current.city = e;
  }
  useEffect(() => {
    if (address.address !== undefined) {
      setLoader(false);
    }

    setcountry([...Country.getAllCountries()]);
    let coun = country.filter((res) => res.name == address.country);
    console.log(coun);
    setcountrycode(coun[0]?.isoCode);
    setCountrySelect(`${coun[0]?.isoCode},${coun[0]?.name}`);
    // console.log(countrySelect)
    // address.country = `${coun[0]?.isoCode},${coun[0]?.name}`
    let arr = [...State.getStatesOfCountry(coun[0]?.isoCode)];
    // console.log(arr)
    setstate([...arr]);
    ss(arr, coun[0]?.isoCode);
  }, [address, Country, State, City]);

  function ss(e, c) {
    let sta = e.filter((res) => res.name == address?.state);
    setStateSelect(`${sta[0]?.isoCode},${sta[0]?.name}`);
    let arr = [...City.getCitiesOfState(c, sta[0]?.isoCode)];
    setcity([...arr]);
  }

  useEffect(() => {
    curriculumLists();
    addresses();
  }, []);
  useEffect(() => {
    subjectLists(address?.curriculumCode);
  }, [address]);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container ">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            width: "98%",
            alignItems: "center",
          }}
        >
          <div>
            <h1
              className="form-heading"
              style={{ color: "#4A1D75", fontFamily: "roboto" }}
            >
              Edit Address
            </h1>
          </div>
          <div
          // className="add-btn-div"
          >
            <Link
              to={`/admin/address`}
              className="btn btn-sm "
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Cancel
            </Link>
          </div>
        </div>

        <div className="container23 pb-5">
          <form onSubmit={editaddres}>
            <div className="container ">
              <div className="container23  pb-5">
                <div className="row pt-3">
                  <div className="col-md-4">
                    <label htmlFor="inputname">Title</label>
                    <input
                      type="text"
                      defaultValue={address?.title}
                      className="form-control"
                      onChange={(e) =>
                        (addresData.current.title = e.target.value)
                      }
                      placeholder=" Enter Your Title"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">Curriculum Name</label>
                    <select
                      className="form-select"
                      value={address?.curriculumCode}
                      required
                      onChange={(e) => {
                        let curriculumCode = e.target.value;
                        subjectLists(e.target.value);
                        let data = curriculum?.filter(
                          (s) => s?.curriculumCode === curriculumCode
                        );
                        addresData.current.curriculum = data[0]?.curriculum;
                      }}
                    >
                      <option value={"Select"} selected disabled>
                        {"Select "}
                      </option>

                      {curriculum.map((e) => (
                        <option value={e?.curriculumCode}>
                          {e?.curriculum}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">Subject Name</label>
                    <select
                      className="form-select"
                      value={address?.subject}
                      required
                      onChange={(e) => {
                        addresData.current.subject = e.target.value;
                      }}
                    >
                      <option value={"Select"} selected disabled>
                        {"Select "}
                      </option>
                      {subject.map((e) => (
                        <option value={e?.subject}>{e?.subject}</option>
                      ))}
                    </select>
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-md-4">
                    <label htmlFor="inputname">Country Name</label>
                    <select
                      className="select-box"
                      required
                      value={countrySelect}
                      onChange={(e) => {
                        countrychange(e.target.value);
                      }}
                    >
                      <option value="" disabled={Statedis}>
                        {" "}
                        Select country{" "}
                      </option>{" "}
                      {country &&
                        country.map((e, index) => {
                          return (
                            <option
                              key={index}
                              value={`${e.isoCode},${e.name}`}
                            >
                              {e.name}
                            </option>
                          );
                        })}
                    </select>
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">State Name</label>
                    <select
                      className="form-select"
                      value={stateSelect}
                      required
                      onChange={(e) => {
                        console.log(e.target.value);
                        addresData.current.state = e.target.value.split(",")[1];
                        statechange(e.target.value);
                      }}
                    >
                      <option value="" disabled={Statedis}>
                        {" "}
                        Select State{" "}
                      </option>
                      {state &&
                        state.map((e, index) => {
                          return (
                            <option
                              key={index}
                              value={`${e.isoCode},${e.name}`}
                            >
                              {e.name}
                            </option>
                          );
                        })}
                    </select>
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">City Name</label>

                    <select
                      className="form-select"
                      value={addresData.current.city} // Bind the selected city to the value attribute
                      onChange={(e) =>
                        (addresData.current.city = e.target.value)
                      }
                    >
                      <option disabled={Citydis}> Select City </option>
                      {city &&
                        city.map((e, index) => {
                          return (
                            <option key={index} value={e.name}>
                              {e.name}
                            </option>
                          );
                        })}
                    </select>
                    <KeyboardArrowDownIcon className="drop_icon" />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-4">
                    <label htmlFor="inputname">Campus</label>
                    <input
                      type="text"
                      defaultValue={address?.branch}
                      className="form-control"
                      onChange={(e) =>
                        (addresData.current.branch = e.target.value)
                      }
                      placeholder=" Enter Your Campus"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">University</label>
                    <input
                      type="text"
                      defaultValue={address?.university}
                      onChange={(e) =>
                        (addresData.current.university = e.target.value)
                      }
                      className="form-control"
                      placeholder=" Enter Your University"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">Block</label>
                    <input
                      type="text"
                      defaultValue={address?.block}
                      onChange={(e) =>
                        (addresData.current.block = e.target.value)
                      }
                      className="form-control"
                      placeholder="Enter Your Block"
                    />
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-md-4">
                    <label htmlFor="inputname">Area</label>
                    <input
                      type="text"
                      defaultValue={address?.area}
                      onChange={(e) =>
                        (addresData.current.area = e.target.value)
                      }
                      className="form-control"
                      placeholder="Enter Your Area"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">Near By</label>
                    <input
                      type="text"
                      defaultValue={address?.nearBy}
                      onChange={(e) =>
                        (addresData.current.nearBy = e.target.value)
                      }
                      className="form-control"
                      placeholder="Enter Near By"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">Building No</label>
                    <input
                      type="text"
                      defaultValue={address?.houseNo}
                      onChange={(e) =>
                        (addresData.current.houseNo = e.target.value)
                      }
                      className="form-control"
                      placeholder="Enter Your Building No"
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-4">
                    <label htmlFor="inputname">Floor No</label>
                    <input
                      type="text"
                      defaultValue={address?.floor}
                      onChange={(e) =>
                        (addresData.current.floor = e.target.value)
                      }
                      className="form-control"
                      placeholder="Enter Your Floor"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">Room No</label>
                    <input
                      type="text"
                      defaultValue={address?.room_no}
                      onChange={(e) =>
                        (addresData.current.room_no = e.target.value)
                      }
                      className="form-control"
                      placeholder="Enter Your Room No "
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputname">Pincode</label>
                    <input
                      type="number"
                      defaultValue={address?.pincode}
                      onChange={(e) =>
                        (addresData.current.pincode = e.target.value)
                      }
                      className="form-control"
                      placeholder="Enter Your Pincode "
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-4 mt-2 px-0 ">
                      <label htmlFor="inputname">Add Facilities</label>
                      <div className="add-facalities-div">
                        <input
                          className="form-control custom-css-add"
                          type="text"
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                          placeholder="Enter Text"
                        />
                        <button type="button" onClick={addItem} className="add-btn">
                          Add
                        </button>
                      </div>

                      <div
                        style={{
                          listStyle: "none",
                          padding: 0,
                          margin: 0,
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        {items.map((item, index) => (
                          <div key={index} className="add-input-div">
                            <span>{item}</span>
                            <span
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => removeItem(index)}
                            >
                              X
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-sm  mt-4"
                    style={{
                      borderRadius: "40px",
                      backgroundColor: "#4A1D75",
                      border: "2px solid #4A1D75",
                      width: "140px",
                      textAlign: "center",
                      padding: "6px",
                      color: "white",
                      height: "auto",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
