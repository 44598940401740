import React, { Component } from "react";
import Dashboard from "../../components/Students/Dashboard/Dashboard";
import { getAssignments } from "../../services/Student/AssignmentService";
import { getAllQueries } from "../../services/Student/QueryService";
import { getAllSessions, getSession, getUpcomingSession, getusercur } from "../../services/Student/SessionService";
import {
  userID,
  userCurriculumCode,
  userCurriculum
} from "../../services/Authentication/AuthService";
import { getUserCurriculum } from "../../services/Student/CurriculumStudentService"
class DashboardContainer extends Component {
  state = { assignmentList: [], allQueries: {}, allSessions: {}, upcomingSessionList: [], userInfo: { curriculum: [], endDate: "", startDate: "", location: "", fieldname: "" }, userCurriculumsData: [], userCurriculumsCode: [] };
  getUserCurriculums = () => {
    getUserCurriculum(userID()).then((res) => {
      this.setState({ userCurriculumsData: res.data.data });
      let cur = res.data.data.map(e => e.curriculum)
      sessionStorage.setItem("currculum", cur)
    })
  }
  getAllAssignments = async () => {
    getUserCurriculum(userID()).then((res) => {
      var data = res.data.data
      let code = []
      if (data !== undefined) {

        data.map((e) => {
          code.push(e.curriculumCode)
        })

      }
      const payload = {
        curriculumCode: code

      };
      getAssignments(payload)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ assignmentList: res.data.data });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    })

  };
  getAllQueriesList = async () => {
    const payload = {
      userID: userID(),
      curriculumCode: userCurriculumCode(),
      limit: 4,
    };
    getAllQueries(payload)
      .then((res) => {
        this.setState({ allQueries: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };



  getAllSessionsList = async () => {
    const payload = {
      condition: "all",
      curriculumCode: userCurriculumCode(),
      limit: 4,
    };
    getAllSessions(payload)
      .then((res) => {
        // this.setState({ allSessions: res.data.data });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  getSession = () => {
    const payload = { curriculumCode: userCurriculumCode() }
    getSession(payload).then(res => {
      if (res.status === 200) {
        this.setState({ allSessions: res.data.sessionInfo })
      } else {

      }
    })
  }
  upcomingSession = () => {
    getUserCurriculum(userID()).then((res) => {
      var data = res.data.data
      let code = []
      if (data !== undefined) {

        data.map((e) => {
          code.push(e.curriculumCode)
        })
      }
      const payload = {
        curriculumCode: code

      };
      getUpcomingSession(payload).then(res => {
        if (res.status === 200) {
          let arr = res.data.data.filter(el => {
            return el.status !== 'PENDING'
          })
          this.setState({ upcomingSessionList: arr })
          console.log(arr, "<-session");
        } else {
          this.setState({ upcomingSessionList: [] })
        }
      })
    })
  }

  getDetail = () => {
    const payload = { curriculum: userCurriculum() }
    console.log(payload, "gdfsagvds");
    getusercur(payload).then(res => {
      if (res.status === 200 && res.data.status) {

        this.setState({
          userInfo: {
            curriculum: res.data.data.curriculum
            , endDate: res.data.data.endDate, startDate: res.data.data.startDate, location: res.data.data.location || ""
          }
        })

      } else {

      }

    }).catch(err => {
      console.log('err', err);
    })
  }

  componentDidMount() {
    this.upcomingSession()
    this.getDetail()
    this.getUserCurriculums()
    this.getAllQueriesList();
    this.getAllSessionsList();
    this.getSession()
    this.getAllAssignments();

  }
  render() {
    const { assignmentList, allQueries, allSessions, userInfo } = this.state;
    // console.log('id---------------' + userID())

    return (
      <React.Fragment>
        <Dashboard
          allQueries={allQueries}
          assignmentList={assignmentList}
          allSessions={allSessions}
          upcomingSession={this.state.upcomingSessionList}
          upcomingSessionLIst={this.state.upcomingSessionList}
          userInfo={userInfo}
          userCurriculumsData={this.state.userCurriculumsData}
        />
      </React.Fragment>
    );
  }
}

export default DashboardContainer;