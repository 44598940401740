import React from "react";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
import { deleteTemplate } from "../../../services/Admin/templateService";
import { Backdrop, CircularProgress } from "@mui/material";

const ViewTemplate = (props) => {
  const history = useHistory();
  const { templateData,  questionsList,open } = props;

  return (
    <>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            View Template
          </h3>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              deleteTemplate({ templateID: templateData.templateID });
              history.goBack();
            }}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              // marginRight: "5px",
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-sm btn-secondary mr-2"
            onClick={() => history.goBack()}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>
        <div className="col-md-12">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Template ID</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {templateData.templateID ? templateData.templateID : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Template Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {templateData.templateName ? templateData.templateName : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {templateData.curriculum ? templateData.curriculum : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {templateData.subject ? templateData.subject : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>templateType</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {templateData.templateType ? templateData.templateType : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-3 ">
            {questionsList.length > 0 &&
              questionsList.map((ql, i) => {
                return (
                  <div key={ql._id} className="question-list card mb-2">
                    <label style={{ fontFamily: "roboto" }}>
                      Question Type: {ql.questionType}
                    </label>
                    <label style={{ fontFamily: "roboto" }}>
                      Question Marks: {ql.questionmarks}
                    </label>
                    <label style={{ fontFamily: "roboto" }}>
                      Question ID: {ql.questionID}
                    </label>
                    <hr />
                    <div>
                      <div className="ans">
                        <span style={{ float: "left", marginRight: "10px" }}>
                          {i + 1 + "."}
                        </span>
                        {parse(ql.question)}
                      </div>
                      {ql.questionType === "Multi" && (
                        <React.Fragment>
                          <div className="ans">
                            <i className="las la-check-circle"></i>{" "}
                            {ql.correctanswer}
                          </div>
                          <div className="ans">
                            <i className="las la-times-circle"></i> {ql.answer1}
                          </div>
                          <div className="ans">
                            <i className="las la-times-circle"></i> {ql.answer2}
                          </div>
                          <div className="ans">
                            <i className="las la-times-circle"></i> {ql.answer3}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTemplate;
