import React from "react";
import { Link } from "react-router-dom";
import { copyTemplate } from "../../../services/Admin/templateService";
import style from "./template.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { Backdrop, CircularProgress } from "@mui/material";

const Template = ({ templatesList, search, searchTemplate, open }) => {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <h1
          className="form-heading"
          style={{ color: "#4A1D75", fontFamily: "roboto" }}
        >
          Templates
        </h1>
        {/* <div className="templateHeader"> */}
        {/* <div class="input-group row col-12   mb-2 "> */}
        {/* <div class="input-group-prepend"> */}
        {/* <div class="input-group-text" style={{ borderRadius: "40px" }}>
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchTemplate(text)}
              class="form-control"
              placeholder="Search Curriculum/Subject/Template Name"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
        {/* </div> */}
        {/* </div>
        <div style={{ borderRadius: "40px" }}>
          <Link
            to={`/admin/add-template`}
            className="btn btn-sm add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            Add Template
          </Link>
        </div>
      </div> */}
        <div className="templateHeader">
          <div class="search">
            <span class="fa fa-search"></span>
            <input
              // placeholder="Search Curriculum"
              style={{ borderRadius: "30px" }}
              type="text"
              value={search}
              onChange={(text) => searchTemplate(text)}
              // class="form-control"
              placeholder="Search"
            />
          </div>
          <div>
            <Link
              to={`/admin/add-template`}
              className="btn btn-sm add-btn"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Add Template
            </Link>
          </div>
        </div>
        <div className="col-md-12 table_container">
          {/* <div className="card"> */}
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table">
                <thead>
                  <tr>
                    <th scope="col" style={{ fontFamily: "roboto" }}>
                      Template ID
                    </th>
                    <th scope="col" style={{ fontFamily: "roboto" }}>
                      Template Name
                    </th>
                    <th scope="col" style={{ fontFamily: "roboto" }}>
                      Curriculum
                    </th>
                    <th scope="col" style={{ fontFamily: "roboto" }}>
                      Subject
                    </th>
                    <th scope="col" style={{ fontFamily: "roboto" }}>
                      Template Type
                    </th>
                    <th
                      scope="col"
                      style={{ fontFamily: "roboto", minWidth: "260px" }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {templatesList &&
                    templatesList.length > 0 &&
                    templatesList.reverse().map((q) => {
                      return (
                        <tr key={q._id}>
                          <td style={{ fontFamily: "roboto" }}>
                            {q.templateID}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>
                            {q.templateName}{" "}
                          </td>

                          <td style={{ fontFamily: "roboto" }}>
                            {q.curriculum}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>{q.subject}</td>
                          <td style={{ fontFamily: "roboto" }}>
                            {q.templateType}
                          </td>
                          <td
                          // style={{
                          //   display: "flex",
                          //   width: "100%",
                          // }}
                          >
                            <Link to={`/admin/edit-template/${q.templateID}`}>
                              <button
                                className="btn btn-sm mr-2"
                                style={{ backgroundColor: " #FFE7A8" }}
                              >
                                <span style={{ color: "#EAB731" }}>
                                  <BorderColorIcon
                                    style={{
                                      color: "#EAB731",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {" "}
                                    Edit
                                  </b>
                                </span>
                              </button>
                            </Link>
                            <Link to={`/admin/view-template/${q.templateID}`}>
                              <button
                                className="btn btn-sm ml-2"
                                style={{
                                  backgroundColor: "#DEF2E9",
                                  marginLeft: "10px",
                                }}
                              >
                                <span style={{ color: "#49AF48" }}>
                                  <RemoveRedEyeIcon
                                    style={{
                                      color: "#49AF48",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    View
                                  </b>
                                </span>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template;
