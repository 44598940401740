import React from 'react'
import TeacherReport from '../../components/Teachers/TeacherReport/TeacherReport'

export default function TeacherRepotContainer() {
  return (
    <div>
      <TeacherReport/>
    </div>
  )
}
