import React, { useEffect, useState } from "react";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./AddAddress.css";
import DeleteAddress from "./DeleteAddress";
import { getAddressList } from "../../../services/Admin/AddressService";
// import LoadingModal from '../../components/Share/LoadingModal';
import LoadingModal from "../../Share/LoadingModal";

export default function Address(props) {
  const { studentList, search, searchAddress, changeStatus, open } = props;

  let [allData, setAllData] = useState([]);
  let [filterData, setfilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true)


  async function addressList() {
    const add = await getAddressList();
    setIsLoading(false)
    console.log(add.data.data);
    setAllData(add.data.data);
    setfilterData(add.data.data);
  }

  console.log(allData);

  const searchHandle = (e) => {
    if (e !== "") {
      const searchValue = e.toLowerCase().trim(); // Convert search value to lowercase for case-insensitive search

      const filteredData = allData.filter((item) => {
        const curriculuma = item?.curriculum
          .toLowerCase()
          .includes(searchValue);
        const subject = item?.subject.toLowerCase().includes(searchValue);
        const country = item?.country.toLowerCase().includes(searchValue);
        const state = item?.state.toLowerCase().includes(searchValue);
        const city = item?.city.toLowerCase().includes(searchValue);
        const pincode = item?.pincode.toLowerCase().includes(searchValue);
        const houseNo = item?.houseNo.toLowerCase().includes(searchValue);
        const area = item?.area.toLowerCase().includes(searchValue);
        const nearBy = item?.nearBy.toLowerCase().includes(searchValue);
        return (
          curriculuma ||
          subject ||
          country ||
          state ||
          city ||
          pincode ||
          houseNo ||
          area ||
          nearBy
        );
      });
      setfilterData(filteredData);
    }
  };

  useEffect(() => {
    addressList();
  }, []);

  return (
    
    <>
        <LoadingModal visible={isLoading} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          width: "99%",
          alignItems: "center",
        }}
      >
        <h1
          className="form-heading"
          style={{ color: "#4A1D75", fontFamily: "roboto" }}
        >
          Address List
        </h1>

        <div>
          <Link
            to={`/admin/add-address`}
            className="btn btn-sm "
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Add Address
          </Link>
        </div>
      </div>
      <div className="input-group">
        <div className="search-con ">
          <input
            id="search-input"
            type="search"
            value={search}
            onChange={(text) => searchHandle(text.target.value)}
            className="form-control ps-4 "
            placeholder="Search"
          />
          <i className="fas fa-search icon123  text-secondary" />
        </div>
      </div>
      <div className=" mt-4 mx-2">
        <table class="table mt-3">
          <thead>
            <tr className="table-head">
              <th
                scope="col"
                style={{
                  backgroundColor: "#f1e3fe",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Curriculum
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#f1e3fe",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Subject
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#f1e3fe",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Title
              </th>

              <th
                scope="col"
                style={{
                  backgroundColor: "#f1e3fe",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Address
              </th>
              <th
                scope="col"
                style={{
                  backgroundColor: "#f1e3fe",
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {/* {filterData.map((res) => { */}
            {filterData.slice().reverse().map((res) => {
              return (
                <tr>
                  <td>{res.curriculum}</td>
                  <td>{res.subject}</td>
                  <td>{res.title}</td>

                  <td>
                    {" "}
                    {res.houseNo +
                      ", " +
                      res.area +
                      " near by " +
                      res.nearBy +
                      ", " +
                      res.city +
                      "-" +
                      res.pincode +
                      ", " +
                      res.state +
                      ", " +
                      res.country}
                  </td>
                  <td className="text-nowrap">
                    <div>
                      <Link
                        to={"/admin/edit-address/" + res._id}
                        className="crud-btn1 btn-sm mr-2"
                        role="button"
                        aria-pressed="true"
                      >
                        {" "}
                        <i class="bi bi-pencil-square"></i>Edit
                      </Link>
                      <Link
                        to={"/admin/view-address/" + res._id}
                        className="crud-btn2 mx-2 btn-sm mr-2"
                        role="button"
                        aria-pressed="true"
                      >
                        <i class="bi bi-eye-fill"></i>View
                      </Link>

                      <DeleteAddress id={res._id} addressList={addressList} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
