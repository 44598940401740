import React, { useState, useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import Header from "../../components/admin/header/header";
import Sidebar from "../../components/admin/sidebar/sidebar";
import routerService from "./adminRoutes";
import "../../styles/admin.css";
import { DateUpdate, addNotify, deleteNotify, getInactiveUser } from '../../services/Admin/commonService';
import { useSelector, useDispatch } from 'react-redux';
import * as Inactive from '../../store/actions/inactiveUser';

import {
  userID, userRole,
} from "../../services/Authentication/AuthService";
import { getAllSessions } from "../../services/Student/SessionService";
import { deleteSession } from "../../services/Admin/staffService";
import moment from "moment";
const AdminLayout = (props) => {
  // state={
  //   apiRes:{},
  // }
  //console.log('id---------------'+userID())
  const dispatch = useDispatch()
  const [apiRes, setApiRes] = useState()
  const [apiSession, setSession] = useState()
  const [count, setCount] = useState(0)


  // componentDidMount(){
  //   this.getInactive()
  // }
  let lis = useSelector(state => {
    return state.headUser.userList
  })
  let session = useSelector(state => {
    return state.headUser.sessionList
  })
  useEffect(() => {
    if (!lis || !session) {
      AllData();
    }
  }, [lis, session]);
  useEffect(() => {
    setApiRes(lis)
  }, [lis])
  const [hasFetchedData, setHasFetchedData] = useState(false);

  useEffect(() => {
    if (!hasFetchedData) {
      AllData();
      setHasFetchedData(true);
    }

    // ... other useEffect dependencies ...
  }, [hasFetchedData]);

  // ... rest of the component ...


  const getInactive = () => {
    getInactiveUser().then(result => {
      if (result.status === 200) {
        // this.setState({ apiRes: result.data.userDetail})
        setApiRes(result.data.userDetail)
        // dispatch(Inactive.inActiveList(result.data.userDetail))
        dispatch(Inactive.getList())

      }
    }).catch(err => console.log('err header-' + err))
  }

  const { match } = props;
  console.log("admin routes")

  async function AllData() {
    if (count < 1) {
      setCount(count + 1)
      const data = await getAllSessions();
      const adminUserId = userID();
      const adminUserRole = userRole();

      let result = data.data.data.filter(e => e.role === "student");
      console.log(result);

      result.forEach(async (res) => {
        // Check if notification has already been sent for this session
        if (!res.notificationSent) {
          let oneWeekLater = new Date(res.start);
          oneWeekLater.setDate(oneWeekLater.getDate() + 7);
          console.log(res)
          if (res.min_student === undefined || res?.min_student > res?.students?.length) {

            let currentDate = new Date();

            if (oneWeekLater.getTime() < currentDate.getTime()) {
              // console.log(`Session ${res.subject} has already started or is currently ongoing.`); 
              await deleteSession(res._id)
              await addNotify({
                curriculum: res.curriculum,
                subject: res.subject,
                studentEmails: res.studentEmails,
                status: "PENDING",
                sender_id: adminUserId,
                message: `We regret to inform you that ${res.title} session  must be canceled due to unforeseen circumstances.`,
                for_msg: "student",
                path: "/student/help-from-tutor/open",
                sendBy: adminUserRole,
                time: currentDate
              });
              return;
            }
            else {

              let daysRemaining = Math.floor((oneWeekLater.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));

              if (daysRemaining > 0 && daysRemaining <= 7) {
                console.log(`Notification: ${daysRemaining} day(s) left for session ${res.subject}`);


                if (res.reminder && moment(res.reminder).format('L') !== moment(currentDate).format('L')) {
                  await addNotify({
                    curriculum: res.curriculum,
                    subject: res.subject,
                    studentEmails: res.studentEmails,
                    status: "PENDING",
                    sender_id: adminUserId,
                    message: `You have ${daysRemaining} day(s) left to create the requested session for ${res.subject} in curriculum - ${res.curriculum}`,
                    for_msg: "admin",
                    path: "/admin/sessions",
                    sendBy: adminUserRole,
                    time: currentDate
                  });

                  await DateUpdate(

                    {
                      "reminder": new Date(),
                      "weekDate": oneWeekLater,
                      // "role":"student"
                    }, res._id
                  )
                }
                res.notificationSent = true;
              }
            }
          }

        }
      });
    }

  }
  return (
    <React.Fragment>
      <div
        className="main-wrapper"
        style={{ minHeight: window.innerHeight - 60 }}
      >
        <Header userList={apiRes} apiSession={apiSession} />
        <Sidebar />
        <div
          className="content-wrapper"
          /*   style={{ minHeight: window.innerHeight - 60 }} */
          style={{ minHeight: "100vh" }}
        >
          {routerService &&
            routerService.map((route, key) => {
              return (
                <Route
                  key={key}
                  path={`${match.url}/${route.path}`}
                  component={route.component}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );

}
export default withRouter(AdminLayout);
