import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { staffValidation } from "../../../validations/AdminValidations";
import FormikControl from "../../../common/Formik/FormikControl";
import { curriculumListFun, subjectListFun } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import { subjectValues } from "../../../constants/AdminInitialValues";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
} from "@material-ui/core";
import { X } from "react-feather";
import { error } from "jquery";

const AddStaff = (props) => {
  const {
    curriculumList,
    subjectsList,
    staffValues,
    mode,
    handleSubmitStaff,
    rolesList,
    handleInput,
  } = props;

  let data = staffValues;


  // const inputList = [{ firstName: "", lastName: "" }];
  const [inputList, setInputList] = useState([

  ]);
  const [index, setIndex] = useState(0);
  const [aa, setAa] = useState(data.role);
  const [curricullam, setCurriculam] = useState("");
  const [listSubject, setListSubject] = useState([]);
  const [subject, setSubject] = useState([]);
  const handleSelectDropdownChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
  };
  const history = useHistory();
  let calll = "Doing The Work";
  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);

  useEffect(() => {

    setAa(staffValues.role);
    setCurriculam(staffValues.curriculum);
    setSubject(staffValues.subject);
    if (staffValues.assignCurrculum) {
      staffValues.assignCurrculum.map((e, i) => {
        console.log(e.subject)
        staffValues.assignCurrculum[i].subList = e.subject
      })
      setInputList(staffValues.assignCurrculum);
    }
  }, [staffValues]);
  useEffect(() => {
   
    let sub = subjectsList.map((e) => e.subject);
    if (sub.length !== 0 && inputList.length !== 0) {
      inputList[index].subList = sub;
    }

    setListSubject(sub);
  }, [subjectsList]);
 
  const addMoreTearcher = () => {
    setInputList([...inputList, { curriculum: "", subject: [], subList: [] }]);
    // setListSubject([]);
    // inputList.push({ firstName: "", lastName: "" });
  };
  

  const roleOptions = rolesList && rolesList.length > 0 ? rolesList : [];
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="d-flex flex-row">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "99%",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div>
                <h1
                  className="form-heading"
                  style={{ color: "#4A1D75", fontFamily: "roboto" }}
                >
                  {mode} Staff
                </h1>
              </div>
              <button
                className="btn btn-sm mb-4"
                onClick={() => history.goBack()}
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                }}
              >
                Close
              </button>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={staffValues}
                validationSchema={staffValidation}
                validateOnMount
                enableReinitialize
              >
                {(formik) => {
                  const { handleChange } = formik;
                  const data = formik.values;
                  data.role = aa;
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            label="First Name"
                            id="firstName"
                            name="firstName"
                          />
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            label="Last Name"
                            name="lastName"
                          />
                        </div>

                        <div className="col-md-6">
                          <FormikControl
                            control="input"
                            type="text"
                            name="mobile"
                            label="Mobile Number"
                          />
                        </div>

                        <div className="col-md-6">
                          <div className="relative">
                            <FormikControl
                              control="select"
                              label="Select Role"
                              optname="roleName"
                              name="role"
                              placeholder="Select Role"
                              options={roleOptions}
                              value={aa}
                              onChange={(e) => {
                                if (mode === "Add" && inputList.length === 0) {
                                  setInputList([
                                    {
                                      curriculum: "",
                                      subject: [],
                                      subList: [],
                                    },
                                  ]);
                                }
                                setAa(e.target.value);
                              }}
                            />
                            <KeyboardArrowDownIcon className="drop_icon" />
                          </div>
                        </div>

                        {aa.toLowerCase() === "teacher" && (
                          <>
                            {inputList.map((x, i) => {
                              return (
                                <React.Fragment>
                                  <div className="col-md-6">
                                    {/* <FormikControl
                                control="select"
                                label="Curriculum Name"
                                optname="curriculum"
                                name="curriculum"
                                placeholder="Select Curriculum"
                                options={curriculumOptions}
                               
                                value={curricullam}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e, "curriculum", formik.values);
                                  setCurriculam(e.target.value);
                                }}
                               
                              /> */}
                                    <label
                                      className="label-heading"
                                      htmlFor="curriculum"
                                    >
                                      curriculums Name
                                    </label>
                                    {mode === "Add" && (
                                      <select
                                        className="w-100 form-select"
                                        name="curriculum"
                                        optname="curriculum"
                                        label="Curriulum Name"
                                        // value={data.curriculum ? data.curriculum : ""}
                                        value={x.curriculum}
                                        options={curriculumOptions}
                                        onChange={(e) => {
                                          handleChange(e);
                                          handleInput(
                                            e,
                                            "curriculum",
                                            formik.values
                                          );
                                          inputList[i].curriculum =
                                            e.target.value;
                                          setIndex(i);
                                          // inputList[i].subject = [];

                                          setCurriculam(e.target.value);
                                          // inputList[i]={firstName:""}
                                          handleSelectDropdownChange(e, i);
                                        }}
                                      >
                                        <option
                                          defaultValue="select"
                                          defaultChecked
                                          selected="selected"
                                          value={"select"}
                                        >
                                          select
                                        </option>
                                        {curriculumList?.map((data1, index) => (
                                          <option
                                            key={index}
                                            value={data1.curriculum}
                                          >
                                            {data1.curriculum}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                    {mode === "Edit" && (
                                      <select
                                        className="w-100 form-select"
                                        name="curriculum"
                                        optname="curriculum"
                                        label="Curriulum Name"
                                        // value={data.curriculum ? data.curriculum : ""}
                                        value={x.curriculum}
                                        options={curriculumOptions}
                                        onChange={(e) => {
                                          handleChange(e);
                                          handleInput(
                                            e,
                                            "curriculum",
                                            formik.values
                                          );
                                          inputList[i].curriculum =
                                            e.target.value;
                                          inputList[i].subject = [];
                                          setIndex(i);
                                          setCurriculam(e.target.value);

                                          // inputList[i] = { firstName: "" };
                                          handleSelectDropdownChange(e, i);
                                        }}
                                      >
                                        <option
                                          defaultValue="select"
                                          defaultChecked
                                          selected="selected"
                                          value={"select"}
                                        >
                                          select
                                        </option>
                                        {curriculumList?.map((data1, index) => (
                                          <option
                                            key={index}
                                            value={data1.curriculum}
                                          >
                                            {" "}
                                            {data1.curriculum}{" "}
                                          </option>
                                        ))}
                                      </select>
                                    )}
                                  </div>

                                  <div className="col-md-6">
                                    {/* <FormikControl
                                control="select"
                                label="Subject Name"
                                optname="subject"
                                name="subject"
                                placeholder="Select Subject"
                                options={subjectOptions}
                                // value={data.subject}
                                value={subject}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleInput(e, "subject", formik.values);
                                  setSubject(e.target.value);
                                }}
                              /> */}
                                    <label
                                      className="label-heading"
                                      // htmlFor="curriculum"
                                    >
                                      Subject Name
                                    </label>
                                    {mode === "Add" && (
                                      <>
                                        <Select
                                          multiple
                                          className="w-100 form"
                                          value={x.subject ? x.subject : []}
                                          name="subject"
                                          onChange={(e) => {
                                            // handleChange(e);
                                            // handleInput(e, "subject", formik.values);\

                                            setSubject(e.target.value);
                                            inputList[i].subject =
                                              e.target.value;
                                            handleSelectDropdownChange(e, i);
                                          }}
                                          input={<OutlinedInput label="Name" />}
                                          style={{
                                            height: "30px",
                                            marginTop: "2%",
                                          }}
                                        >
                                          {x.subList &&
                                            x.subList?.map((data2) => (
                                              <MenuItem value={data2}>
                                                {data2}{" "}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </>
                                      // <select
                                      //   multiple
                                      //   name="subject"
                                      //   className="w-100 form-select"
                                      //   optname="subject"
                                      //   label="Subject Name"
                                      //   // value={data.subject ? data.subject : ""}
                                      //   value={subject}
                                      //   // options={subjectOptions}
                                      //   onChange={(e) => {
                                      //     handleChange(e);
                                      //     handleInput(e, "subject", formik.values);
                                      //     setSubject(e.target.value);
                                      //   }}

                                      // >
                                      //   <option
                                      //     defaultValue="select"
                                      //     defaultChecked
                                      //     selected="selected"
                                      //     value={"select"}
                                      //   >
                                      //     select
                                      //   </option>
                                      //   {subjectsList?.map((data2, index) => (
                                      //     <option key={index} value={data2.subject}>
                                      //       {" "}
                                      //       {data2.subject}{" "}
                                      //     </option>
                                      //   ))}
                                      // </select>
                                      // <FormControl>

                                      // </FormControl>
                                    )}
                                    {mode === "Edit" && (
                                      // <select
                                      //   name="subject"
                                      //   className="w-100 form-select"
                                      //   optname="subject"
                                      //   label="Subject Name"
                                      //   // value={data.subject ? data.subject : ""}
                                      //   value={subject}
                                      //   options={subjectOptions}
                                      //   onChange={(e) => {
                                      //     setSubject(e.target.value);
                                      //     handleChange(e);
                                      //     handleInput(e, "subject", formik.values);
                                      //   }}
                                      // >
                                      //   <option
                                      //     defaultValue="select"
                                      //     defaultChecked
                                      //     selected="selected"
                                      //     value={"select"}
                                      //   >
                                      //     select
                                      //   </option>
                                      //   {subjectsList?.map((data2, index) => (
                                      //     <option key={index} value={data2.subject}>
                                      //       {" "}
                                      //       {data2.subject}{" "}
                                      //     </option>
                                      //   ))}
                                      // </select>
                                      <Select
                                        multiple
                                        className="w-100 form"
                                        value={x.subject ? x.subject : []}
                                        onChange={(e) => {
                                          // handleChange(e);
                                          // handleInput(e, "subject", formik.values);
                                          inputList[i].subject = e.target.value;
                                          // setSubject(e.target.value);
                                          setListSubject(e.target.value);
                                          // inputList[i] = { lastName: "" };
                                          handleSelectDropdownChange(e, i);
                                        }}
                                        input={<OutlinedInput label="Name" />}
                                        style={{
                                          height: "30px",
                                          marginTop: "2%",
                                        }}
                                      >
                                        {x.subList &&
                                          x.subList?.map((data2) => (
                                            <MenuItem value={data2}>
                                              {data2}{" "}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    )}
                                  </div>
                                  {/* <div className="col-md-1">
                                    <button>X</button>
                                  </div> */}
                                </React.Fragment>
                              );
                            })}
                            <div className="col-md-12 mt-4">
                              <button
                                className="btn btn-sm"
                                style={{
                                  borderRadius: "40px",
                                  backgroundColor: "#4A1D75",
                                  border: "2px solid #4A1D75",
                                  width: "140px",
                                  textAlign: "center",
                                  padding: "6px",
                                  color: "white",
                                  height: "auto",
                                }}
                                onClick={() => {
                                  addMoreTearcher();
                                  // setMoreDrop([...moreDrop, 1]);
                                }}
                              >
                                Add More
                              </button>
                            </div>
                          </>
                        )}

                        <div className="col-md-6" style={{ marginTop: "20px" }}>
                          <FormikControl
                            control="input"
                            type="text"
                            name="email"
                            label="Email ID"
                          />
                        </div>

                        <div className="col-md-6" style={{ marginTop: "20px" }}>
                          <FormikControl
                            control="textarea"
                            type="text"
                            name="address"
                            label="Address"
                          />
                        </div>

                        <div className="text-center col-md-12">
                          <button
                            type="submit"
                            className="btn btn-sm mt-4"
                            onClick={() => handleSubmitStaff(formik, inputList,)}
                            style={{
                              borderRadius: "40px",
                              backgroundColor: "#4A1D75",
                              border: "2px solid #4A1D75",
                              width: "140px",
                              textAlign: "center",
                              padding: "6px",
                              color: "white",
                              height: "auto",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
