import React from "react";
import Select from "../../../common/select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import styles from "./MyQueries.module.css";
import {
  curriculumListFun,
  subjectListFun,
  topicListFun,
  chapterListFun,
} from "../../../utils/utils";

const RaiseQuery = (props) => {
  const {
    curriculumList,
    subjectsList,
    topicsList,
    chaptersList,
    queryData,
    backQuery,
    handleSubmitQuery,
    handleInput,
    handleInputMessage,
  } = props;

  let curriculumOptions = curriculumListFun(curriculumList);
  let subjectOptions = subjectListFun(subjectsList);
  let topicOptions = topicListFun(topicsList);
  let chapterOptions = chapterListFun(chaptersList);
     console.log(chapterOptions)
  return (
    <div className={`row pt-3 ${styles.raisequerymain}`}>
      <h4
        className="text-heading text-center mb-2"
        style={{ fontFamily: "roboto" }}
      >
        Raise A Query
      </h4>
      <div className="">
      <button
        type="button"
        className="btn  btn-right mb-2  "
        onClick={backQuery}
        style={{
          // borderRadius: "50px",
          // width: "12%",
          color: "white",
          backgroundColor: "#4A1E75",
          fontFamily:"roboto",
          textTransform:'capitalize'
        }}
      >
        Back
      </button>
      </div>
      <div className="col-md-12">
        <div className="cardDiv mb-0">
          <div className={styles.raiseQueryDiv}>
            <form>
              <div className="row mt-3 mb-3">
                <div className="col-md-6">
                  <Select
                    name="curriculum"
                    optname="curriculum"
                    label="Curriulum Name"
                    placeholder="Select Curriculum"
                    value={queryData.curriculum ? queryData.curriculum : ""}
                    options={curriculumOptions}
                    onChange={(e) => handleInput(e, "curriculum")}
                    style={{ borderRadius: "50px" }}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="subject"
                    optname="subject"
                    placeholder="Select Subject Name"
                    value={queryData.subject ? queryData.subject : ""}
                    options={subjectOptions}
                    onChange={(e) => handleInput(e, "subject")}
                    style={{ borderRadius: "50px" }}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="topic"
                    optname="topic"
                    placeholder="Select Topic Name"
                    value={queryData.topic ? queryData.topic : ""}
                    options={topicOptions}
                    onChange={(e) => handleInput(e, "topic")}
                    style={{ borderRadius: "50px" }}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    name="chapter"
                    optname="chapter"
                    placeholder="Select Chapter Name"
                    value={queryData.chapter ? queryData.chapter : ""}
                    options={chapterOptions}
                    onChange={(e) => handleInput(e, "chapter")}
                    style={{ borderRadius: "50px" }}
                  />
                </div>

                <div className="col-md-12">
                  <CKEditor
                    editor={ClassicEditor}
                    data={queryData.message ? queryData.message : ""}
                    onChange={(event, editor) => {
                      handleInputMessage(editor.getData());
                    }}
                  />
                </div>
              </div>
            </form>

            <div className="text-center">
            <button
              type="button"
              className="btn mb-2  "
              onClick={handleSubmitQuery}
              style={{
                // borderRadius: "50px",
                fontSize:'16px',
                padding:'10px 15px',
                color: "white",
                backgroundColor: "#4A1E75",
              }}
            >
              Submit
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaiseQuery;
