import http from "../httpService";
import { apiUrl } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
  "x-access-token": getAuthToken(),
};

export function saveQuestion(payload, questionId) {
  if (!questionId) {
    //Add Question
    return http.post(apiUrl + "/question/addQuestion", payload, {
      headers: headers,
    });
  } else {
    //Edit Question
    return http.post(apiUrl + "/question/updateQuestion/"+questionId, payload, {
      headers: headers,
    });
  }
}

export const getQuestion = (payload) => {
  return http.post(apiUrl + "/question/getQuestion", payload, {
    headers: headers,
  });
};
//delete question
export const deleteQuestion = (id) => {
  return http.post(apiUrl + "/question/deleteQuestion/"+id, {
    headers: headers,
  });
};

export const copyQuestion = (id) => {
  return http.post(apiUrl + "/question/copyQuestions/"+id, {
    headers: headers,
  });
};
export const imageUpload = (payload, fileUpload) => {
  const imageHeaders = { headers, "x-access-token": getAuthToken() };
  return http.post(apiUrl + "/video/uploadMultipleImage/data", payload, {
    headers: imageHeaders,
  });
};
