import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const Assignment = (props) => {
  const { assignmentList } = props;
  return (
    <div className="row">
      <div className="assessment_heading w-100 mt-2 px-3">
        <h1
          className="form-heading mb-0"
          style={{ fontSize: "23px", fontFamily: "roboto" }}
        >
          Assessment Correction List
        </h1>
      </div>
      <div className="col-md-12 p-3 table_container">
        {/* <div className="card"> */}
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table" id="table">
              <thead>
                <tr>
                  <th style={{ fontFamily: "roboto" }}>ID</th>
                  <th style={{ fontFamily: "roboto" }}>Assessment Name</th>
                  <th style={{ fontFamily: "roboto" }}>Type</th>
                  <th style={{ fontFamily: "roboto" }}>Curriculum</th>
                  <th style={{ fontFamily: "roboto" }}>Start Date</th>
                  <th style={{ fontFamily: "roboto" }}>End Date</th>
                  <th style={{ fontFamily: "roboto" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {assignmentList &&
                  assignmentList.length > 0 &&
                  assignmentList.map((a) => {
                    return (
                      <tr key={a._id}>
                        <td style={{ fontFamily: "roboto" }}>{a.testID}</td>
                        <td style={{ fontFamily: "roboto" }}>
                          {a.assessmentName}
                        </td>
                        <td style={{ fontFamily: "roboto" }}>{a.type}</td>
                        <td style={{ fontFamily: "roboto" }}>{a.curriculum}</td>
                        <td style={{ fontFamily: "roboto" }}>
                          {moment(a.startdate).format("MM/DD/YYYY")}
                        </td>
                        <td style={{ fontFamily: "roboto" }}>
                          {moment(a.enddate).format("MM/DD/YYYY")}{" "}
                        </td>
                        <td>
                          <Link
                            to={`/teacher/assignment-correction/${a.testID}/${a.curriculumCode}`}
                          >
                            <button className="btn btn-sm btn-primary view_icon">
                              <i class="fa-regular fa-eye"></i>
                              View
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Assignment;
