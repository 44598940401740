import React, { useCallback, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NavLink } from "react-router-dom";
import styles from "./Assignments.module.css";
import parse from "html-react-parser";
import Logo from "../../../assets/Images/logo.png";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import fscreen from "fscreen";
import "./Assignments.module.css";
import Countdown from "react-countdown";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { getStream, download, exitVideo } from "./videoFunction";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import SystemCheckModal from "../../Share/SystemCheckModal";
const TestView = ({
  questions,
  handleOption,
  handleOptionText,
  submitTest,
  assignmentIndividual,
  assignmentPreview,
  isLoading
}) => {
  if (assignmentIndividual.assessmentName === undefined) {
    assignmentPreview();
  }
  const { id } = useParams()
  const history = useHistory()
  const handle = useFullScreenHandle();
  const [showTest, setShowTest] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [system, setSystem] = useState(false);
  const [dura, setDura] = useState();
  const [loading, setloading] = useState(false);
  const [change, setChange] = useState(false);
  const [permission, setPermistion] = useState([])
  const [count,setCount] = useState(0)
  document.onkeydown = function (evt) {
    evt = evt || window.event;
    if (evt.keyCode == 27) {
      alert('Esc key pressed.');
    }
  };
  const renderer = ({ hours, minutes, seconds, completed }) => {

    if (completed) {
      let file = download();
      setChange(true)
      submitTest(questions, file, 1)

      return;
    } else {
      let time = `${hours}:${minutes}:${seconds}`;
      setDura(milli(time));
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  // const [minutes, setMinutes] = useState(60);
  // const [seconds, setSeconds] = useState(0);

  // useEffect(() => {
  //   let myInterval = setInterval(() => {
  //     if (seconds > 0) {
  //       setSeconds(seconds - 1);
  //     }
  //     if (seconds === 0) {
  //       if (minutes === 0) {
  //         clearInterval(myInterval);
  //       } else {
  //         setMinutes(minutes - 1);
  //         setSeconds(59);
  //       }
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(myInterval);
  //   };
  // });

  document.onkeydown = function (evt) {
    console.log(evt.key)
    evt = evt || window.event;
    var isEscape = false;
    if ("key" in evt) {
      isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
      isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
      alert("Escape");
    }
    console.log(isEscape)
  };
  const reportChange = (state, handle1) => {
    if (!change) {

      if (!state && showTest && !change) {

        setShowTest(false)
        console.log("qqqqqqqqqqqqqqqq")
        let file = download();
        submitTest(questions, file, 0)

        //   exitVideo()
        //   if (window.confirm("Do you want to exit test?")) {
        //     window.close();
        //   } else {

        //   }
      }
    }
  };
  function milli(time) {
    let timeArr = time.split(":");
    let timeInMilliseconds =
      Number(timeArr[0]) * 3600000 +
      Number(timeArr[1]) * 60000 +
      Number(timeArr[2]) * 1000;
    return timeInMilliseconds;
  }

  useEffect(() => {
    // handle.enter()
    // console.log('fullscreen' + fscreen.fullscreenEnabled)
    if (fscreen.fullscreenElement === null) {
      // history.push(`/student/assignment-preview/${id}`)

    } else {
      setShowTest(true);
    }

    if (assignmentIndividual.duration !== undefined) {
      setDura(milli(assignmentIndividual.duration));
    }
  }, [fscreen.fullscreenElement]);
  const systemCheck = () => {
    setShowLoader(true)
    navigator.permissions.query({ name: "camera" }).then(res => {
      if (res.state == "granted") {
        permission.push("camera")
      } else {
        alert("give permission to camera and microphone")
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        }).catch(res => {
          alert("Unblock camera and microphone permission from browser settings")
        })
      }
    });
    navigator.permissions.query({ name: "microphone" }).then(res => {
      if (res.state == "granted") {
        permission.push("microphone")
      } else {
        if (permission.length === 1) {

          alert("give permission to camera and microphone")
          navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          })
        }
      }
    });

    setTimeout(() => {
      setSystem(true)
      setShowLoader(false)
    }, 15000)
  }

  const enterFullscreen = () => {

    navigator.permissions.query({ name: "camera" }).then(res => {
      if (res.state == "granted") {
        permission.push("camera")
      } else {
        alert("give permission to camera and microphone")
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        }).catch(res => {
          alert("Unblock camera and microphone permission from browser settings")
        })
      }
    });
    navigator.permissions.query({ name: "microphone" }).then(res => {
      if (res.state == "granted") {
        permission.push("microphone")
      } else {
        if (permission.length === 1) {

          alert("give permission to camera and microphone")
          navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          })
        }
      }
    });

    if (permission.length >= 2) {



      getStream()
      handle.enter();

    }
    //console.log(fscreen.fullscreenElement)
  };

  return (
    <React.Fragment>
      <div>
      <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
        <div className={`  ${styles.testInfo}`}>
          <SystemCheckModal visible={showLoader} />
          <div className=" " style={{ width: "95%" }}>
            <div className="row ">
              <div className="col-md-4 ">
                {/* <NavLink to="/student/dashboard"> */}
                <img
                  src={Logo}
                  alt="logo"
                  style={{ height: "60px", marginLeft: "12px" }}
                />
                {/* </NavLink> */}
              </div>
              <div className={`col-md-8 ${styles.testInfo1}`}>
                <h3>
                  <b>{assignmentIndividual.assessmentName}</b>
                </h3>
                <h3>
                  <b>
                    Subject: <span>{assignmentIndividual.subject}</span>
                  </b>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ruleAssessment}>
          <h3>RULE OF ONLINE ASSESSMENT</h3>
          <ul>
            <li>
              Complete the test individually and without any assistance from
              others.
            </li>
            <li>
              Do not refer to any unauthorized materials or resources during the
              test.
            </li>
            <li>
              Follow the specified time limit and submit your answers within the
              allocated timeframe
            </li>
            <li>
              Avoid any disruptive behavior or activities during the test.
            </li>
            <li>You must use a functioning webcam and microphone</li>
            <li>
              No cell phones or other secondary devices in the room or
              Assessment area
            </li>
            <li>
              Your desk/table must be clear or any materials except your
              Assessment-taking device
            </li>
            <li>
              The testing room must be well-lit and you must be clearly visible
            </li>
            <li>No dual screens/monitors </li>
            <li>Do not leave the camera </li>
            <li>No use of additional applications or internet</li>
          </ul>
        </div>
        {!system ? (<>
          <div className={styles.startAssessment}>
            <button className="btn btn-sm btn-success" onClick={() => systemCheck()}>
              System Check
            </button>
          </div>
        </>) : (<>
          <div className={styles.startAssessment}>
            <button className="btn btn-sm btn-success" onClick={enterFullscreen}>
              Start Assessment
            </button>
          </div>
        </>)}


        <FullScreen
          handle={handle}
          style={{ backgroundColor: "white" }}
          onChange={reportChange}
        >
          {showTest && (
            <div
              style={{
                height: "100%",
                backgroundColor: "white",
                overflow: "auto",
              }}
            >
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className={`${styles.testInfo}`}>
                <div className="" style={{ width: "100%" }}>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "55%",
                    }}
                  >
                    <div
                      className="col-md-4"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "70%",
                      }}
                    >
                      {/* <NavLink to="/student/dashboard"> */}
                      <div>
                        <img
                          src={Logo}
                          alt="logo"
                          style={{ height: "60px", marginLeft: "10px" }}
                        />
                      </div>
                      <div style={{ paddingTop: "%" }}>
                        <h3>
                          Assessment Name:{" "}
                          <span>{assignmentIndividual.assessmentName}</span>
                        </h3>
                        <h3>
                          Subject: <span>{assignmentIndividual.subject}</span>
                        </h3>
                      </div>
                      {/* <div style={{ paddingTop: "3%" }}>
                      
                      </div> */}
                      {/* </NavLink> */}
                    </div>
                    <div className={`col-md-8 ${styles.testInfo12}`}>
                      <p>
                        Timer:
                        <Countdown
                          date={Date.now() + dura}
                          renderer={renderer}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  minHeight: window.innerHeight - 119,
                  background: "#f7f7f7",
                  padding: "30px 0px",
                }}
              >

                <div className="container">
                  <div className="row justify-content-center" >
                    <div className={`${styles.note}`} style={{ width: "64%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",

                        }}
                      >
                        <div
                          className={`${styles.quesTitle}`}
                          style={{ width: "90%" }}
                        >
                          <span
                            style={{ paddingRight: "10px" }}
                          >{"Warning :  Please avoid clicking the escape button, as it will result in the test ending."}</span>

                        </div>
                      </div>
                    </div>


                    {/* <div className="col-md-4">
              <div className={`${styles.testTimerBg}`}>
                <ul className={`${styles.testTimerLeft}`}>
                  <li>
                    <h4>20</h4>
                    <p>Questions</p>
                  </li>
                  <li>
                    {minutes === 0 && seconds === 0 ? null : (
                      <h4>
                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                      </h4>
                    )}
                    <p>Minutes</p>
                  </li>
                </ul>
              </div>
            </div> */}
                    <div className="col-md-8">
                      {questions &&
                        questions.length > 0 &&
                        questions.map((q, i) => {

                          if (q.questionType === "Multi") {
                            return (
                              <div className={`${styles.quesItem}`} key={q._id}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >

                                  <div
                                    className={`${styles.quesTitle}`}
                                    style={{ width: "70%" }}
                                  >
                                    {q.question ? (
                                      <>
                                        <React.Fragment>
                                          <span>Q{i + 1} :</span>{" "}
                                          {parse(q.question)}
                                        </React.Fragment>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <label> Marks : {q.questionmarks}</label>
                                </div>
                                <div className="ui form">
                                  <div className={`${styles.radioCheckbox}`}>
                                    <input
                                      type="radio"
                                      name={`question${i + 1}`}
                                      value={q.answer1}
                                      onChange={(e) => handleOption(e, q)}
                                    />
                                    <label>{q.answer1}</label>
                                  </div>
                                  {q.image_answer1.location ? <img
                                    src={q.image_answer1.location}
                                    className={`${styles.imageAnswer}`}
                                  /> : ""}

                                  <div className={`${styles.radioCheckbox}`}>
                                    <input
                                      type="radio"
                                      name={`question${i + 1}`}
                                      value={q.answer2}
                                      onChange={(e) => handleOption(e, q)}
                                    />
                                    <label>{q.answer2}</label>
                                  </div>
                                  {q.image_answer2.location ? (
                                    <img
                                      src={q.image_answer2.location}
                                      className={`${styles.imageAnswer}`}
                                    />
                                  ) : (
                                    ""
                                  )}

                                  <div className={`${styles.radioCheckbox}`}>
                                    <input
                                      type="radio"
                                      name={`question${i + 1}`}
                                      value={q.answer3}
                                      onChange={(e) => handleOption(e, q)}
                                    />
                                    <label>{q.answer3}</label>
                                  </div>
                                  {q.image_answer3.location ? (
                                    <img
                                      src={q.image_answer3.location}
                                      className={`${styles.imageAnswer}`}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <div className={`${styles.radioCheckbox}`}>
                                    <input
                                      type="radio"
                                      name={`question${i + 1}`}
                                      value={q.correctanswer}
                                      onChange={(e) => handleOption(e, q)}
                                    />
                                    <label>{q.correctanswer}</label>
                                  </div>
                                  {q.image_correctanswer.location ? (
                                    <img
                                      src={q.image_correctanswer.location}
                                      className={`${styles.imageAnswer}`}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className={`${styles.quesItem}`} key={q._id}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    className={`${styles.quesTitle}`}
                                    style={{ width: "90%" }}
                                  >
                                    {q.question ? (
                                      <React.Fragment>
                                        <span
                                          style={{ paddingRight: "10px" }}
                                        >{`Q${i + 1} : `}</span>{" "}
                                        {parse(q.question)}
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <label style={{}}>
                                    {" "}
                                    Marks : {q.questionmarks}
                                  </label>
                                </div>
                                <div className="ui form">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    onChange={(event, editor) => {
                                      handleOptionText(editor.getData(), q);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}

                      <div className="col-md-12 text-center ">
                        {isLoading && (<>
                          <p style={{ fontWeight: "bold" }}> Please Wait...</p>
                        </>)}
                        <button
                          disabled={isLoading}
                          onClick={() => {
                          if(count == 0){
                            let file = download();
                            setChange(true)
                            submitTest(questions, file, 1)
                            setloading(true)
                            setCount(1)
                          }
                          }}
                          className={`btn btn-sm btn-success `}
                          style={{ fontSize: "15px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </FullScreen>
      </div>
    </React.Fragment>
  );
};

export default TestView;
