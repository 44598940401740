import React from "react";
import { Link } from "react-router-dom";
import { copyCourse } from "../../../services/Admin/courseService";
import style from "./course.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import "../course/course.css";
import { Backdrop, CircularProgress } from "@mui/material";

const CourseContent = (props) => {
  const { courseList, search, searchCourse, open } = props;
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row">
        <h1
          className="form-heading"
          style={{ color: "#4A1D75", fontFamily: "roboto" }}
        >
          Chapter List
        </h1>
        {/* <div className="courseHeader"> */}
        {/* <div class="input-group row col-12   mb-2 "> */}
        {/* <div class="input-group-prepend"> */}
        {/* <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "30px" }}
            />
            <input
              type="text"
              value={search}
              onChange={(text) => searchCourse(text)}
              class="form-control"
              placeholder="Search Curriculum/Subject/topic"
              style={{ borderRadius: "40px" }}
            />
          </div> */}
        {/* </div> */}
        {/* </div>
        <div className="add-btn-div">
          <Link
            to={`/admin/add-course-content`}
            className="btn btn-sm add-btn"
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "150px",
              textAlign: "center",
              padding: "15px",
            }}
          >
            Add Chapter
          </Link>
        </div>
      </div> */}
        <div className="courseHeader">
          <div class="search">
            <span class="fa fa-search"></span>
            <input
              style={{ borderRadius: "30px" }}
              type="text"
              value={search}
              onChange={(text) => searchCourse(text)}
              // class="form-control"
              placeholder="Search"
            />
          </div>
          <div>
            <Link
              to={`/admin/add-course-content`}
              className="btn btn-sm add-btn"
              style={{
                borderRadius: "40px",
                backgroundColor: "#4A1D75",
                border: "2px solid #4A1D75",
                width: "140px",
                textAlign: "center",
                padding: "6px",
                color: "white",
                height: "auto",
              }}
            >
              Add Chapter
            </Link>
          </div>
        </div>
        <div className="col-md-12 table_container1">
          {/* <div className="card"> */}
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead style={{ marginBottom: "40px" }}>
                  <tr style={{ backgroundColor: "red" }}>
                    <th style={{ color: "#20639B", fontFamily: "roboto" }}>
                      Curriculum Name
                    </th>
                    <th style={{ color: "#20639B", fontFamily: "roboto" }}>
                      Subject Name
                    </th>
                    <th style={{ color: "#20639B", fontFamily: "roboto" }}>
                      Topic Name
                    </th>
                    {/* <th style={{ color: "#20639B" }}>Practical Session</th> */}
                    <th
                      style={{
                        color: "#20639B",
                        fontFamily: "roboto",
                        minWidth: "260px",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody style={{ marginTop: "80px" }} className="w-100">
                  {courseList.length > 0 &&
                    [...courseList].reverse().map((c) => {
                      return (
                        <tr key={c._id}>
                          <td style={{ fontFamily: "roboto" }}>
                            {c.curriculum}
                          </td>
                          <td style={{ fontFamily: "roboto" }}>{c.subject}</td>
                          <td style={{ fontFamily: "roboto" }}>{c.topic}</td>
                          {/* <td>{c.practicalSession}</td> */}
                          <td
                            style={
                              {
                                // display: "flex",
                                // width: "100%",
                                // justifyContent: "space-between",
                              }
                            }
                          >
                            <Link to={`/admin/edit-course-content/${c._id}`}>
                              <button
                                className="btn btn-sm mr-2"
                                style={{ backgroundColor: " #FFE7A8" }}
                              >
                                <span style={{ color: "#EAB731" }}>
                                  <BorderColorIcon
                                    style={{
                                      color: "#EAB731",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {" "}
                                    Edit
                                  </b>
                                </span>
                              </button>
                            </Link>
                            <Link to={`/admin/view-course-content/${c._id}`}>
                              <button
                                className="btn btn-sm ml-2"
                                style={{
                                  backgroundColor: "#DEF2E9",
                                  marginLeft: "10px",
                                }}
                              >
                                <span style={{ color: "#49AF48" }}>
                                  <RemoveRedEyeIcon
                                    style={{
                                      color: "#49AF48",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    View
                                  </b>
                                </span>
                              </button>
                            </Link>
                            {c.topic[c.topic.length - 1] === ")" ? null : (
                              <button
                                onClick={() => {
                                  async function test() {
                                    await copyCourse(c._id);
                                    window.location.reload();
                                  }
                                  test();
                                }}
                                className="btn btn-sm "
                                style={{
                                  backgroundColor: " #E0F6F6",
                                  // marginTop: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                <span
                                  style={{ color: "#1DB9AA", fontSize: "13px" }}
                                >
                                  <CopyAllIcon
                                    style={{
                                      color: "#1DB9AA",
                                      fontSize: "13px",
                                    }}
                                  />
                                  <b
                                    style={{
                                      fontSize: "11px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {" "}
                                    Copy
                                  </b>
                                </span>{" "}
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default CourseContent;
