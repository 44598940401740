import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FlatList from "flatlist-react";
import TextField from "@material-ui/core/TextField";
import { Button, Modal } from "react-bootstrap";
import Autocomplete1 from "@material-ui/lab/Autocomplete";
import styles from "./Topbar.module.css";
import { getCurriculumList } from "../../../services/Admin/commonService";
import moment from "moment";
import {
  teacherSubject,
  userCurriculum,
  userCurriculumCode,
  userID,
  assignCurrculum,
} from "../../../services/Authentication/AuthService";
import { getUpdateCurriculum } from "../../../services/Admin/curriculumService";
import { getAllSubjectList } from "../../Share/GetCurriculumDetails";
// import { Link } from "react-router-dom";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getNotify, deleteNotify } from "../../../services/Admin/commonService";
import { apiUrl } from "../../../config.json";
// import { io } from "socket.io-client";
// var socket = io(apiUrl);
const Topbar = () => {
  let history = useHistory();
  const [show, setShow] = useState(false);
  const [notify, setNotify] = useState([]);
  const [cur, setCurList] = useState({});
  const [subjectList, setSubjectList] = useState([]);
  const [subject, setSubject] = useState("");

  const [selected, setSelected] = useState({});
  const [error, setError] = useState();
  function Logout() {
    sessionStorage.clear();
    history.push("/login");
  }
  let notification = [];
  const listCurricluim = () => {
    getCurriculumList()
      .then((res) => {
        if (res.status === 200) {
          setCurList(res.data.data);
        }
      })
      .catch((err) => {
        setCurList([]);
      });
  };
  const getNotification = async () => {
    await getNotify({
      for_msg: "teacher",
    }).then((res) => {
      let noti = res.data.data;

      let aa = noti.filter(({ curriculum: id1, subject: code1 }) =>
      assignCurrculum().some(
        ({ curriculum: id2, subject: code2 }) => id2 === id1 && code2.includes(code1)
        )
        );
        console.log(aa)
      setNotify(aa.reverse());
    });
  };
  useEffect(async () => {
    setSelected({
      curriculum: userCurriculum(),
      curriculumCode: userCurriculumCode(),
    });
    setSubject(teacherSubject());
    listCurricluim();
    getAllSubject(userCurriculum(), userCurriculumCode());

    // socket.on("notification_to_teacher", async (data) => {
    //   console.log(data, "notification_to_teacher");
    // });
    getNotification();
  }, []);
  console.log("sd", userCurriculum());
  const getAllSubject = (curriculum, curriculumCode) => {
    let payload = {
      curriculum: curriculum,
      curriculumCode: curriculumCode,
    };
    console.log("sub000");
    getAllSubjectList(curriculumCode)
      .then((res) => {
        if (res.length > 0) {
          setSubjectList(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateCur = () => {
    let payload = {
      userID: userID(),
      curriculum: selected.curriculum,
      curriculumCode: selected.curriculumCode,
      role: "teacher",
      subject: subject,
    };
    console.log("dsds", payload);

    getUpdateCurriculum(payload)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.token = res.data.token;
          sessionStorage.loginType = "teacher";
          handleClose();
          window.location.reload();
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const data = [
    {
      icontext: <i class="las la-check-circle"></i>,
      textData: "Assigned Curriculum",
    },
    {
      icontext: <i class="las la-check-circle"></i>,
      textData: " You are assigned with math session for curriculum name",
    },
    {
      icontext: <i class="las la-check-circle"></i>,
      textData:
        "You are assigned with a new assessment of math for curriculum name",
    },
  ];
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
  return (
    <header>
      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Change Curriculum</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Autocomplete1
            id="combo-box-demo"
            // className="form-control"
            options={cur}
            getOptionLabel={(option) => option.curriculum}
            onChange={(event, value) => {
              if (value === null) {
                setSelected("");
                setSubject("");
                return;
              }
              setSubject("");
              getAllSubject(value.curriculum, value.curriculumCode);
              setSelected({
                curriculum: value.curriculum,
                curriculumCode: value.curriculumCode,
              });
            }}
            // disableClearable={true}
            defaultValue={{
              curriculum: selected.curriculum ? selected.curriculum : "",
            }}
            style={{ width: "100%", height: "38px" }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          <Autocomplete1
            id="combo-box-demo"
            // className="form-control"
            options={subjectList}
            className="mt-3"
            getOptionLabel={(option) => {
              return option.subject || "";
            }}
            key={(option) => option.subject}
            onChange={(event, value) => {
              if (value === null) {
                setSubject("");
                return;
              }
              console.log("vcalyyyy", value);
              setSubject(value.subject);
            }}
            disableClearable={true}
            defaultValue={{ subject: subject ? subject : "" }}
            style={{ width: "100%", height: "38px" }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
          {error && <p>{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateCur}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${styles.navbar}`}
      >
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className={`navbar-nav ms-auto ${styles.navbarNav}`}>
            <li className="nav-item" style={{ fontFamily: "roboto" }}>
              Teacher Dashboard
            </li>
          </div>
          <div className={`navbar-nav ml-auto ${styles.navbarNav}`}>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-bell"></i>
                <span
                  class="badge rounded-pill badge-notification bg-danger"
                  style={{
                    fontSize: "11px",
                    padding: "3px 5px",
                    margin: "-8px",
                    position: "relative",
                    top: "-19px",
                    left: "-5px",
                  }}
                >
                  {notify.length}
                </span>
              </Link>

              <div
                className={`dropdown-menu ${styles.dropdownMenu} p-0`}
                // aria-labelledby="navbarDropdown"
                style={{}}
              >
                {/* <div className="flat">
                  {notification.map(data => {
                    return (
                      <div className="dropdown-item">
                        <p>{data}</p>
                      </div>
                    )
                  })}
                  <FlatList
                    list={notification}
                    className="flat"
                    renderWhenEmpty={() => (
                      <div className="dropdown-item">
                        <p>No Notification</p>
                      </div>
                    )}
                    display={{
                      grid: false,
                    }}
                    renderItem={(data, idx) => {
                      return (
                        <Fragment>
                          <div key={idx} className="dropdown-item">
                            <p>
                              {data} {idx}
                            </p>
                          </div>
                          {idx < notification.length - 1 && (
                            <div className="dropdown-divider"></div>
                          )}
                        </Fragment>
                      );
                    }}
                  />
                </div> */}
                <div className={`${styles.teacheraccept} p-0`}>
                  {notify.map((res) => {
                    return (
                      <>
                        <div className={`${styles.iconText}`}>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "start",
                              cursor: "pointer",
                              padding: "15px 10px",
                              paddingBottom: "0px",
                            }}
                            onClick={() => {
                              deleteNotify(res._id).then((res) => {
                                getNotification();
                              });
                              history.push(res.path);
                            }}
                          >
                            <img src="/bell.png"/>
                            {/* <i class="las la-check-circle"></i> */}
                            <p
                              style={{
                                paddingLeft: "2%",
                                fontFamily: "roboto",
                              }}
                            >
                              {capitalizeFirstLetter(res.message)}
                            </p>
                          </span>
                          <p
                            style={{
                              display: "block",
                              paddingBottom: "10px",
                              paddingLeft: "45px",
                              color: "#ccc9c9",
                            }}
                          >
                            {moment
                              .utc(res.time)
                              .local()
                              .startOf("seconds")
                              .fromNow()}
                          </p>
                        </div>
                        <hr className="m-0"></hr>
                      </>
                    );
                  })}
                </div>
                <div
                  style={{
                    position: "sticky",
                    zIndex: "9999",
                    bottom: "0px",
                    padding: "15px 15px",
                    background: "white",
                    borderTop: "1px solid #ccc9c9",
                    cursor: "pointer",
                    fontFamily: "roboto",
                  }}
                >
                  Mark all as read
                </div>
              </div>
            </li>
            <li className={`nav-item dropdown`}>
              <span
                className="nav-link dropdown-toggle pointer"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="las la-user-circle"></i>
              </span>
              <div
                className={`dropdown-menu ${styles.dropdownMenu1}`}
                aria-labelledby="navbarDropdown"
              >
                {/* <span className="dropdown-item pointer" onClick={handleShow}>
                  Change Curriculum
                </span> */}
                {/* <div className="dropdown-divider"></div> */}

                <Link
                  className="dropdown-item"
                  to="/teacher/profile"
                  style={{ fontFamily: "roboto" }}
                >
                  Profile
                </Link>
                <div className="dropdown-divider"></div>
                <span
                  className="dropdown-item pointer"
                  onClick={Logout}
                  style={{ fontFamily: "roboto" }}
                >
                  Logout
                </span>
              </div>
            </li>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
