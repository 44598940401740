import React from "react";
import parse from "html-react-parser";
import { deleteQuestion } from "../../../services/Admin/questionService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Backdrop, CircularProgress } from "@mui/material";

const ViewQuestion = (props) => {
  const { questionsIndividual, closeViewQuestion,open } = props;
  const data = questionsIndividual;
const history= useHistory()
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            View Question
          </h3>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              closeViewQuestion(data._id);
            }}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-sm btn-secondary mr-2"
            onClick={() => history.push("/admin/question")}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>
        <div className="col-md-12">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Question ID</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.questionID ? data.questionID : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label>Curriculam Name</label>
                  <span>{data.curriculum ? data.curriculum : ""}</span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.subject ? data.subject : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Topic Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.topic ? data.topic : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Chapter Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.chapter ? data.chapter : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Question Type</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.questionType ? data.questionType : ""}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Marks for Questions
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {data.questionmarks ? data.questionmarks : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-3 ">
            <div className="question-list card mb-2">
              <div>
                <div>
                  <p
                    style={{
                      float: "left",
                      marginRight: "10px",
                      fontFamily: "roboto",
                    }}
                  >
                    Question:
                  </p>{" "}
                  <span style={{ fontFamily: "roboto" }}>
                    {data.question ? parse(data.question) : ""}
                  </span>
                </div>
                {data.questionType === "Multi" && (
                  <React.Fragment>
                    <p style={{ fontFamily: "roboto" }}>
                      <i className="las la-check-circle"></i>{" "}
                      {data.correctanswer ? data.correctanswer : ""}
                    </p>
                    {data.image_correctanswer.location !== "" ? (
                      <>
                        <img
                          src={
                            data.image_correctanswer
                              ? data.image_correctanswer.location
                              : ""
                          }
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "500px",
                            border: "1px solid #000",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    <p>
                      <i className="las la-times-circle"></i>{" "}
                      {data.answer1 ? data.answer1 : ""}
                    </p>
                    {data.image_answer1.location !== "" ? (
                      <>
                        <img
                          src={
                            data.image_answer1
                              ? data.image_answer1.location
                              : ""
                          }
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "500px",
                            border: "1px solid #000",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <p>
                      <i className="las la-times-circle"></i>{" "}
                      {data.answer2 ? data.answer2 : ""}
                    </p>
                    {data.image_answer2.location !== "" ? (
                      <>
                        <img
                          src={
                            data.image_answer2
                              ? data.image_answer2.location
                              : ""
                          }
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "500px",
                            border: "1px solid #000",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    <p>
                      <i className="las la-times-circle"></i>{" "}
                      {data.answer3 ? data.answer3 : ""}
                    </p>
                    {data.image_answer3.location !== "" ? (
                      <>
                        <img
                          src={
                            data.image_answer3
                              ? data.image_answer3.location
                              : ""
                          }
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: "500px",
                            border: "1px solid #000",
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewQuestion;
