import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import StudentLayout from "./containers/Students/StudentLayout";
import AdminLayout from "./containers/admin/adminLayout";
import TeacherLayout from "./containers/Teachers/TeachersLayout";
import OrgAdminLayout from "./containers/OrgAdmin/OrgAdminLayout";
import AuthLayout from "./containers/Authentication/AuthLayout";
import VerifyLayout from "./containers/verification/VerifyLayout";
import EmailVerify from "./components/verification/emailVerify";
import ChangePassword from "./components/verification/changePassword";
import Contact from "./components/ContactForm/Contact";
import Subscription from "./components/Subscription/Subscription_card";
import certificate from "./components/Students/Certificatee/certificatee";
import StudentData from "./components/Teachers/Assignment/StudentData";
import editAsseement from "./components/admin/assessment/editAsseement";
import PleaseWaitModal from "./components/Share/PleaseWaitModal";
import SystemCheckModal from "./components/Share/SystemCheckModal";

//
import ResetPassword from "./components/Reset Password/ResetPassword";
import Message from "./components/Reset Password/Meassage";
// import StudentData from "./components/Teachers/Assignment/StudentData";

export default class Routes extends Component {
  render() {
    const loginType = sessionStorage.getItem("loginType");
    const { location, match } = this.props;
    // if (location.pathname === "/") {
    //   return <Redirect to={"/student/login"} />;
    // }
    // if (location.pathname === "/student") {
    //   return <Redirect to={"/student/dashboard"} />;
    // } else if (location.pathname === "/admin") {
    //   return <Redirect to={"/admin/login"} />;
    // }
    let route;
    if (loginType === "student") {
      route = <Route path="/student" component={StudentLayout} />;
    } else if (loginType === "teacher") {
      route = <Route path="/teacher" component={TeacherLayout} />;
    } else if (loginType === "superadmin") {
      route = <Route path="/admin" component={AdminLayout} />;
    } else {
      route = <Redirect to={"/"} />;
    }
    return (
      <>
        <Switch>
          <Route path="/StudentData/:id" component={StudentData} />
          <Route path="/register" component={AuthLayout} />
          <Route path="/contact" component={Contact} />
          <Route path="/Subscription" component={Subscription} />
          <Route path="/certificate" component={certificate} />
          <Route path="/register-success" component={AuthLayout} />
          <Route path="/verification" component={VerifyLayout} />
          <Route path="/verify-success" component={VerifyLayout} />
          <Route path="/PleaseWait" component={PleaseWaitModal} />
          <Route path="/SystemCheck" component={SystemCheckModal} />
       
          <Route
            path="/change-password/:id/:token"
            component={ChangePassword}
          />
          <Route
            path="/email-verification/:id"
            component={EmailVerify}
          />
          <Route path="/orgadmin" component={OrgAdminLayout} />

          <Route exact path="/" component={AuthLayout} />
          <Route path="/lms/reset-password/:email" component={ResetPassword} />
          <Route path="/lms/message" component={Message} />
          {route}
        </Switch>
      </>
    );
  }
}