import React, { Component } from "react";
import LoadingModal from "../../components/Share/LoadingModal";
import AddCurriculum from "../../components/admin/curriculum/addCurriculum";
import ViewCurriculum from "../../components/admin/curriculum/viewCurriculum";
import Curriculum from "../../components/admin/curriculum/curriculum";
import { userID } from "../../services/Authentication/AuthService";
import {
  getAllCurriculum,
  saveCurriculum,
  getCurriculum,
  deleteCurriculum,
} from "../../services/Admin/curriculumService";
import { event } from "jquery";
import { indexOf } from "lodash";
import { imageUpload } from "../../services/Admin/questionService";

class CurriculumContainer extends Component {
  state = {
    mode: "",
    search: "",
    curriculumListAll: [],
    curriculumList: [],
    curriculumImg: "",
    certificate: "",
    displayCur: "",
    position: "",
    curriculumListIndividual: [],
    displayImage: "",
    addCurriculumData: {
      organizationName: "",
      organizationCode: "",
      curriculumAward: "",
      shortName: "",
      curriculum: "",
      longName: "",
      marksMode: "",
      minimumMarks: "",
      curriculumCode: "",
      liveSession: "",
      showPreviousCurriculum: "",
      createDate: new Date(),
      duration: "",
      effectiveDate: "",
      endDate: "",
      startDate: "",
      eligibility: "",
      passCriteria: "",
      experience: "",
      price: "",
      open: true,
    },
    isLoading: false,
  };

  handleInput = ({ currentTarget: input }) => {
    console.log(input.value)
    const addCurriculumData = this.state.addCurriculumData;
    console.log(input.name);
    if (input.value === "Yes") {
      addCurriculumData[input.name] = true;
    } else if (input.value === "No") {
      addCurriculumData[input.name] = false;
    } else {
      addCurriculumData[input.name] = input.value;
    }
    if (input.name === "organizationImage") {
    }

    this.setState({ addCurriculumData });
  };
  handleInputImage = (event, name, position) => {
    console.log(name);

    if (name === "cur") {
      if (event.target.files && event.target.files[0]) {
        console.log("event", event.target.files);
        this.setState({ certificate: event.target.files[0] });
        this.setState({ position: position });

        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ displayCur: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        // No file selected, handle accordingly
        this.setState({ certificate: null, position: null, displayCur: null });
        alert("No file selected for certificate.");
      }
    } else {
      if (event.target.files && event.target.files[0]) {
        console.log("event", event.target.files);
        this.setState({ curriculumImg: event.target.files[0] });

        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ displayImage: e.target.result });
        };
        reader.readAsDataURL(event.target.files[0]);
      } else {
        // No file selected, show an alert
        alert("No file selected for organization image.");
        this.setState({ curriculumImg: null, displayImage: null });
      }
    }
  };

  handlePosition = (position) => {
    this.setState({ position: position });
  };
  handleCkeditor = (data, name) => {
    const addCurriculumData = this.state.addCurriculumData;
    addCurriculumData[name] = data;
    this.setState({ addCurriculumData });
  };

  handleDates = (date, name) => {
    const addCurriculumData = this.state.addCurriculumData;
    addCurriculumData[name] = date;
    this.setState({ addCurriculumData });
  };

  generateCurriculamName = () => {
    const addCurriculumData = this.state.addCurriculumData;
    if (addCurriculumData.shortName !== "") {
      addCurriculumData.curriculum =
        addCurriculumData.organizationCode.trim() +
        "-" +
        addCurriculumData.curriculumAward.trim() +
        "-" +
        addCurriculumData.shortName.trim();
    }
  };

  getAllCurriculumList = async () => {
    this.setState({ open: true });
    getAllCurriculum()
      .then((res) => {
        this.setState({
          curriculumList: res.data.data,
          curriculumListAll: res.data.data,
          open: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleAddCurriculum = async () => {
    try {
      let payload = {...this.state.addCurriculumData,
      organizationName:this.state.addCurriculumData.organizationName.trim(),
      organizationCode:this.state.addCurriculumData.organizationCode.trim(),
      curriculumAward:this.state.addCurriculumData.curriculumAward.trim(),
      shortName:this.state.addCurriculumData.shortName.trim(),
      longName:this.state.addCurriculumData.longName.trim(),
      minimumMarks:this.state.addCurriculumData.minimumMarks,
      price:this.state.addCurriculumData.price.trim()
      };
      console.log(payload)
      payload.createDate = new Date();

      if (this.state.position !== "") {
        payload.certificate_align = this.state.position;
      }

      let curriculumId = this.props.match.params.id;
      let data = new FormData();

      if (this.state.curriculumImg) {
        data.append(
          "profileImage",
          this.state.curriculumImg,
          this.state.curriculumImg.name
        );
      }

      if (this.state.certificate) {
        data.append(
          "profileImage",
          this.state.certificate,
          this.state.certificate.name
        );
      }

      let headers = {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      };

      this.setState({ isLoading: true });
      if (this.state.curriculumImg || this.state.certificate) {
        const res = await imageUpload(data, headers);

        if (res.data.data.length > 0) {
          if (this.state.curriculumImg && !this.state.certificate) {
            payload.location = res.data.data[0].location;
            payload.fieldname = res.data.data[0].key;
          } else if (this.state.certificate && !this.state.curriculumImg) {
            payload.certificate_bg = {
              fileName: res.data.data[0].key,
              url: res.data.data[0].location,
            };
          } else {
            payload.location = res.data.data[0].location;
            payload.fieldname = res.data.data[0].key;
            payload.certificate_bg = {
              fileName: res.data.data[1].key,
              url: res.data.data[1].location,
            };
          }

          await saveCurriculum(payload, curriculumId);
        } else {
          throw new Error("Image upload failed");
        }
      } else {
        await saveCurriculum(payload, curriculumId);
      }

      this.setState({ isLoading: false });

      this.props.history.push("/admin/curriculum");
    } catch (error) {
      console.error("Error:", error);

      // Handle error, show alert, etc.
       alert("Please Fill all the");

      this.setState({ isLoading: false });
    }
  };

  getIndividualCurriculumList = async () => {
    this.setState({ open: true });
    const curriculumId = this.props.match.params.id;
    const payload = {
      _id: curriculumId,
    };
    try {
      const response = await getCurriculum(payload);
      this.setState({
        curriculumListIndividual: response.data.data[0],
        open: false,
      });
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };

  editCurriculum = async () => {
    const curriculumId = this.props.match.params.id;
    const payload = {
      _id: curriculumId,
    };
    this.setState({ isLoading: true });
    try {
      const response = await getCurriculum(payload);
      console.log(response.data.data[0]);
      this.setState({
        addCurriculumData: response.data.data[0],
        displayImage: "",
        isLoading: false,
      });
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  deleteEntireCurriculum = async () => {
    const codeCurriculum = this.state.curriculumListIndividual.curriculumCode;
    const curriculum = this.state.curriculumListIndividual.curriculum;
    const payload = {
      curriculumCode: codeCurriculum,
      curriculum: curriculum,
    };
    console.log(payload);
    try {
      await deleteCurriculum(payload);
      this.props.history.push("/admin/curriculum");
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  handleUpdateCurriculum = async () => {
    const curriculumId = this.props.match.params.id;
    let payload = this.state.addCurriculumData;
    try {
      await saveCurriculum(payload, curriculumId);
      this.props.history.push("/admin/curriculum");
    } catch (ex) {
      // if (ex.response && ex.response.status === 404) {
      //   this.props.history.replace("/not-found");
      // }
    }
  };
  searchCurr = (event) => {
    this.setState({ search: event.target.value });
    let val = String(event.target.value)
      .toLowerCase()
      .trim();

    // if (!val) {
    //   this.getAllCurriculumList();
    //   return;
    // }
    let filter = this.state.curriculumListAll.filter((q) => {
      if (q.curriculum.toLowerCase().indexOf(val) !== -1) {
        return q;
      }
    });
    // console.log('filter-' + JSON.stringify(filter))
    this.setState({ curriculumList: [...filter.reverse()] });
  };

  closeViewCurriculum = () => {
    this.props.history.push("/admin/curriculum");
  };

  loadDefaultValues = () => {
    const pathName = this.props.match.path;
    if (pathName === "/admin/add-curriculum") {
      this.setState({ mode: "Add" });
    } else if (pathName === "/admin/edit-curriculum/:id") {
      this.setState({ mode: "Edit" });
      this.editCurriculum();
    } else if (pathName === "/admin/view-curriculum/:id") {
      this.setState({ mode: "View" });
      this.getIndividualCurriculumList();
    } else if (pathName === "/admin/curriculum") {
      this.setState({ mode: "List" });
      this.getAllCurriculumList();
    }
  };

  componentDidMount() {
    this.loadDefaultValues();
  }

  render() {
    let path = this.props.match.path;
    return (
      <React.Fragment>
        {path === "/admin/curriculum" && (
          <Curriculum
            curriculumList={this.state.curriculumList}
            searchCurr={this.searchCurr}
            search={this.state.search}
            open={this.state.open}
          />
        )}

        {(path === "/admin/add-curriculum" ||
          path === "/admin/edit-curriculum/:id") && (
          <AddCurriculum
            mode={this.state.mode}
            handleAddCurriculum={this.handleAddCurriculum}
            curriculumListIndividual={this.state.curriculumListIndividual}
            handleCkeditor={this.handleCkeditor}
            addCurriculumData={this.state.addCurriculumData}
            handleInput={this.handleInput}
            generateCurriculamName={this.generateCurriculamName}
            handleDates={this.handleDates}
            handleInputImage={this.handleInputImage}
            displayImage={this.state.displayImage}
            open={this.state.open}
            displayCur={this.state.displayCur}
            handlePosition={this.handlePosition}
          />
        )}

        {path === "/admin/view-curriculum/:id" && (
          <ViewCurriculum
            curriculumListIndividual={this.state.curriculumListIndividual}
            closeViewCurriculum={this.closeViewCurriculum}
            deleteEntireCurriculum={this.deleteEntireCurriculum}
            open={this.state.open}
          />
        )}
        <LoadingModal visible={this.state.isLoading} />
      </React.Fragment>
    );
  }
}

export default CurriculumContainer;
