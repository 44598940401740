import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { curriculumListFun } from "../../../utils/utils";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextError from "../../../common/Formik/TextError";
import { subjectValidation } from "../../../validations/AdminValidations";
import {
  InputComponent,
  SelectComponent,

  Button,
} from "../../../common/FormComponents";
import '../../../common/Formik/Common.css'
import Autocomplete1 from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormikControl from "../../../common/Formik/FormikControl";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const AddSubject = (props) => {
  const {
    mode,
    curriculumList,
    subjectData,
    isloading,
    handleInput,
    handleCkEditor,
    handleSubmit,
    handleDates,
    handleInput1
  } = props;

  const [data,setData] = useState(subjectData)
  let curriculumOptions = curriculumListFun(curriculumList);
  console.log(curriculumOptions)
  console.log(data.curriculum)

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(()=>{
    setData(subjectData)
  },[props,data])

  return (
    <>
   {!isloading?( 
   <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              width: "160%",
              alignItems: "center",
            }}
          >
            <div></div>
            <h1 className="form-heading" style={{ color: "#4A1D75",fontFamily:"roboto" }}>
              {mode} Subject
            </h1>
            <div className="add-btn-div">
              <Link
                to={`/admin/subject`}
                className="btn btn-sm "
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4A1D75",
                  border: "2px solid #4A1D75",
                  width: "140px",
                  textAlign: "center",
                  padding: "6px",
                  color: "white",
                  height: "auto",
                }}
              >
                Cancel
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={data}
                validationSchema={subjectValidation}
                validateOnMount
                enableReinitialize
              >
                {(props) => {
                  return (
                    <Form >
                      <div className="row">
                        {/* <Field
                          col="col-md-6"
                          label="Curriculum"
                          name="curriculum"
                          optname="curriculum"
                          options={curriculumOptions}
                          component={SelectComponent}
                          onChange={(e) => handleInput(e, "curriculum")}
                          value={data.curriculum}
                        /> */}
                        {/* <FormikControl
                          control="autoCompolete"
                          label="Curriculum Name"
                          optname="curriculum"
                          name="curriculum"
                          placeholder="Select Curriculum"
                          options={curriculumOptions}
                          onChange={(event, value) => handleInput(value.curriculum, "curriculum")}

                        /> */}
                        <div className="form-group col-md-6">
                          <label className="label-heading" htmlFor="curriculum">
                            Curriculum
                          </label>

                          {mode === "Add" && (
                            <Autocomplete1
                              disablePortal
                              id="combo-box-demo"
                              // className="form-control"
                              required
                              options={curriculumOptions}
                              getOptionLabel={(option) => option.curriculum}
                              onChange={(event, value) =>
                                handleInput1(value.curriculum, "curriculum")
                              }
                              style={{ width: "100%", height: "38px" }}

                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />

                              )}
                            />
                          )}
                          {mode === "Edit" && data.curriculum &&(
                            <Autocomplete1
                              disablePortal
                              id="combo-box-demo"
                              // className="form-control"
                              required
                              options={curriculumOptions}
                              getOptionLabel={(option) => option.curriculum}
                              onChange={(event, value) =>
                                handleInput1(value.curriculum, "curriculum")
                              }
                              disableClearable={true}
                              defaultValue={{curriculum: data.curriculum ? data.curriculum : ''}}
                              style={{ width: "100%", height: "38px" }}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          )}
                          <ErrorMessage
                            component={TextError}
                            name="curriculum"
                          />
                        </div>

                        <input
                          type="hidden"
                          className="form-control"
                          name="curriculumCode"
                          defaultValue={data.curriculumCode}
                          disabled
                          required
                        />

                        <Field
                        style={{height:'43px'}}
                          col="col-md-6"
                          name="subject"
                          label="Subject Name"
                          component={InputComponent}
                          onChange={(e) => handleInput(e)}
                          value={data.subject}
                          required
                        />

                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="label-heading" htmlFor="">
                              Subject Info
                            </label>

                            <CKEditor
                              editor={ClassicEditor}
                              data={data.subjectInfo ? data.subjectInfo : ""}
                              onChange={(event, editor) => {
                                handleCkEditor(editor.getData(), "subjectInfo");
                              }}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group col-md-6">
                          <label> Start Date (MM/DD/YYYY)</label>
                          <DatePicker
                            name="startDate"
                            placeholderText="MM/DD/YYYY"
                            className="form-control"
                            selected={
                              data.startDate === ""
                                ? startDate
                                : new Date(
                                    moment(data.startDate).format("MM/DD/YYYY")
                                  )
                            }
                            onChange={(date) => {
                              handleDates(date, "startDate");
                              setStartDate(date);
                            }}
                            autoComplete="off"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            required
                          />
                          <ErrorMessage
                            component={TextError}
                            name="startDate"
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label>End Date (MM/DD/YYYY)</label>
                          <DatePicker
                            name="endDate"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            selected={
                              data.endDate === ""
                                ? endDate
                                : new Date(
                                    moment(data.endDate).format("MM/DD/YYYY")
                                  )
                            }
                            onChange={(date) => {
                              handleDates(date, "endDate");
                              setEndDate(date);
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            autoComplete="off"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            required
                          />
                          <ErrorMessage component={TextError} name="endDate" />
                        </div>

                        {/* <DatepickerComponent
                          col="col-md-6"
                          name="startDate"
                          label="Course Start Date (MM/DD/YYYY)"
                          minDate={new Date()}
                          selected={data.startDate ? new Date() : ""}
                          dateFormat="yyyy/MM/dd"
                          onChange={(date) => handleDates(date, "startDate")}
                        /> */}

                        {/* <DatepickerComponent
                          col="col-md-6"
                          name="endDate"
                          label="Course End Date (MM/DD/YYYY)"
                          startDate={data.startDate}
                          endDate={data.endDate}
                          minDate={data.startDate}
                          selected={
                            data.endDate
                              ? new Date(
                                  moment(data.endDate).format("MM/DD/YYYY")
                                )
                              : ""
                          }
                          onChange={(date) => handleDates(date, "endDate")}
                        /> */}

                        {/* <Field
                          col="col-md-6"
                          name="minCredits"
                          label="Min Credits"
                          component={InputComponent}
                          onChange={(e) => handleInput(e)}
                          value={data.minCredits}
                        />

                        <Field
                          col="col-md-6"
                          name="maxCredits"
                          label="Max Credits"
                          component={InputComponent}
                          onChange={(e) => handleInput(e)}
                          value={data.maxCredits}
                        />
                        <div className="col-md-6">
                        <div className="relative">
                        <Field
                          name="practicalSession"
                          optname="practicalSession"
                          label="Practical Session"
                          options={[
                            { _id: "1", practicalSession: "Yes" },
                            { _id: "2", practicalSession: "No" },
                          ]}
                          component={SelectComponent}
                          onChange={(e) => handleInput(e)}
                          value={data.practicalSession}
                        />
                        <KeyboardArrowDownIcon className='drop_icon'/>
                        </div>
                        </div> */}

                        <Button
                          type="submit"
                          className="btn form-btn"
                          label={"Submit"}
                          onClick={() => handleSubmit()}
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            width: "140px",
                            textAlign: "center",
                            padding: "6px",
                            color: "white",
                            height: "auto",
                          }}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>):
    (' ')
    }
   </>
  );
};

export default AddSubject;
