import React, { useState, useEffect } from "react";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./curriculum.css";
import Input from "../common/input";
import Select from "../common/select";
import { Link } from "react-router-dom";
import loop from "../../../assets/Images/admin-bg1.jpg";
import MenuItem from "@mui/material/MenuItem";
import { Backdrop, CircularProgress, Button } from "@mui/material";

const AddCurriculum = (props) => {
  const {
    mode,
    addCurriculumData,
    generateCurriculamName,
    handleAddCurriculum,
    handleInput,
    handleCkeditor,
    handleDates,
    handleInputImage,
    displayImage,
    open,
    displayCur,
    handlePosition,
  } = props;
 

  const [data, setData] = useState({});
  const [imageError, setImageError] = useState('');
  const [imageErrorTemplate, setImageErrorTemplate] = useState('');
  const [experiencedate, setExeperienceData] = useState(data.experience);
  const [markMode, setMarkMode] = useState(data.marksMode);
  const [liveSession, setLiveSession] = useState(data.liveSession);
  const [curriculumMode, setCurriculumMode] = useState(data.curriculumMode);
  const [preCurri, setPreCurri] = useState(data.showPreviousCurriculum);
  const [align, setAlign] = useState("center");
  const [show, setShow] = useState(false);

  let date1 = new Date(data.startDate);
  let date2 = new Date(data.endDate);
  let diffTime = Math.abs(date2 - date1);
  let durationTime = (diffTime / (1000 * 60 * 60 * 24 * 30 * 12)).toFixed(1);
  data.duration = durationTime && durationTime !== "NaN" ? durationTime : "";
  data.curriculumCode = data.curriculum
    ? data.curriculum + "-" + new Date().getFullYear()
    : "";

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  // const [require, setRequire] = useState("");
  //   const [requireCOde, setRequireCode] = useState("");
  // const [requireCodeErr, setRequireCodeErr] = useState(false);

  // function formHandler(e) {
  //   e.preventDefault();
  //   console.log(require);
  //   // if(require == null)
  //   // {
  //   alert("Enter Correct Value");
  //   // }
  //   console.log("btn clicked");
  // }

  // function requireHandler(e) {
  //   setRequire(e.target.value);
  //   let req = e.target.value;

  //   if (req == null) {
  //     console.log("Invalid orgName");
  //     setRequireErr(true);
  //   } else {
  //     setRequireErr(false);
  //   }
  // }
  // function btnclick() {

  //   if (require == "") {
  //     alert("erere");
  //   } else {

  //     handleAddCurriculum();
  //   }

  //   if (requireCOde == "") {
  //     alert("erere");
  //   } else {
  //     handleAddCurriculum();
  //   }

  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if(!displayImage){
  //     setImageError('Image is required');
  //     setImageErrorTemplate('Image is mandotary')
  //   }
  //   else{
  //     setImageError('')
  //     setImageErrorTemplate('')
      
  //     handleAddCurriculum();

  //   }
    
    
  // };
  console.log(data.minimumMarks,"$$$$$$$$$$$$")
  const handleSubmit = (e) => {
    e.preventDefault();
    // Reset error messages
    setImageError('');
    setImageErrorTemplate('');
  
    if (!displayImage) {
      // Set error message for no image selected
      setImageError('Image is required');
    }
  
    if (!displayCur) {
      // Set error message for no image template selected
      setImageErrorTemplate(' Template image is required');
    }
  
    if (displayImage && displayCur) {
      // Both conditions are met, proceed with form submission
      handleAddCurriculum();
    }

    if (mode === "Edit") {
      setImageError('');
      setImageErrorTemplate('');
      handleAddCurriculum();
    
    }
    else{
      // handleAddCurriculum();
    }
  };
  useEffect(() => {
    if (addCurriculumData.organizationName !== null) {
      setData(addCurriculumData);
    }
  }, [addCurriculumData]);


  
  useEffect(() => {
    setAlign(data.certificate_align);
    setExeperienceData(data.experience);
    setMarkMode(data.marksMode);
    setLiveSession(data.liveSession ? "Yes" : "NO");
    setPreCurri(data.showPreviousCurriculum ? "Yes" : "NO");
    setCurriculumMode(data.curriculumMode);
    
  }, [data]);
  console.log(align);


  return (
    // <div className="maindiv">
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title style={{ fontSize: "18px", width: "100%" }}>
            <div className="d-flex justify-content-between">
              <div>Upload Template</div>
              <div
                onClick={() => setShow(false)}
                style={{
                  border: "2px solid black",
                  padding: "2px 10px",
                  cursor: "pointer",
                  background: "#e9ecef",
                }}
              >
                x
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <span className=" text-align pb-2">Text Alignment</span>
              <div className="col-sm">
                <div className="text w-100">
                  <button
                    className={
                      align === "center" ? "uploadButton" : "uploadButtonChange"
                    }
                    onClick={(e) => {
                      setAlign("center");
                      handlePosition("center");
                    }}
                  >
                    <div>
                      <span>Center Align </span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-sm ">
                <div className="text w-100">
                  <button
                    className={
                      align === "left" ? "uploadButton" : "uploadButtonChange"
                    }
                    onClick={(e) => {
                      setAlign("left");
                      handlePosition("left");
                    }}
                  >
                    <div>
                      <span>Left Align </span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-sm ">
                <div className="text w-100">
                  <button
                    className={
                      align === "right" ? "uploadButton" : "uploadButtonChange"
                    }
                    onClick={(e) => {
                      setAlign("right");
                      handlePosition("right");
                    }}
                  >
                    <div>
                      <span>Right Align </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <br />
            <div className="d-flex justify-content-center align-items-center">
              <div className="logo d-flex justify-content-center align-items-center">
                {console.log(displayCur)}
                {!displayCur && !data.imgLink && <p>Select A Background</p>}
                {mode === "Add" && displayCur && (
                  <img className="imageLogo" src={displayCur} alt="no img" />
                )}
                {mode === "Edit" && (displayCur || data.certificate_bg) && (
                  <img
                    className="imageLogo"
                    src={displayCur ? displayCur : data.certificate_bg.url}
                    alt="no img"
                  />
                )}
              </div>
            </div>
            <div className="box_style img-btn">
              <div className="text-center w-100">
                <input
                  type="file"
                  id="2actual-btn1"
                  name="certificate"
                  hidden
                  onChange={(e) => {
                    handleInputImage(e, "cur", align)
                    setImageErrorTemplate('')
                  }  
                  }
                />
                <label
                  className="text-center text-gray"
                  htmlFor="2actual-btn1"
                  type="button"
                >
                  
                  <br />
                  <div className="uploadButton">
                    <span>Choose File</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "99%",
                alignItems: "center",
              }}
            >
              <div>
                <h1
                  className="form-heading"
                  style={{ color: "#4A1D75", fontFamily: "roboto" }}
                >
                  {mode} Curriculum
                </h1>
              </div>
              <div
              // className="add-btn-div"
              >
                <Link
                  to={`/admin/curriculum`}
                  className="btn btn-sm "
                  style={{
                    borderRadius: "40px",
                    backgroundColor: "#4A1D75",
                    border: "2px solid #4A1D75",
                    width: "140px",
                    textAlign: "center",
                    padding: "6px",
                    color: "white",
                    height: "auto",
                  }}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="card">
                <div className="card-body">
                  <Input
                    type="text"
                    name="organizationName"
                    label="Organization Name"
                    value={data.organizationName ? data.organizationName : ""}
                    // onChange={requireHandler}
                    // disabled
                    onChange={handleInput}
                    required
                  />
                  <Input
                    type="text"
                    name="organizationCode"
                    label="Organization Code"
                    value={data.organizationCode ? data.organizationCode : ""}
                    onChange={handleInput}
                    // onChange={requireHandler}
                    // disabled
                    required
                  />

                  <Input
                    type="text"
                    // defaultValue={data.fieldname?data.fieldname:""}
                    // name="organizationImage"
                    label="Organization Image"
                    // onChange={handleInputImage}
                    // required={false}
                    hidden
                    disabled
                  />

                  <div className="d-flex justify-content-center align-items-center">
                    <div className="logo d-flex justify-content-center align-items-center">
                      {console.log(displayImage)}
                      {!displayImage && !data.imgLink && <p>Select A Image</p>}
                      {mode === "Add" && displayImage && (
                        <img
                          className="imageLogo"
                          src={displayImage}
                          alt="no img"
                        />
                      )}
                      {mode === "Edit" && (displayImage || data.imgLink) && (
                        <img
                          className="imageLogo"
                          src={displayImage ? displayImage : data.imgLink}
                          alt="no img"
                        />
                      )}
                    </div>
                  </div>
                  <div className="box_style img-btn">
                    <div className="text-center w-100">
                      <input
                        type="file"
                        // required
                        id="2actual-btn"
                        name="organizationImage"
                        hidden
                        onChange={(e) => {
                          handleInputImage(e, "", "")
                        setImageError("")
                        
                        }
                        }
                        
                      />
                      <label
                        className="text-center text-gray"
                        htmlFor="2actual-btn"
                        type="button"
                      >
                        <br />
                        <div className="uploadButton">
                          <span>Upload</span>
                        </div>
                      </label>
                      {imageError && <p style={{ color: 'red', marginTop: '5px' }}>{imageError}</p>}
  
                    </div>
                  </div>

                  <Input
                    type="text"
                    name="curriculumAward"
                    label="Curriculum Award"
                    value={data.curriculumAward ? data.curriculumAward : ""}
                    onChange={(e) => {
                      handleInput(e);
                      generateCurriculamName(e);
                    }}
                    required
                  />
                  <Input
                    type="text"
                    name="shortName"
                    label="Short Name"
                    value={data.shortName ? data.shortName : ""}
                    onChange={(e) => {
                      handleInput(e);
                      generateCurriculamName();
                    }}
                    required
                  />
                  <Input
                    type="text"
                    name="curriculum"
                    label="Curriculum Name"
                    value={data.curriculum ? data.curriculum : ""}
                    onChange={handleInput}
                    disabled
                  />
                  <Input
                    type="text"
                    name="curriculumCode"
                    label="Curriculum Code"
                    value={data.curriculumCode ? data.curriculumCode : ""}
                    onChange={handleInput}
                    disabled
                  />
                  <Input
                    type="text"
                    name="longName"
                    label="Long Name"
                    value={data.longName ? data.longName : ""}
                    onChange={handleInput}
                    required
                  />
                  {/* <Select
                  label=" Experience"
                  name="experience"
                  value={data.experience ? data.experience : ""}
                  options={[
                    { _id: "1", name: "Internship" },
                    { _id: "2", name: "Placement" },
                    { _id: "3", name: "Work experience" },
                    { _id: "4", name: "Industry experience" },
                  ]}
                  onChange={handleInput}
                  required
                /> */}
                  <div className="form-group">
                    <label className="label-heading" htmlFor="curriculum">
                      Experience
                    </label>
                    <select
                      className="w-100 form-select"
                      name="experience"
                      value={experiencedate}
                      onChange={(e) => {
                        setExeperienceData(e.target.value);
                        // handleInput(e,"experience");
                        handleInput(e);
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        {" "}
                        select
                      </option>
                      <option>Internship</option>
                      <option>Placement</option>
                      <option>Work experience</option>
                      <option>Industry experience</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="label-heading" htmlFor="curriculum">
                      Marks Mode
                    </label>
                    <select
                      className="w-100 form-select"
                      name="marksMode"
                      value={markMode}
                      onChange={(e) => {
                        setMarkMode(e.target.value);
                        // handleInput(e,"marksMode");
                        handleInput(e);
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      <option>Percentage</option>
                      <option>Marks</option>
                    </select>
                  </div>
                  <Input
                    type="number"
                    name="minimumMarks"
                    label="Minimum Marks"
                    value={data.minimumMarks ? data.minimumMarks : ""}
                    onChange={handleInput}
                    required
                  />

                  <div className="form-group">
                    <label className="label-heading" htmlFor="curriculum">
                      Live Session
                    </label>
                    <select
                      className="w-100 form-select"
                      name="liveSession"
                      value={liveSession}
                      onChange={(e) => {
                        setLiveSession(e.target.value);
                        // handleInput(e, "liveSession");
                        handleInput(e);
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  {/* <Select
                  label="Show Previous Curriculums"
                  name="showPreviousCurriculum"
                  value={
                    data.showPreviousCurriculum === true
                      ? "Yes"
                      : data.showPreviousCurriculum === false
                      ? "No"
                      : ""
                  }
                  options={[
                    { _id: "1", name: "Yes" },
                    { _id: "2", name: "No" },
                  ]}
                  onChange={handleInput}
                  required
                /> */}
                  {/* <div className="form-group">
                  <label className="label-heading" htmlFor="curriculum">
                    How Previous Curriculums
                  </label>
                  <select
                    className="w-100 form-select"
                    name="showPreviousCurriculum"
                    value={preCurri}
                    onChange={(e) => {
                      setPreCurri(e.target.value);
                      // handleInput(e, "showPreviousCurriculum");
                      handleInput(e);

                    }}
                  >
                    <option
                      defaultValue="select"
                      defaultChecked
                      selected="selected"
                      value={"select"}
                    >
                      select
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div> */}
                  <div className="form-group">
                    <label>Curriculum Mode</label>

                    <select
                      className="w-100 form-select"
                      name="curriculumMode"
                      value={curriculumMode}
                      onChange={(e) => {
                        setCurriculumMode(e.target.value);
                        // handleInput(e, "liveSession");
                        handleInput(e);
                      }}
                    >
                      <option
                        defaultValue="select"
                        defaultChecked
                        selected="selected"
                        value={"select"}
                      >
                        select
                      </option>
                      <option value={"Online"}>Online</option>
                      <option value={"Offline"}>Offline</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Eligibility</label>

                    <CKEditor
                      data={data.eligibility}
                      editor={ClassicEditor}
                      onBlur={(event, editor) =>
                        handleCkeditor(editor.getData(), "eligibility")
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Pass Criteria</label>
                    <CKEditor
                      data={data.passCriteria}
                      editor={ClassicEditor}
                      onBlur={(event, editor) =>
                        handleCkeditor(editor.getData(), "passCriteria")
                      }
                    />
                  </div>

                  <Input
                    type="text"
                    name="createDate"
                    label="Create Date (MM/DD/YYYY)"
                    value={
                      data.createDate
                        ? moment(data.createDate).format("MM/DD/YYYY")
                        : ""
                    }
                    onChange={handleInput}
                    disabled
                  />

                  {/* <div className="form-group">
                  <label>Effective Date (MM/DD/YYYY)</label>
                  <DatePicker
                    name="effectiveDate"
                    selected={
                      data.effectiveDate === ""
                        ? effectiveDate
                        : new Date(
                          moment(data.effectiveDate).format("MM/DD/YYYY")
                        )
                    }
                    onChange={(date) => {
                      handleDates(date, "effectiveDate");
                      setEffectiveDate(date);
                    }}
                    minDate={new Date()}
                    className="form-control"
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="MM/DD/YYYY"
                    required
                  />
                </div> */}
                  <div className="form-group">
                    <label> Start Date (MM/DD/YYYY)</label>
                    <DatePicker
                      name="startDate"
                      className="form-control"
                      selected={
                        data.startDate === ""
                          ? startDate
                          : new Date(
                              moment(data.startDate).format("MM/DD/YYYY")
                            )
                      }
                      onChange={(date) => {
                        handleDates(date, "startDate");
                        setStartDate(date);
                      }}
                      startDate={data.effectiveDate}
                      minDate={mode == "Add" ? new Date() : null}
                      endDate={endDate}
                      autoComplete="off"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="MM/DD/YYYY"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>End Date (MM/DD/YYYY)</label>
                    <DatePicker
                      name="endDate"
                      className="form-control"
                      selected={
                        data.endDate === ""
                          ? endDate
                          : new Date(moment(data.endDate).format("MM/DD/YYYY"))
                      }
                      onChange={(date) => {
                        handleDates(date, "endDate");
                        setEndDate(date);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                      /* minDate={startDate} */
                      minDate={mode == "Add" ? new Date() : null}
                      autoComplete="off"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="MM/DD/YYYY"
                      required
                    />
                  </div>
                  <label>Certificate Template</label>

                  <div className="text-center">
                    <Button
                      className="btn mt-2"
                      style={{
                        borderRadius: "40px",
                        backgroundColor: "#4A1D75",
                        border: "2px solid #4A1D75",
                        width: "180px",
                        textAlign: "center",
                        padding: " 3px 6px",
                        color: "white",
                        height: "auto",
                      }}
                      onClick={() => setShow(true)}
                    >
                      Upload Template
                    </Button>
                    {imageErrorTemplate && <p style={{ color: 'red', marginTop: '5px' }}>{imageErrorTemplate}</p>}
                  </div>
                  <Input
                    type="text"
                    name="duration"
                    label="Duration (in Years)"
                    value={data.duration ? data.duration : ""}
                    onChange={handleInput}
                    disabled
                  />

                  <Input
                    type="number"
                    name="price"
                    label="Price of Curriculum"
                    value={data.price ? data.price : ""}
                    min="00"
                    onChange={handleInput}
                    required
                  />

                  <div className="text-center">
                    <button
                      //  onClick={handleAddCurriculum}
                      // onClick={btnclick}
                      type="submit"
                      className="btn mt-2"
                      style={{
                        borderRadius: "40px",
                        backgroundColor: "#4A1D75",
                        border: "2px solid #4A1D75",
                        width: "140px",
                        textAlign: "center",
                        padding: "6px",
                        color: "white",
                        height: "auto",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
    // </div>
  );
};

export default AddCurriculum;
