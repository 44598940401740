import React, { useEffect, useState } from "react";
import "./Subscription.css";
import { getAllCurriculum } from "../../services/Admin/curriculumService";
import { verfyPayment } from "../../services/Authentication/AuthService";
import { userID, ID } from "../../services/Authentication/AuthService";
import { getUserCurriculum } from "../../services/Student/CurriculumStudentService";
import bg from "../../assets/Images/CurriculamPage.png";
import { useHistory } from "react-router-dom";
import { apiUrl } from "../../config.json";
const Subscription_card = () => {
  console.log(userID(), "<-");

  const [amount, setamount] = useState(100);
  const [payID, setID] = useState([]);
  const [subCur, setSubCur] = useState([]);
  const history = useHistory();
  const usercur = () => {
    getUserCurriculum(userID())
      .then((res) => {
        let curdata = [];
        res.data.data.map((e) => {
          curdata.push(e.curriculumCode);
        });
        setSubCur(curdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(subCur);
  const handlePay = async (curriculum, price) => {
    console.log("dataaaaaaa", typeof curriculum);

    if (amount === "") {
      alert("please enter amount");
    } else {
      try {
        let response = await fetch(`${apiUrl}/user/orderPayment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            user: {
              userID: ID(),
            },

            amount: price * 100,
            currency: "INR",
            curriculumID: curriculum,
          }),
        });

        let orderData = await response.json();
        console.log(orderData.data.paymentDetail.orderId, "adfsadasd");

        var options = {
          key: "rzp_test_ax5R0HtxrLmVyD",
          key_secret: "GtWiRjjdH8bWjGuIdgXeiewm",
          amount: 1000000,
          currency: "INR",
          order_id: orderData.data.paymentDetail.orderId,
          name: "lms",
          description: "testing",
          handler: function (response) {
            // alert(response.razorpay_payment_id);
            console.log(response);
            verfypay(response, curriculum);
          },
          prefill: {
            name: "uxdlab",
            email: "uxdlab@gmail.com",
            contact: "23456787654",
          },
          notes: {
            address: "Razorpay Corporate office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        var pay = new window.Razorpay(options);
        pay.open();
        console.log(payID, "hfvdsdf");
      } catch (error) {
        console.log(error);
      }
    }
  };
  function verfypay(payDeatils, curriculum) {
    let payload = {
      user: {
        userID: ID(),
        curriculumID: curriculum,
      },
      paymentDeatils: {
        paymentId: payDeatils.razorpay_payment_id,
        paymentSignature: payDeatils.razorpay_signature,
        paymentOrderId: payDeatils.razorpay_order_id,
      },
    };
    verfyPayment(payload)
      .then((res) => {
        console.log("GOOD WORK");
        history.push("/student/dashboard");

      })
      .catch((err) => {
        console.log("bad work", err);
      });
  }
  //------------------------------------------------------------------------

  const [data, setData] = useState([]);

  const getResponseData = () => {
    getAllCurriculum()
      .then((res) => {
        console.log(res.data.data, "Response");
        setData(res.data.data);
        // setArr(res.data.data)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const filterdata = () => {
    let arr = [];
    data.map((e) => {
      arr.push(e.curriculumCode);
    });

    const obj = {
      sub: arr.filter((x) => subCur.includes(x)),
      notsub: arr.filter((x) => !subCur.includes(x)),
    };

    return obj;
  };
  console.log(filterdata());
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };
  async function dataaa(dataa) {
    console.log(dataa);
  }

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    usercur();
    getResponseData();
  }, []);

  console.log("====================================");
  console.log(data, "Data---------");
  console.log("====================================");

  return (
    <React.Fragment>
      <div>
        {/* <div className='topBg'></div> */}
        <img src={bg} className="w-100" style={{paddingTop:"75px"}}/>
        <h2 className="text-center mt-5">All Curriculums</h2>
        <div className="container-fluid main">
          {data &&
            data.map((e) => {
              if (filterdata().notsub.includes(e.curriculumCode) === true) {
                return (
                  <>
                    <div
                      className="Container-fluid mb-3 text-center outer"
                      id="blocks"
                    >
                      <div className="block" id="block-I">
                        <img src={e.imgLink} className="box_img" />
                        <h5 className="block-heading mt-2">{e.curriculum}</h5>
                        <div className="info">{e.curriculumCode}</div>
                        <div className="amount">Rs.{e.price}</div>
                        <button
                          className="btn1 w-75"
                          id="button"
                          type="submit"
                          onClick={() => {
                            handlePay(e._id, e.price);
                          }}
                        >
                          BUY NOW
                        </button>
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <div className="Container text-center outer" id="blocks">
                      <div
                        className="block"
                        id="block-I"
                        style={
                          {
                            // width: "20vw",
                            // height: "auto",
                            // padding: "30px",
                            // backgroundColor: "#C5F9D5",
                            // borderRadius: "8px",
                          }
                        }
                      >
                        <img src={e.imgLink} className="box_img" />
                        <h5 className="block-heading mt-2">{e.curriculum}</h5>
                        <div className="info">{e.curriculumCode}</div>
                        <div className="amount">Rs.{e.price}</div>
                        <button
                          className="btn2 w-75"
                          id="button"
                          type="submit"
                          disabled
                        >
                          Purchased
                        </button>
                      </div>
                    </div>
                  </>
                );
              }
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subscription_card;
