import React from 'react'
import { Modal } from "react-bootstrap";
import loadingImg from "../../assets/Images/loading.gif";
import styles from "./share.module.css";

const PleaseWaitModal = (visible) => {
  return (
    <>
    <Modal
        show={visible.visible}
        animation={false}
        centered
        contentClassName={styles.modalContent}
        backdropClassName={styles.modalBackdrop}
      >
        <img
          src={loadingImg}
          alt="Loading"
          height="100"
          width="100"
          style={{ margin: "0px auto" }}
        />
        <div style={{color:"white", textAlign:"center"}}>
            <h3>Please Wait...</h3>
        </div>
      </Modal>
    </>
  )
}

export default PleaseWaitModal