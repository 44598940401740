import http from "../httpService";
import { apiUrl, api } from "../../config.json";
import { getAuthToken } from "../Authentication/AuthService";

const headers = {
    "x-access-token": getAuthToken(),
};


export const addAddressService = (payload) => {
  console.log(payload)
    return http.post(apiUrl + "/address", payload , {
      headers: headers,
    });
  };
export const editAddressService = (payload,id) => {
    return http.put(apiUrl + "/address/"+id, payload , {
      headers: headers,
    });
  };

export const getAddressById = (id) => {
    return http.get(apiUrl + "/address/"+id, {
      headers: headers,
    });
  };
export const getAddressList = () => {
    return http.get(apiUrl + "/address" , {
      headers: headers,
    });
  };

  export const getAddressDelete = (id) => {
    return http.delete(apiUrl + "/address/"+id , {
      headers: headers,
    });
  };
  export const getCreateAddress = (payload) => {
    console.log(payload)
      return http.post(apiUrl + "/address/getAddressbyCur", payload , {
        headers: headers,
      });
    };