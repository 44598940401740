import React, { useEffect } from 'react'
import style from './TestImage.module.css'
import { useState } from 'react'
import img from '../../../assets/Images/Group 24445.png'
import img1 from '../../../assets/Images/admin-bg1.jpg'
import img2 from '../../../assets/Images/admin-bg2.jpg'
import img3 from '../../../assets/Images/admin-bg3.jpg'
import { userID } from '../../../services/Authentication/AuthService'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getTestStudent, fetchAssignmentsById, setStatus } from '../../../services/Teacher/AssignmentService'
import moment from 'moment'
export default function TestView() {
    const [show, setShow] = useState(false)
    const [assessment, setAssessment] = useState({})
    const [student, setStudent] = useState([])
    const [data, setSid] = useState("")

    const { id } = useParams()
console.log(student)
    const statuss = async (status) => {
        let body = {
            id: data._id,
                
        }
        // console.log(body)
        await setStatus(body).then(e=>window.location.reload())
    }
    useEffect(async () => {
        await fetchAssignmentsById({ testID: id, msg: true }).then(e => setAssessment(e.data.data)).catch(err => console.log(err));
        await getTestStudent({
            testID: id,
            userID: userID()
        }).then(r => setStudent(r.data.data)).catch(err => console.log(err))
    }, [])
    return (
        <>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body pad-0">
                        <h1 className="form-heading">Assessment Correction</h1>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th className="fs-6">Assessment Name</th>
                                            <td>{assessment.assessmentName}</td>
                                        </tr>
                                        <tr>
                                            <th className="fs-6">Assessment Type</th>
                                            <td>{assessment.type === "Public" ? "Informal" : "Formal"}</td>
                                        </tr>
                                        <tr>
                                            <th className="fs-6">Curriculum</th>
                                            <td>{assessment.curriculum}</td>
                                        </tr>
                                        <tr>
                                            <th className="fs-6">Subject</th>
                                            <td>{assessment.subject}</td>
                                        </tr>
                                        <tr>
                                            <th className="fs-6">
                                                Start Date <small>(DD/MM/YYYY)</small>
                                            </th>
                                            <td>{moment(assessment.startdate).format("DD/MM/YYYY")}</td>
                                        </tr>
                                        <tr>
                                            <th className="fs-6">
                                                End Date <small>(DD/MM/YYYY)</small>
                                            </th>
                                            <td>{moment(assessment.enddate).format("DD/MM/YYYY")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered mt-5" style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
                                    <tbody>
                                        <tr>
                                            <th className="fs-6">Student ID</th>
                                            <th className="fs-6">Student Name</th>
                                            <th className="fs-6">Marks</th>
                                        </tr>
                                        {student.map((item) => <tr>
                                            <td>{item.studentId}</td>
                                            <td className="text-primary fw-bold pointer" onClick={() => { setShow(true); setSid(item) }}>{item.firstName + " " + item.lastName}</td>
                                            <td>{item.achievedMarks + "/" + item.totalMarks}</td>
                                        </tr>)}


                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-8">
                                {show ? (
                                    <div className="w-100">
                                        <div className="w-100 d-flex justify-content-center">
                                            <img src={img} className='w-50' />
                                        </div>
                                        <div className='w-100 d-flex justify-content-center my-4'>
                                            <div id="carouselExampleIndicators" className="carousel slide w-50" data-ride="carousel">
                                                <ol className="carousel-indicators" style={{ listStyle: 'none' }}>
                                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active toggle_dot"></li>
                                                    <li data-target="#carouselExampleIndicators" data-slide-to="1" className='toggle_dot'></li>
                                                    <li data-target="#carouselExampleIndicators" data-slide-to="2" className='toggle_dot'></li>
                                                </ol>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item customH active">
                                                        <img className="d-block w-100 " src={img1} alt="First slide" />
                                                    </div>
                                                    <div className="carousel-item customH">
                                                        <img className="d-block w-100" src={img2} alt="Second slide" />
                                                    </div>
                                                    <div className="carousel-item customH">
                                                        <img className="d-block w-100" src={img3} alt="Third slide" />
                                                    </div>
                                                </div>
                                                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-center mb-4">
                                            {data.status ==="Accept" &&(<p className={style.accept}>Accepted</p>)}
                                            {data.status ==="Reject" &&(<p className={style.reject}>Rejected</p>)}
                                            <button className="cancel me-4" onClick={() => { setShow(false); statuss("Reject") }}>Reject</button>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => { statuss("Accept") }}
                                            >
                                                Accept
                                            </button>
                                        </div>
                                    </div>) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
