import React, { useState, useEffect, Fragment } from "react";
import Logo from "../../assets/Images/logo.png";
import styles from "./verify.module.css";
import { Link } from "react-router-dom";
import { passwordChange } from "../../services/Authentication/AuthService";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { Button, useMediaQuery } from "@mui/material";

const Verify = (props) => {
  const isMobile = useMediaQuery("(max-width:950px )");
  console.log(isMobile, "isMobile");

  const [logoImg, setLogoImg] = useState("");
  let image = useSelector((state) => {
    localStorage.setItem("logo", state.logo.logo);
    return state.logo.logo;
  });
  useEffect(() => {
    //console.log('image logo',image)
    setLogoImg(image);
  }, [image]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  let history = useHistory();

  const emailSent = (event) => {
    setEmail(event.target.value);
  };
  const submitEmail = () => {
    if (email.trim() === "email has been send") {
      setError("Email cant be empty");
     

      // return;
    } else {
      
    }
    passwordChange({ email: email }).then((res) => {
      console.log(res)
      if (res.status === 200) {
       
      
          alert("please go to your mail and reset your password");
          history.push("/login");
       
          
        
      } else {
        setError(res.data.Message);
        alert("email is not correct");      
      }
    });
  };
  return (
    <Fragment>
      {/* new */}
      <Box
        // sx={{ paddingLeft: "38vw", paddingRight: "38vw", paddingTop: "22vh" }}
        sx={{
          paddingLeft: `${isMobile ? "8vw" : "38vw"}`,
          paddingRight: `${isMobile ? "8vw" : "38vw"}`,
          paddingTop: `${isMobile ? "8vw" : "22vh"}`,
        }}
      >
        <Box>
          <Box
            sx={{
              fontSize: "30px",
              fontFamily: "IvyOra Text",
              lineHeight: "52px",
              fontWeight: "700",
              color: "#4A1D75",
              textAlign: "center",
            }}
          >
            Email Address
          </Box>
        </Box>

        <Box className="d-flex justify-content-start" sx={{ paddingTop: "8%" }}>
          <input
            className="form-control  "
            placeholder="Enter a verified Email"
            id="exampleInputEmail1"
            value={email}
            onChange={emailSent}
          />
        </Box>

        <Box sx={{ paddingTop: "5%" }}>
          <Button
            onClick={submitEmail}
            variant="contained"
            sx={{
              fontFamily: "Roboto",
              fontWeight: "400",
              fontSize: "18px",
              color: "#FDFDFD",
              textTransform: "capitalize",
              background: "#4A1D75 ",
              bordeRadius: "10px",
              width: "100%",
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      {/* old */}
      {/* <div className={styles.cardContainer}>
            <div className="card bg-info" className={styles.card}>
               {logoImg && <img src={logoImg} height="170px"
                    className={styles.image} alt="logo" />}
                <div className="card-body">
                    <div className="mb-3">
                        <label for="exampleInputEmail1" className="form-label">Emal address</label>
                        <input type="email" className={`form-control ${styles.input}`} id="exampleInputEmail1" value={email} onChange={emailSent} aria-describedby="emailHelp" />
                        <small className="form-text">Ente a verified Email.</small>
                        {error && <small className="form-text text-danger">{error}.</small>}

                        <button type="submit" className="btn btn-primary mt-3 w-100" onClick={submitEmail}>Submt</button>

                    </div>
                </div>
            </div>
        </div> */}
    </Fragment>
  );
};
// const style = {
//     card: {
//         width: "50%",
//     },
//     cardImg: {
//         height: 100
//     }
// }

export default Verify;
