import React, { useEffect, useState } from "react";
import Logo from "../../assets/Images/log.png";
import "./Auth.css";
import sideImage from "../../assets/Banners/Objects1.png"

// import {jwt_decode} from "jwt-decode";
import jwt_decode from "jwt-decode"
import { Formik } from "formik";
import { loginValidation } from "../../validations/StudentValidations";
import FormikControl from "../../common/Formik/FormikControl";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';
import * as Img from '../../store/actions/logoImage'
// import GoogleLogin from "react-google-login";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

const Login = (props) => {
  const [logoImg, setLogoImg] = useState('')

  const { loginData, errorMsg, handleSubmitLogin, redirectRegister, logToGoogle,passErrorMsg } = props;

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Img.getlogo())
  }, [])
  useEffect(() => {

  }, [sessionStorage.logo])
  let image = useSelector(state => {
    localStorage.setItem('logo', state.logo.logo)
    return state.logo.logo
  })
  useEffect(() => {
    console.log('image logo')
    setLogoImg(localStorage.logo);

    setTimeout(()=>{
    },5000)
  }, [image])
  const responseGoogle = (response) => {
    var decoded = jwt_decode(response.credential);

    console.log(decoded)
    const token = {
      tokenId: response.credential,
      id: decoded.sub,
      email: decoded.email

    }
    console.log("uhdasgfdfjesgwfwef", token)
    logToGoogle(token)

  }

  const responseFacebook = (response) => {
    console.log(response);
  }
  const componentClicked = (response) => {
    console.log(response);
  }

  const [passwordtype, setPasswordtype] = useState('password')
  const [eye, eyeClose] = useState(true)

  const toggle = () => {
    if (passwordtype == 'password') {
      setPasswordtype("text")
      eyeClose(false)
    }
    else {
      setPasswordtype("password")
      eyeClose(true)
    }
  }
  function errorMsgww(err) {
    console.log(err)
  }
  return (
    <div className="login theam-color">
      <div className="row">
        <div className="col-md-6 login-left">
          <img className="w-100" src={sideImage} />
        </div>
        <div className="col-md-6 login-right pad-0">
          {logoImg && (
            <img
              src={logoImg}
              alt="logo"
              height="150px"
              style={{ margin: "10px auto", display: "block" }}
            />
          )}
          <h3 className="register-heading mb-4">Login</h3>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <Formik
                initialValues={loginData}
                validationSchema={loginValidation}
                validateOnMount
                enableReinitialize
              >
                {(formik) => {
                  const { handleSubmit } = formik;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="col-md-12">
                        <FormikControl
                          control="input"
                          type="text"
                          name="email"
                          placeholder="Email Address"
                        />
                      </div>
                      <div className="col-md-12 password">
                        <FormikControl
                          control="input"
                          type={passwordtype}
                          name="password"
                          placeholder="Password"
                          id="password-feild"
                          className="password_field w-100"
                        />
                          {passErrorMsg?<span className="text-danger errorMsg">*Please Enter Correct Password or Email</span>:''}

                        <span id="eye_icon">
                          <i
                            onClick={toggle}
                            className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                            style={{ zIndex: 1 }}
                          ></i>{" "}
                        </span>
                      </div>

                      <div className="loginError">{errorMsg}</div>

                      <div className="text-center col-md-12 pt-1">
                        <button
                          type="submit"
                          className="btn btn-sm  theam-color mt-2"
                          // method='post'
                          onClick={() => handleSubmitLogin(formik)}
                          style={{ backgroundColor: "#4A1D75", color: "white" }}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="col-md-12 exists-acc">
              <p>
                Don't have an account?{" "}
                <strong>
                  <Link
                    to="#"
                    onClick={redirectRegister}
                    style={{ color: "#4A1D75" }}
                  >
                    Register Here
                  </Link>
                </strong>
              </p>
            </div>
            <div className="col-md-12 exists-acc" style={{ color: "#4A1D75" }}>
              <p>
                <Link to="/verification" style={{ color: "#4A1D75" }}>
                  Forgot Password.
                </Link>
              </p>
            </div>
            <div className="col-md-12 exists-acc">
              {/* <GoogleLogin
                clientId="450497098981-fujdo5urf68rv2q75kb2cq8ttnci7clt.apps.googleusercontent.com"
                buttonText="Login with Google"
                onSuccess={responseGoogle}
                onFailure={errorMsgww}
                cookiePolicy={"single_host_origin"}
                scope="email"
              /> */}
              <div className="d-flex justify-content-center">
                <div>

                  <GoogleOAuthProvider
                    clientId="450497098981-fujdo5urf68rv2q75kb2cq8ttnci7clt.apps.googleusercontent.com"
                  >
                    <GoogleLogin
                      buttonText="Login with Google"
                      onSuccess={responseGoogle}
                      onFailure={(e) => {
                        console.log(e)
                      }}
                      cookiePolicy={"single_host_origin"}
                      scope="email"
                    />
                  </GoogleOAuthProvider>
                </div>
              </div>
            </div>
            {/* <div className="col-md-12 exists-acc">
              <FacebookLogin
                appId="351718189849173"
                autoLoad={false}
                textButton="Login with Facebook  "
                fields="name,email,picture"
                onClick={componentClicked}
                callback={responseFacebook}
                size="small"
                icon="fa-facebook-f"
              />,
            </div> */}

            {/* <div id="google_translate_element"></div> */}
          </div>

          {/* <hr />

          <div className="lw lwg">
            <img src={googleImg} />
            <span>Login with Google</span>
          </div>

          <div className="lw lwg">
            <img src={facebookImg} />
            <span>Login with Facebook</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;