import React, { useEffect, useRef, useState } from "react";
import style from "./Document.module.css";
import { Label, Modal, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { getCurriculumList } from "../../../services/Admin/commonService";
import { userID } from "../../../services/Authentication/AuthService";
import { getUserCurriculum } from "../../../services/Student/CurriculumStudentService";
import { userInfo } from "../../../services/Authentication/AuthService";
import { addUploadFileData } from "../../../services/Authentication/AuthService";
import axios from "axios";

const Document = (props) => {
  const Documentdata = useRef({
    curriculumId: "",
    fileName: "",
    // fileUrl: "",
    country: "",
    state: "",
    city: "",
    area: "",
    nearby: "",
    buildingNo: "",
    floor: "",
    company: "",
    designation: "",
    startDate: "",
    endDate: "",
    status: "",
    description: "",
    documentType: "",
  });
  let [country, setcountry] = useState([]);
  let [state, setstate] = useState([]);
  let [city, setcity] = useState([]);
  let [countrycode, setcountrycode] = useState("");
  let [Statedis, setStatedis] = useState(false);
  let [Citydis, setCitydis] = useState(false);
  let [dataaa, setDataa] = useState();
  let [stateData, setStateData] = useState();
  let [userCurriculumSData, setUserCurriculumSData] = useState();
  let [userInFoMatchData, setUserInfoMatchdata] = useState("");
  const [selectedCurriculumId, setSelectedCurriculumId] = useState(null);
  const [storeFullRes, setStoreFullRes] = useState();

  const getUserCurriculums = () => {
    getUserCurriculum(userID()).then((res) => {
      setUserCurriculumSData(res?.data?.data);
      let cur = res?.data?.data?.map((e) => e.curriculum);
      sessionStorage.setItem("currculum", cur);
    });
  };

  const userInFoMatch = async () => {
    try {
      const res = await userInfo();
      setUserInfoMatchdata(res);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  // const handleAreaChange = (e) => {
  //   const { name, value } = e.target;
  //   Documentdata.current[name] = value;
  // };

  console.log(userCurriculumSData, "hhhhhhhhhhhhhhhh");
  console.log(userInFoMatchData, "hhhhhhhhhhhhhhhh");

  const addDocumentdata = async (res, e, resData) => {
    e.preventDefault();
    setSelectedCurriculumId(res._id);

    const formData = new FormData();
    formData.append("curriculumId", res._id);
    formData.append("fileName", Documentdata.current.fileName);

    formData.append("country", Documentdata.current.country);
    formData.append("state", Documentdata.current.state);
    formData.append("city", Documentdata.current.city);
    formData.append("area", Documentdata.current.area);
    formData.append("nearby", Documentdata.current.nearby);
    formData.append("buildingNo", Documentdata.current.buildingNo);
    formData.append("floor", Documentdata.current.floor);
    formData.append("company", Documentdata.current.company);
    formData.append("designation", Documentdata.current.designation);
    formData.append("startDate", Documentdata.current.startDate);
    formData.append("endDate", Documentdata.current.endDate);
    formData.append("status", Documentdata.current.status);
    formData.append("description", Documentdata.current.description);

    try {
      const response = await addUploadFileData(res._id, formData);
      console.log("Add Document Response:", response.data);
      setOpen(false);
    } catch (error) {
      console.error("Error adding document:", error);
    }

    console.log(Documentdata, "uijjjjj");
  };

  const curriculumLists = async () => {
    const data = await getCurriculumList().then((e) => {
      return e?.data?.data;
    });
    setCurriculum(data);
  };

  const history = useHistory();
  const [curriculum, setCurriculum] = useState([]);

  console.log(Country.getAllCountries());
  console.log(State.getAllStates());
  console.log(City.getAllCities());

  function countrychange(e) {
    setStatedis(true);
    setcountrycode(e.split(",")[0]);
    setDataa(e.split(",")[1]);
    Documentdata.current.country = e.split(",")[1]; // Update this line
    setstate(State.getStatesOfCountry(e.split(",")[0]));
  }

  function statechange(e) {
    setCitydis(true);
    setStateData(e.split(",")[1]);
    Documentdata.current.state = e.split(",")[1]; // Update this line
    setcity(City.getCitiesOfState(countrycode, e.split(",")[0]));
  }

  const handleAreaChange = (e) => {
    const { name, value } = e.target;
    Documentdata.current = {
      ...Documentdata.current,
      [name]: value,
    };
    console.log("Documentdata:", Documentdata.current);
  };

  const handleDateChange = (e, name) => {
    Documentdata.current[name] = e.target.value;
  };

  const handleStatusChange = (e) => {
    Documentdata.current["status"] = e.target.value;
  };
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   Documentdata.current.fileUrl = file;
  // };

  useEffect(() => {
    setcountry(Country.getAllCountries);
    getUserCurriculums();
    curriculumLists();
    userInFoMatch();
  }, []);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <>
      <Modal
        isOpen={open}
        toggle={() => {
          setOpen(!open);
        }}
        size="lg"
      >
        <ModalBody>
          <form
            onSubmit={(e) =>
              addDocumentdata(selectedCurriculumId, e, storeFullRes)
            }
          >
            <div className="d-flex justify-content-between">
              <div className="px-3 mt-2">
                <h3>Document Upload</h3>
              </div>
              <div>
                {" "}
                <button
                  onClick={() => history.goBack()}
                  className="btn btn-sm  mt-4"
                  style={{
                    borderRadius: "40px",
                    backgroundColor: "#4A1D75",
                    border: "2px solid #4A1D75",
                    width: "140px",
                    textAlign: "center",
                    padding: "6px",
                    color: "white",
                    height: "auto",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-md-4">
                <label htmlFor="inputname">Country Name</label>
                <select
                  className="form-select"
                  placeholder=""
                  onChange={(e) => countrychange(e.target.value)}
                >
                  <option selected>Select country</option>
                  {country &&
                    country.map((e, index) => {
                      return (
                        <option key={index} value={`${e.isoCode},${e.name}`}>
                          {e.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="inputname">State Name</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => statechange(e.target.value)}
                >
                  <option selected>Select state</option>
                  {state &&
                    state.map((e, index) => {
                      return (
                        <option key={index} value={`${e.isoCode},${e.name}`}>
                          {e.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">City Name</label>
                <select
                  className="form-select"
                  onChange={(e) => handleAreaChange(e)}
                  name="city"
                >
                  <option selected>Select city</option>
                  {city &&
                    city.map((e, index) => {
                      return (
                        <option key={index} value={e.name}>
                          {e.name}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Area</label>
                <input
                  className="form-control"
                  placeholder="Area"
                  onChange={(e) => handleAreaChange(e)}
                  name="area"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Nearby</label>
                <input
                  className="form-control"
                  placeholder="Nearby"
                  onChange={(e) => handleAreaChange(e)}
                  name="nearby"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Building no</label>
                <input
                  className="form-control"
                  placeholder="Building no"
                  onChange={(e) => handleAreaChange(e)}
                  name="buildingNo"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Floor</label>
                <input
                  className="form-control"
                  placeholder="Floor"
                  onChange={(e) => handleAreaChange(e)}
                  name="floor"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Company</label>
                <input
                  className="form-control"
                  placeholder="Enter company name"
                  onChange={(e) => handleAreaChange(e)}
                  name="company"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Designation</label>
                <input
                  className="form-control"
                  onChange={(e) => handleAreaChange(e)}
                  name="designation"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => handleDateChange(e, "startDate")}
                  name="startDate"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => handleDateChange(e, "endDate")}
                  name="endDate"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Status</label>
                <select
                  className="form-select"
                  name="status"
                  onChange={(e) => handleStatusChange(e)}
                >
                  <option selected>Complete</option>
                  <option selected>Pursuing</option>
                </select>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Description</label>
                <input
                  className="form-control"
                  placeholder="Enter description"
                  onChange={(e) => handleAreaChange(e)}
                  name="description"
                ></input>
              </div>
              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Document Type</label>
                <input
                  className="form-control"
                  name="documentType"
                  value={storeFullRes?.experience}
                  readOnly
                />
              </div>

              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Choose File</label>
                <input
                  type="file"
                  className="form-control"
                  name="fileUrl"
                  // onChange={(e) => handleFileChange(e)}
                />
              </div>

              <div className="col-md-4 mt-3">
                <label htmlFor="inputname">Enter File Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter file name"
                  name="fileName"
                  onChange={(e) => handleAreaChange(e)}
                ></input>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-sm mt-4"
                  style={{
                    borderRadius: "40px",
                    backgroundColor: "#4A1D75",
                    border: "2px solid #4A1D75",
                    width: "140px",
                    textAlign: "center",
                    padding: "6px",
                    color: "white",
                    height: "auto",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <div className="row">
        <div className={`col-md-12 ${style.dashimg} mb-2 `}></div>
      </div>
      <div className={`row ${style.documentRow} mt-5 `}>
        <div className="col-2"></div>

        <div className="  col-8 border border-dark mb-3 rounded">
          <h4 className=" py-3  ">Curriculum Name </h4>
          <div>
            <div>
              <div className="d-flex flex-column mb-4 mx-1 ">
                {userCurriculumSData &&
                  userCurriculumSData.map((res) => {
                    const dd = userInFoMatchData?.paymentDeatils?.filter(
                      (ress) => res._id === ress.curriculumID
                    );

                    const hasDocumentData = dd?.some(
                      (item) => item.documentData
                    );
                    console.log(hasDocumentData, "jjjjjjjjjjj");
                    return (
                      <div
                        key={res._id}
                        className="d-flex justify-content-between mb-4"
                        style={{ borderBottom: "1px solid #f3e8e8" }}
                      >
                        <span className={style.curriculumName}>
                          {res?.curriculum}
                        </span>
                        <div className="d-flex justify-content-between mb-4">
                          {hasDocumentData ? (
                            <button
                              className={`${style.tabItem} px-4`}
                              style={{ visibility: "hidden" }}
                            >
                              <span className="px-2"> Add</span>{" "}
                            </button>
                          ) : (
                            <button
                              className={`${style.tabItem} px-4`}
                              onClick={() => {
                                setSelectedCurriculumId(res);
                                setStoreFullRes(res);
                                setOpen(true);
                              }}
                            >
                              <span className="px-2"> Add</span>
                            </button>
                          )}
                          <button
                            className={`${style.tabItem} px-4`}
                            onClick={() => {
                              setOpenEdit(true);
                            }}
                          >
                            <span className="px-2"> Edit</span>{" "}
                          </button>
                          <button
                            className={`${style.tabItem} px-4`}
                            onClick={() => {
                              setOpenEdit(true);
                            }}
                          >
                            <span className="px-2">view</span>{" "}
                          </button>
                          <button className={`${style.tabItem} px-4`}>
                            <span className="px-2"> Delete</span>{" "}
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );
};

export default Document;
