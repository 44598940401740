import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Assignments.module.css";
import Countdown from 'react-countdown';
import { CircularProgress } from "@material-ui/core";

const AssignmentResult = ({ assignmentIndividual, getTime, showResult }) => {

  const data = assignmentIndividual;
  console.log(assignmentIndividual)

  // useEffect(() => {
  //   timerr()

  // }, [data])
  const height = window.innerHeight - 155;
  const [showMss, setShowMss] = useState(true)
  // const [timer, setTimer] = useState(null)
  if (!data) {
    return false;
  }

  const Completionist = () => {
    setShowMss(false)
    return <span>Start the Assessment now!</span>
  };


  const getTestTime = () => {
    let now = getTime()
    let h, m, event

    if (data.startdate)
      event = new Date()
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let da = new Date(event.toISOString())
    // let enGB = (da.toLocaleDateString('en-GB', options))
    console.log(da.getTime());
    // let time = moment(data.startdate).format("DD/MM/YYYY hh:mm")
    h = data.startdate.split('T')[1].split(':')[0]
    m = data.startdate.split('T')[1].split(':')[1]
    // console.log('hhm', data.startdate);
    if (h < now.hours && m < now.min) {
      return true

    } else {
      return false
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12 ">
          <div className="row pt-3">
            <div className="col-md-4"></div>
            <div className="col-md-4"><h2 className="text-center">Assessment Details</h2></div>
            <div className="col-md-4 text-end"></div>
          </div>

          <div className="w-100 pt-3">
         <div className={`${styles.test}`}>
          <div className="w-100 text-end pb-3">
          <Link to={`/student/assignments`}>
              <button
                className={`btn btn-secondary theam-color ${styles.testBack1}`}
              >
                Back
              </button>
            </Link>
          </div>
         <div
            className={`${styles.assignmentPreviewDiv} py-5`}
            
          >
            <div className="col-md-8 py-2" style={{}}>
              <div>

                <table class="table" >
                  <tr className="">
                    <th>Assessment Name</th>
                    <td>{data.assessmentName ? data.assessmentName : ""}</td>
                  </tr>
                  <tr>
                    <th>Curriculum Name</th>
                    <td>{data.curriculum}</td>
                  </tr>
                  <tr>
                    <th>Subject Name</th>
                    <td>{data.subject ? data.subject : ""}</td>
                  </tr>
                  <tr>
                    <th>
                      Start Date &nbsp;<small>(DD/MM/YYYY)</small>
                    </th>
                    <td>{moment(data.startdate).format("DD/MM/YYYY")}</td>
                  </tr>

                  <tr>
                    <th>Total Marks</th>
                    <td>
                      {showResult.status
                        ? showResult.totalMarks
                        : data.maxWeightage}
                    </td>
                  </tr>
                  {showResult.status === "Accept" && (
                    <>
                      {" "}
                      <tr>
                        <th>AchievedMarks Marks</th>
                        <td>{showResult.achievedMarks}</td>
                      </tr>
                      <tr>
                        <th>Correct Answered</th>
                        <td>{showResult.correctAnsweredQues}</td>
                      </tr>
                      <tr>
                        <th>Wrong Answered</th>
                        <td>{showResult.wrongAnsweredQues}</td>
                      </tr>
                    </>
                  )}
                  {showResult.status === "Reject" && (
                    <>
                      <p style={{ padding: "10px" }}>
                        Your Assessment Was Rejected by teacher
                      </p>
                    </>
                  )}
                </table>
                  {showResult.status === undefined && (
                    <>
                      <p className="" style={{ padding: "10px 0" }}>
                       <b>Note:-</b> Teacher needs to correct. Marks will be calculated
                        after teacher correction
                      </p>
                    </>
                  )}
                <div
                  className={` col-md-11 `}
                  style={{ display: "flex", justifyContent: "center" }}
                >

                </div>
              </div>
            </div>
          </div>
         </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentResult;
