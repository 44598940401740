import "./Notification.css"
import { Link } from "react-router-dom"
import { updateByIdNews, getByIdNews } from "../../../services/Admin/commonService"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useEffect, useState } from "react"
import { userID } from "../../../services/Authentication/AuthService"
 import LoadingModal from "../../Share/LoadingModal"
export const UpdateNotification = () => {
    const { id } = useParams()
    const history = useHistory()
    const [newsData, setNewsData] = useState()
    const [loader,setLoader]= useState(false)
    const [url, setUrl] = useState()
    const [message, setMessage] = useState()
    const getNews = () => {
      setLoader(true)
      getByIdNews(id).then(res =>{setNewsData(res.data.data);setLoader(false)} )}
    const updateNews = async () => {
        setLoader(true)

        const trimmedMessage = message?.trim();
        const trimmedUrl = url?.trim();
    

        let payload = {
            "sender_id": userID(),
            "path": trimmedUrl,
            "message": trimmedMessage
        }
        console.log(payload)
        await updateByIdNews(newsData._id, payload).then(res => { 
            setLoader(false)
            history.push("/admin/notification-listing") 
        })
    }
    useEffect(() => {
        getNews()
    }, [])
    return (
      <div className="Addnotification_content">
        <LoadingModal visible={loader} />
        <div className="notification-head">
          <h1 style={{ fontFamily: "roboto" }}>
            Edit News and Upcoming Events
          </h1>
          <div className="cancel_btn">
            <Link to="/admin/notification-listing">
              <button id="cancel_btn">Cancel</button>
            </Link>
          </div>
        </div>
        <div className="notification_container">
          <div className="notification_inputs mx-auto">
            <p style={{ fontFamily: "roboto" }}>Notification Heading :</p>
            <textarea
              className="w-100 p-2"
              defaultValue={newsData ? newsData.message : ""}
              onChange={(e) => setMessage(e.target.value)}
              rows="4"
              style={{ fontFamily: "roboto" }}
            ></textarea>
            <br />
            <br />
            <p style={{ fontFamily: "roboto" }}>Notification Url :</p>
            <textarea
              defaultValue={newsData ? newsData.path : ""}
              className="w-100 p-2"
              onChange={(e) => setUrl(e.target.value)}
              rows="4"
              style={{ fontFamily: "roboto" }}
            ></textarea>
            <br />
            <div className="submit_btn">
              <button id="submit_btn" onClick={() => updateNews()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}