import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./Sessions.module.css";
import { Button } from "@mui/material";
const SessionPreview = ({ sessionIndividual }) => {
  const data = sessionIndividual;
  const height = window.innerHeight - 100;
  function formatTimeShow(time) {
    const time_part_array = time.split(":");
    let ampm = 'AM';
    if (time_part_array[0] >= 12) {
      ampm = 'PM';
    }
    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }
    const formatted_time = time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm;
    return formatted_time;
  }
  function datess(data) {
    let startindex = moment(data).format().indexOf('+')
    const date1 = new Date(moment(data).format().slice(0, startindex));
    const date2 = new Date();
    if (date2.getTime() >= date1.getTime()) {
      return true
    } else {
      return false
    }
  }
  return (
    <div className="row mt-4" >
      <div className="col-md-12">
        <div className="row ">
          <div className="col-md-4 col-sm-4"></div>
          <div className="col-md-4 col-sm-4 text-center"> <div className="fs-3"><b>Session Details</b></div></div>
          <div className="col-md-4 col-sm-4 text-center pt-2">
            <Link to={`/student/sessions`}>
              <button
                className={`btn btn-secondary mr-0 ${styles.testBack}`}
                style={{ fontFamily: "roboto" }}
              >
                Back
              </button>
            </Link>
          </div>
        </div>

      </div>
      <div className="col-md-12 mt-4">
        <div className={`${styles.sessionPreviewDiv}`}>
          <div className="col-md-6 py-5">

            <table className="table">
              <tr>
                <th style={{ fontFamily: "roboto" }}>Curriculum :</th>
                <td style={{ fontFamily: "roboto" }}>{data.curriculum}</td>
              </tr>
              <tr>
                <th style={{ fontFamily: "roboto" }}>Subject :</th>
                <td style={{ fontFamily: "roboto" }}>{data.subject}</td>
              </tr>
              {/* <tr>
              <th>Topic</th>
              <td>{data.topic}</td>
            </tr> 
             <tr>
              <th>Chapter</th>
              <td>{data.chapter}</td>
            </tr>  */}
              <tr>
                <th style={{ backgroundColor: "none", fontFamily: "roboto" }}>
                  Start Date &amp; Time :
                </th>
                <td style={{ fontFamily: "roboto" }}>
                  {/* {moment
                  .parseZone(data.start)
                  .local()
                  .format("DD/MM/YYYY HH:mm")} */}
                  {/* {console.log(data.start.split(" ")[1].trim())} */}
                  {data.start
                    ? data.start.split(" ")[0] +
                    " " +
                    formatTimeShow(data.start.split(" ")[1])
                    : ""}
                </td>
              </tr>
              <tr>
                <th style={{ fontFamily: "roboto" }}>End Date &amp; Time :</th>
                <td style={{ fontFamily: "roboto" }}>
                  {/* {moment.parseZone(data.end).local().format("DD/MM/YYYY HH:mm")} */}
                  {data.end
                    ? data.end.split(" ")[0] +
                    " " +
                    formatTimeShow(data.end.split(" ")[1])
                    : ""}
                </td>
              </tr>
              <tr>
                <th style={{ fontFamily: "roboto" }}>Session Mode : </th>
                <td style={{ fontFamily: "roboto" }}>
                  {data.sessionMode ? data.sessionMode.mode : ""}
                </td>
              </tr>
              {data.length !== 0 && (data.sessionMode.mode === "Offine Session" || data.sessionMode.mode === "Offine + Online Session") && (
                <>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}>Building No. : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.houseNo : ""}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}>Area: </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.area : ""}
                    </td>
                  </tr> <tr>
                    <th style={{ fontFamily: "roboto" }}> Near By : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.nearBy : ""}
                    </td>
                  </tr> <tr>
                    <th style={{ fontFamily: "roboto" }}>University : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.university : ""}
                    </td>
                  </tr> <tr>
                    <th style={{ fontFamily: "roboto" }}>Campus : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.branch : ""}
                    </td>
                  </tr> <tr>
                    <th style={{ fontFamily: "roboto" }}> Room No. : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.room_no : ""}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}>Floor : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.floor : ""}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}> Block : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.block : ""}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}> Country : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.country : ""}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}> State : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.state : ""}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ fontFamily: "roboto" }}> City : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.address ? data.sessionMode.address.city : ""}
                    </td>
                  </tr>

                  <tr>
                    <th style={{ fontFamily: "roboto" }}>Facilities : </th>
                    <td style={{ fontFamily: "roboto" }}>
                      {data.sessionMode.facilities
                        ? data.sessionMode.facilities.join(", ")
                        : ""}
                    </td>
                  </tr>
                  {data.sessionMode.mode === "Offine + Online Session" && (
                    <>
                      {datess(data.start) ? (
                        <>
                          <Button
                            variant="contained"
                            onClick={() => {
                              window.open(data.meetingLink);
                            }}
                            style={{ fontFamily: "roboto" }}
                          >
                            JOIN
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            disabled
                            style={{ fontFamily: "roboto" }}
                          >
                            Session is not start yet
                          </Button>
                        </>
                      )}
                    </>)}
                </>
              )}
            </table>
            {console.log(data, "dataa")}
            {data.length !== 0 && data.sessionMode.mode === "Online Session" && (
              <>
                {datess(data.start) ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.open(data.meetingLink);
                      }}
                      style={{ fontFamily: "roboto" }}
                    >
                      JOIN
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      disabled
                      style={{ fontFamily: "roboto" }}
                    >
                      Session is not start yet
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionPreview;
