import React, { useState, useEffect, Fragment, useRef } from "react";
import { Formik, Form } from "formik";
import { staffValidation } from "../../../validations/AdminValidations";
import FormikControl from "../../../common/Formik/FormikControl";
import { curriculumListFun, subjectListFun } from "../../../utils/utils";
import Autocomplete from "react-autocomplete";
import Autocomplete1 from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const EditStudent = (props) => {
  const {
    mode,
    handlestudentAdd,
    studentValues,
    curriculumList,
    handleCurriculum,
    handleInput,
    editError,
    handleInput1,
    // isLoading,
  } = props;
  console.log(studentValues)
  let [country, setcountry] = useState([]);
  let [state, setstate] = useState([]);
  let [city, setcity] = useState([]);
  let [countrycode, setcountrycode] = useState("");
  let [Statedis, setStatedis] = useState(false);
  let [Citydis, setCitydis] = useState(false);
  let [dataaa, setDataa] = useState();
  let [stateData, setStateData] = useState()
  let [loader,setLoader]=useState(true);
  let [cityData, setCityData] = useState()
  let dd = new Date()
  let todayDate = moment(dd).format('YYYY-MM-DD')
  const studentData = useRef({})
  // console.log(params.id)
  const handelSubmit = (e) => {
    e.preventDefault()
    if(studentData.current.country.split(',').length == 2){
      studentData.current.country = studentData.current.country.split(',')[1]
    }

    if(studentData.current.state.split(',').length == 2){
      studentData.current.state = studentData.current.state.split(',')[1]
    }

    let date = new Date(studentData.current.dob)
    studentData.current.dob = date
    handlestudentAdd(studentData.current)
  };

  function countrychange(e) {
    setStatedis(true);
    setcountrycode(e.split(",")[0]);
    studentData.current.country = e
    studentData.current.state = ''
    studentData.current.city = ''
    setDataa(e.split(",")[1])
    setstate([...State.getStatesOfCountry(e.split(",")[0])]);
  }

  function statechange(e) {
    setCitydis(true);
    setStateData(e.split(",")[1]);
    studentData.current.state = e
    studentData.current.city = ''
    setcity([...City.getCitiesOfState(countrycode, e.split(",")[0])]);
  }

  function citychange(e) {
    setCityData(e)
    studentData.current.city = e
  }

  useEffect(() => {

    if(studentValues.firstName!==undefined){
      setLoader(false);
    }
  

    setcountry([...Country.getAllCountries()]);
    studentData.current = studentValues
    let coun = country.filter((res)=>res.name == studentData.current.country)
    setcountrycode(coun[0]?.isoCode)
    studentData.current.country = `${coun[0]?.isoCode},${coun[0]?.name}`
    let arr = [...State.getStatesOfCountry(studentData?.current?.country.split(",")[0])]
    setstate([...arr]);
     ss(arr,coun[0]?.isoCode)

     let dd = moment(studentData.current?.dob).format("YYYY-MM-DD");
     studentData.current.dob = dd
     console.log(studentData.current)
  }, [studentValues,Country,State,City]);

  function ss(e,c){
    let sta = e.filter(res=>res.name == studentData.current.state)
    studentData.current.state = `${sta[0]?.isoCode},${sta[0]?.name}`
    let arr = [...City.getCitiesOfState(c, studentData?.current?.state.split(",")[0])]
    setcity([...arr]);
  }

  
  const [n1, setn1] = useState();
  
  const history = useHistory();
  let curriculumOptions = curriculumListFun(curriculumList);
  const handleChange1 = (event, value) => {
    console.log(value.curriculum);
    handleInput1(value.curriculum, "curriculum", "");
    setn1(value);
    console.log(event);
  };

  return (
    <Fragment>
      {!loader ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              {/* <div className="d-flex flex-row" style={{marginTop:"10px"}}> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  width: "99%",
                  alignItems: "center",
                }}
              >
                <div>
                  <h1
                    className="form-heading"
                    style={{
                      textTransform: "capitalize",
                      color: "#4A1D75",
                      fontFamily: "roboto",
                    }}
                  >
                    {mode} Student
                  </h1>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger mb-4"
                    onClick={() => history.goBack()}
                    style={{
                      borderRadius: "40px",
                      backgroundColor: "#4A1D75",
                      border: "2px solid #4A1D75",
                      width: "140px",
                      textAlign: "center",
                      padding: "6px",
                      color: "white",
                      height: "auto",
                      fontFamily: "roboto",
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
              {/* </div> */}
              <div className="card mb-5">
                <div className="card-body">
                  <form onSubmit={handelSubmit}>
                    <div className="row pb-3">
                      <div className="col-md-4 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="First Name"
                          required
                          defaultValue={studentData?.current?.firstName}
                          onChange={(e) =>
                            (studentData.current.firstName = e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">Middle name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Middle name"
                          defaultValue={studentData?.current?.middleName}
                          onChange={(e) =>
                            (studentData.current.middleName = e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Last Name"
                          required
                          defaultValue={studentData?.current?.lastName}
                          onChange={(e) =>
                            (studentData.current.lastName = e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">Mobile Number</label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Mobile Number"
                          required
                          defaultValue={studentData?.current?.mobile}
                          onChange={(e) =>
                            (studentData.current.mobile = e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">Email</label>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          required
                          defaultValue={studentData?.current?.email}
                          onChange={(e) =>
                            (studentData.current.email = e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">Gender</label>
                        <select
                          className="select-box"
                          required
                          // options={['Male','Female','Other']}
                          defaultValue={studentData?.current?.gender}
                          onChange={(e) =>
                            (studentData.current.gender = e.target.value)
                          }
                        >
                          <option selected disabled>
                            Select Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">DOB</label>
                        <input
                          className="form-control"
                          type="date"
                          placeholder="DOB"
                          required
                          max={todayDate}
                          defaultValue={studentData?.current?.dob}
                          onChange={(e) =>
                            (studentData.current.dob = e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="row pb-3">
                      <div className="col-md-4 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">
                          Parent/Gaurdian First Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Parent/Gaurdian First Name"
                          defaultValue={
                            studentData?.current?.parentOrGardien
                              ?.parentFirstName
                          }
                          onChange={(e) =>
                            (studentData.current.parentOrGardien.parentFirstName =
                              e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">
                          Parent/Gaurdian Middle Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Parent/Gaurdian Middle Name"
                          // name="parentMiddleName"
                          defaultValue={
                            studentData?.current?.parentOrGardien
                              ?.parentMiddleName
                          }
                          onChange={(e) =>
                            (studentData.current.parentOrGardien.parentMiddleName =
                              e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-4 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">
                          Parent/Gaurdian Last Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Parent/Gaurdian Last Name"
                          defaultValue={
                            studentData?.current?.parentOrGardien
                              ?.parentLastName
                          }
                          onChange={(e) =>
                            (studentData.current.parentOrGardien.parentLastName =
                              e.target.value)
                          }
                        />
                      </div>

                      <div className="col-md-6 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">
                          Parent/Gaurdian Mobile
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Parent/Gaurdian Mobile"
                          defaultValue={
                            studentData?.current?.parentOrGardien?.parentMobile
                          }
                          onChange={(e) =>
                            (studentData.current.parentOrGardien.parentMobile =
                              e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 col-12 pt-3">
                        <label className="text-gray pb-1">
                          Parent/Gaurdian Email
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Parent/Gaurdian Email"
                          defaultValue={
                            studentData?.current?.parentOrGardien?.parentEmail
                          }
                          onChange={(e) =>
                            (studentData.current.parentOrGardien.parentEmail =
                              e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 col-12 pt-3">
                        <label className="text-gray pb-1">
                          Parent/Gaurdian Address
                        </label>
                        <textarea
                          className="form-control"
                          defaultValue={
                            studentData?.current?.parentOrGardien?.parentAddress
                          }
                          onChange={(e) =>
                            (studentData.current.parentOrGardien.parentAddress =
                              e.target.value)
                          }
                          placeholder="Parent/Gaurdian Address"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-12 pt-3">
                        <label className="text-gray pb-1">Address</label>
                        <textarea
                          className="form-control"
                          required
                          onChange={(e) =>
                            (studentData.current.address = e.target.value)
                          }
                          defaultValue={studentData?.current?.address}
                          placeholder="Address"
                          rows="3"
                        ></textarea>
                      </div>
                      <div className="col-md-4 col-12 pt-3">
                        <label className="text-gray">Select Country</label>
                        <select
                          className="select-box"
                          required
                          value={studentData?.current?.country}
                          onChange={(e) => countrychange(e.target.value)}
                        >
                          <option disabled>---select---</option>
                          {country &&
                            country.map((e, index) => {
                              return (
                                <option
                                  key={index}
                                  value={`${e.isoCode},${e.name}`}
                                >
                                  {e.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>

                      <div className="col-md-4 col-12 pt-3">
                        <label className="text-gray">Select State</label>
                        <select
                          className="select-box"
                          required
                          aria-label="Default select example"
                          ariaPlaceholder="dlfahdkf"
                          value={studentData?.current?.state}
                          onChange={(e) => statechange(e.target.value)}
                        >
                          <option value="" disabled={Statedis}>
                            {" "}
                            Select State{" "}
                          </option>
                          {state &&
                            state.map((e, index) => {
                              return (
                                <option
                                  key={index}
                                  value={`${e.isoCode},${e.name}`}
                                >
                                  {e.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-md-4 col-12 pt-3">
                        <label className="text-gray">Select City</label>
                        <select
                          className="select-box"
                          required
                          onChange={(e) => citychange(e.target.value)}
                          value={studentData?.current?.city}
                        >
                          <option value="" disabled={Citydis}>
                            {" "}
                            Select City{" "}
                          </option>
                          {city &&
                            city.map((e, index) => {
                              return (
                                <option key={index} value={e.name}>
                                  {e.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12 pt-3">
                        <label className="text-gray">Pin Code</label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          placeholder="Pin Code"
                          defaultValue={studentData?.current?.pincode}
                          onChange={(e) =>
                            (studentData.current.pincode = e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="text-center col-md-12 col-sm-12 col-12 pt-5">
                      <p className="text-uppercase text-danger">{editError}</p>
                      <button
                        type="submit"
                        className="btn btn-sm mt-4"
                        style={{
                          borderRadius: "40px",
                          backgroundColor: "#4A1D75",
                          border: "2px solid #4A1D75",
                          width: "140px",
                          textAlign: "center",
                          padding: "6px",
                          color: "white",
                          height: "auto",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default EditStudent;
