import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getAddressById } from "../../../services/Admin/AddressService";

export default function ViewAddress(props) {
  const { open } = props;
  const history = useHistory();
  const [address, setAddress] = useState(null);
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  async function fetchAddress() {
    try {
      const response = await getAddressById(id);
      setAddress(response.data.data);
    } catch (error) {
      console.error("Error fetching address:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAddress();
  }, [id]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading} // Set `isLoading` to control the loader
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75", fontFamily: "roboto" }}>
            View Address
          </h3>

          <button
            className="btn btn-sm btn-secondary mr-2"
            onClick={() => history.goBack()}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>
        <div className="col-md-12">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.curriculum}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.subject}
                  </span>
                </div>

                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Country</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.country}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>State</label>
                  <span style={{ fontFamily: "roboto" }}>{address?.state}</span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>City Name</label>
                  <span style={{ fontFamily: "roboto" }}>{address?.city}</span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Campus </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.branch}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>University</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.university}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Block</label>
                  <span style={{ fontFamily: "roboto" }}>{address?.block}</span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Area</label>
                  <span style={{ fontFamily: "roboto" }}>{address?.area}</span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Near by</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.nearBy}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Building No</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.houseNo}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Floor</label>
                  <span style={{ fontFamily: "roboto" }}>{address?.floor}</span>
                </div>

                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Room No</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.room_no}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Pincode</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.pincode}
                  </span>
                </div>

                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Facilities</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {address?.facilities && address?.facilities.join(",")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
