import React, { useState, useEffect, Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import pic from "../../../assets/Images/certificate.jpg";
import sign from "../../../assets/Images/signature.png";
import { Link } from "react-router-dom";
import { width } from "@material-ui/system";
import BottomNavigation from "@mui/material/BottomNavigation";
import { getCurriculum } from "../../../services/Admin/curriculumService";
import { getPayLoad, userID } from "../../../services/Authentication/AuthService";
import { getCertify } from "../../../services/Student/AssignmentService";
import moment from "moment";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    width: "1700px",
    maxHeight: "410px",


    alignItems: "center",
    border: "2px solid #fff",
  },
  section_left: {
    // padding: 10,
    position: "absolute",
    // alignItems: 'center',
    left: "5%",
    // bottom: '100px',
    width: "50%",
    textAlign: "center",
    // backgroundColor: 'red',
  },
  section_right: {
    // padding: 10,
    position: "absolute",
    // alignItems: 'center',
    right: "5%",
    // bottom: '100px',
    width: "50%",
    textAlign: "center",
    // backgroundColor: 'red',
  },
  section_center: {
    // padding: 10,
    position: "absolute",
    // alignItems: 'center',
    right: "23%",
    // bottom: '100px',
    width: "50%",
    textAlign: "center",
    // backgroundColor: 'red',
  },
  image: {
    objectFit: "objectFit",
    height: "430px",
    width: "1820px",
  },
  head1: {
    fontSize: 20,
    marginTop: "30px",
  },
  head1_center: {
    fontSize: 20,
    marginTop: "30px",
  },
  head2: {
    fontSize: 18,
  },
  text1: {
    fontSize: 12,
    marginTop: "25px",
  },
  text2: {
    fontSize: 12,
    marginTop: "10px",
  },
  name: {
    marginTop: "5px",
    // width: '100px'

    textDecoration: "underline",
  },
  sign: {
    marginTop: "5px",
    marginRight: "10px",
    fontSize: 12,
    textAlign: "right",
  },
  imageSign: {
    height: "70px",
    width: "70px",
    marginTop: "20px",
    marginLeft: "220px",
    textDecoration: "underline",
  },
  logo: {
    height: "70px",
    width: "70px",
    marginTop: "30px",
    marginLeft: "110px",
    textDecoration: "underline",

  },
  logo_center: {
    height: "70px",
    width: "70px",
    marginTop: "30px",
    marginLeft: "80px",
    textDecoration: "underline",

  },
  Document: {
    border: "5px solid red",
    background: "white !important"
  }
});

const Certificate = (props) => {
  let name;
  const [marks, setMarks] = useState({
    achievedMarks: 0,
    totalMarks: 0
  })
  let logo = props.cur.imgLink
  if (props.userInfo.firstName) {
    name =
      props.userInfo.firstName.toUpperCase() +
      " " +
      props.userInfo.middleName.toUpperCase() +
      " " +
      props.userInfo.lastName.toUpperCase();
  }


  const getMarks = async () => {
    console.log(props)
    await getCertify({
      "curriculum": props.cur.curriculum,
      "curriculumCode": props.cur.curriculumCode,
      "studentId": userID()

    }).then(res => {
      console.log(res)
      setMarks(res.data.data)
    })
  }

  useEffect(() => {
    getMarks()
  }, [props.cur.curriculum])
  console.log(props)

  return (
    <Document style={styles.Document}>
      <Page size="A4" style={styles.page}>
        {props.cur.certificate_align === "center" && (<>

          <Image style={styles.image} src={props.cur.certificate_bg ? props.cur.certificate_bg.url : pic} />

          <View style={styles.section_center}>
            <Image style={styles.logo_center} src={{ uri: logo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
            <Text style={styles.head1_center}>CERTIFICATE OF COMPLETION</Text>

            <Text style={styles.text1}>
              THE CERTIFICATE IS PROUDLY PRESENTED  TO{" "}
            </Text>
            <Text style={styles.name}>{name}</Text>
            <Text style={styles.text1}>
              Has successfully completed the {props.cur.curriculum} on {moment(props.cur.endDate).format("Do MMMM YYYY")} , {props?.cur?.marksMode === "Percentage" ? `achieving a score of ${marks.totalMarks !== 0 ? Math.round((marks.achievedMarks / marks.totalMarks) * 100) : 0}%` : `with an achievement score of  ${marks.achievedMarks} out of ${marks.totalMarks}`}.
            </Text>
            <Image style={styles.imageSign} src={sign} />

            <Text style={styles.sign}>Signature</Text>
          </View>
        </>)}
        {props.cur.certificate_align === "left" && (<>

          <Image style={styles.image} src={props.cur.certificate_bg ? props.cur.certificate_bg.url : pic} />

          <View style={styles.section_left}>
            <Image style={styles.logo} src={{ uri: logo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
            <Text style={styles.head1}>CERTIFICATE OF COMPLETION</Text>

            <Text style={styles.text1}>
              THE CERTIFICATE IS PROUDLY PRESENTED  TO{" "}
            </Text>
            <Text style={styles.name}>{name}</Text>
            <Text style={styles.text1}>
              Has successfully completed the {props.cur.curriculum} on  {moment(props.cur.endDate).format("Do MMMM YYYY")} ,{props?.cur?.marksMode === "Percentage" ? `achieving a score of  ${marks.totalMarks !== 0 ? Math.round((marks.achievedMarks / marks.totalMarks) * 100) : 0}%` : `with an achievement score of  ${marks.achievedMarks} out of ${marks.totalMarks}`} .
            </Text>
            <Image style={styles.imageSign} src={sign} />

            <Text style={styles.sign}>Signature</Text>
          </View>
        </>)}
        {props.cur.certificate_align === "right" && (<>

          <Image style={styles.image} src={props.cur.certificate_bg ? props.cur.certificate_bg.url : pic} />

          <View style={styles.section_right}>
            <Image style={styles.logo} src={{ uri: logo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} />
            <Text style={styles.head1}>CERTIFICATE OF COMPLETION</Text>

            <Text style={styles.text1}>
              THE CERTIFICATE IS PROUDLY PRESENTED  TO{" "}
            </Text>
            <Text style={styles.name}>{name}</Text>
            <Text style={styles.text1}>
              Has successfully completed the {props.cur.curriculum} on  {moment(props.cur.endDate).format("Do MMMM YYYY")} , {props?.cur?.marksMode === "Percentage" ? `achieving a score of  ${marks.totalMarks !== 0 ? Math.round((marks.achievedMarks / marks.totalMarks) * 100) : 0}%` : `with an achievement score of ${marks.achievedMarks} out of ${marks.totalMarks}`}.
            </Text>
            <Image style={styles.imageSign} src={sign} />

            <Text style={styles.sign}>Signature</Text>
          </View>
        </>)}
      </Page>
    </Document>
  );
};

export default Certificate;
