import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getVideosId } from "../../../services/Admin/videoService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { deleteViewVideo } from "../../../services/Admin/videoService";
import { Backdrop, CircularProgress } from "@mui/material";

export default function ViewVideo(props) {
  const { videoData } = props;
  const history = useHistory();
  const [videoList, setVideoList] = useState();
  const [open, setOpen] = useState(true);
  const { id } = useParams();

  const videogetById = () => {
    setOpen(true);
    let payload = {
      _id: id,
    };
    getVideosId(payload, id)
      .then((res) => {
        console.log(res);
        setVideoList(res.data.data);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletevideos = () => {
    const payload = {
      videoID: videoList.videoID,
      fileName: videoList.fileName,
    };
    deleteViewVideo(payload)
      .then((res) => {
        console.log(res);

        history.push("/admin/video");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    videogetById();
  }, []);
  console.log(videoList);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row justify-content-center">
        <div className="closePage">
          <h3 style={{ color: "#4A1D75" }}>View Video</h3>
          <button
            className="btn btn-sm "
            onClick={() => {
              // deleteViewVideo({
              //   videoID: videoList.videoID,
              //   fileName: videoList.fileName,
              // });
              // history.push("/admin/video");
              deletevideos();
            }}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              // marginRight: "5px",
            }}
          >
            Delete
          </button>
          <button
            className="btn btn-sm mr-2"
            onClick={() => history.goBack()}
            style={{
              borderRadius: "40px",
              backgroundColor: "#4A1D75",
              border: "2px solid #4A1D75",
              width: "140px",
              textAlign: "center",
              padding: "6px",
              color: "white",
              height: "auto",
              marginRight: "5px",
            }}
          >
            Close
          </button>
        </div>
        <div className="col-md-12">
          <div className="card-deck">
            <div className="card">
              <div className="card-body">
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Video Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {videoList?.videoName}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Curriculum Name
                  </label>
                  <span style={{ fontFamily: "roboto" }}>
                    {videoList?.curriculum}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Subject Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {videoList?.subject}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Topic Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {videoList?.topic}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Chapter Name</label>
                  <span style={{ fontFamily: "roboto" }}>
                    {videoList?.chapter}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>Read File Name</label>
                  <span>
                    {videoList?.readArr.map((item) => {
                      return (
                        <>
                          <span style={{ fontFamily: "roboto" }}>
                            {item?.name}
                          </span>
                        </>
                      );
                    })}
                  </span>
                </div>
                <div className="text-view">
                  <label style={{ fontFamily: "roboto" }}>
                    Exercixe File Name
                  </label>
                  <span>
                    {videoList?.exercise.map((item) => {
                      return (
                        <>
                          <span style={{ fontFamily: "roboto" }}>
                            {item?.name}
                          </span>
                        </>
                      );
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
