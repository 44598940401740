import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormikControl from "../../../common/Formik/FormikControl";
import { scheduleValues } from "../../../constants/AdminInitialValues";
import { scheduleValidation } from "../../../validations/AdminValidations";
import moment from "moment";
import LoadingModal from "../../Share/LoadingModal";
import "../schedule.css";
import styles from "./calender.module.css";
import TextError from "../../../common/Formik/TextError";
import { userRole, userID } from "../../../services/Authentication/AuthService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { indexOf } from "lodash";
import {
  getAllCurriculumList,
  getAllSubjectList,
  getAllTopicsList,
} from "../../../components/Share/GetCurriculumDetails";
import { addNotify } from "../../../services/Admin/commonService";
import {
  getCurriculumCodeFun,
  curriculumListFun,
  subjectListFun,
  teacherListFun,
} from "../../../utils/utils";
import { getCreateAddress } from "../../../services/Admin/AddressService";
import { getAllTeachers, sessionInsert } from "../api";
import { ConstructionOutlined } from "@mui/icons-material/ConstructionOutlined";
import { Backdrop } from "@mui/material";

class CreateSession extends Component {
  state = {
    scheduleData: scheduleValues,
    curriculum: "",
    modalVisible: false,
    curriculumList: [],
    subjectsList: [],
    topics: [],
    teachersList: [],
    requestedTeacher: [],
    isLoading: false,
    sessionMode: "Abc",
    facilities: [],
    code: "",
    addressList: [],
    indexs: "",
    address: {},
    isLoading: false, // Add isLoading state
  };

  showvalue1 = function showval1(e) {
    e.target.checked
      ? this.state.facilities.push(e.target.value)
      : this.state.facilities.splice(
          indexOf(this.state.facilities[e.target.value], 1)
        );
    console.log(this.state.facilities);
  };

  constructor(props) {
    super(props);
    this.props.childRef(this);
    this.props.fetchEvent(this);
  }

  getData = (data) => {
    console.log(data);
  };

  handleClose = () => {
    console.log("false");
    this.setState({
      modalVisible: false,
      scheduleData: { curriculum: "", curriculumCode: "", subject: "" },
    });
  };

  handleShow = () => {
    this.setState({
      modalVisible: false,
      scheduleData: { curriculum: "", curriculumCode: "", subject: "" },
    });
    this.setState({ modalVisible: true });
  };

  handleInput = async ({ currentTarget: input }, name, values, subject) => {
    const data = this.state.scheduleData;
    const { options, selectedIndex } = input;
    let code;
    if (selectedIndex) {
      let optValue = options[selectedIndex].innerHTML;
      data[input.name] = optValue;
    } else {
      data[input.name] = input.value;
    }
    if (name === "curriculum") {
      data.subject = "";
      let curriculumCodeValue = getCurriculumCodeFun(
        this.state.curriculumList,
        input.value
      );
      console.log(curriculumCodeValue);
      values.curriculumCode = curriculumCodeValue;
      this.setState({ code: curriculumCodeValue });
      let subjectsList = await getAllSubjectList(curriculumCodeValue);
      this.setState({ subjectsList, curriculumCode: curriculumCodeValue });
    }

    if (name === "subject") {
      this.getAllTeachers(this.state.code, subject);
      values.teacher = "";
      let subjectList = await getAllTopicsList(
        values.curriculumCode,
        input.value
      );
      let subjectTopics = [];
      subjectList.map((s) => {
        return subjectTopics.push(s.topic);
      });
      this.setState({ topics: subjectTopics });
    }

    if (name === "teacher") {
      if (input.value === "all") {
        this.setState({ requestedTeacher: this.state.teachersList });
      } else {
        this.state.teachersList.map((t) => {
          if (t.email === input.value) {
            this.setState({ requestedTeacher: [t] });
          }
        });
      }
    }
  };

  getAddresslistingByCurr = async (subject) => {
    console.log(scheduleValues, "scheduleValues");
    const payload = {
      curriculum: this.state.curriculum,
      curriculumCode: this.state.code,
      subject: subject,
    };
    await getCreateAddress(payload).then((res) => {
      console.log(res.data.data);
      this.state.addressList = res.data.data;
    });
  };

  getAllTeachers = (curriculumCode, subject) => {
    const payload = {
      curriculumCode: curriculumCode,
      subject: subject,
    };

    getAllTeachers(payload)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ teachersList: res.data.data });
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  Offline(e) {
    console.log(e);
  }

  handleSubmit = (formik) => {
    // Set isLoading to true when the submit button is clicked
    // console.log(formik.values);
    // console.log(this.state.address);

    this.setState({ isLoading: true });
    this.setState({ modalVisible: false });

    const values = formik.values;
    const data_str = moment
      .parseZone(values.date)
      .local()
      .format("YYYY-MM-DD");
    const startTime_str = moment
      .parseZone(values.startTime)
      .local()
      .format("HH:mm");
    const endTime_str = moment
      .parseZone(values.endTime)
      .local()
      .format("HH:mm");
    const start_str = data_str + " " + startTime_str;
    const end_str = data_str + " " + endTime_str;

    let curriculumCodeValue = getCurriculumCodeFun(
      this.state.curriculumList,
      values.curriculum
    );
const trimessge = values.title.trim();
const trimdisc = values.description.trim();
    let payload = {
      title: trimessge,
      requestedTeacher: this.state.requestedTeacher,
      curriculum: values.curriculum,
      curriculumCode: curriculumCodeValue,
      subject: values.subject,
      topics: this.state.topics,
      desc:trimdisc,
      start: start_str,
      end: end_str,
      createDate: new Date(),
      createdBy: userID(),
      role: userRole(),
      sessionType: values.sessionType,
      sessionMode: {
        mode: this.state.sessionMode,
        address: this.state.address,
        facilities: this.state.facilities,
      },
    };
console.log(payload)
    if (formik.isValid) {
      sessionInsert(payload)
        .then((res) => {
          addNotify({
            curriculum: payload.curriculum,
            subject: payload.subject,
            sender_id: userID(),
            teacher: payload.requestedTeacher,
            message: `you are assigned with ${payload.subject} session for ${payload.curriculum} `,
            for_msg: "teacher",
            path: "/teacher/teacher-session",
            sendBy: userRole(),
            time: new Date(),
          });
          this.props.fetchEvent(userRole());
          this.state.address = {};
          this.state.addressList = [];
          // this.setState({ modalVisible: false });

          this.props.history.push("/admin/schedule");
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          // Set isLoading back to false after submission is complete
          this.setState({ isLoading: false });
        });
    }
    console.log(payload);
    this.setState({ isLoading: false });
    return false;
   
  };

  async componentDidMount() {
    let data = await getAllCurriculumList();
    this.setState({ curriculumList: data });
  }

  render(props) {
    const { modalVisible, isLoading } = this.state;
    let data = this.state.scheduleData;
    let dd = this.state.dd;
    let curriculumOptions = curriculumListFun(this.state.curriculumList);
    let subjectOptions = subjectListFun(this.state.subjectsList);
    let teacherOptions = teacherListFun(this.state.teachersList);

    return (
      <React.Fragment>
        <Modal size="lg" show={modalVisible} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title style={{ fontSize: "18px", fontFamily: "roboto" }}>
              Create session
            </Modal.Title>
            <span
              onClick={this.handleClose}
              style={{
               
                cursor: "pointer",
                padding: "2px 10px",
                background: "rgb(233, 236, 239)",
              }}
            >
              {" "}
              X
            </span>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={scheduleValues}
              validationSchema={scheduleValidation}
              validateOnMount
              enableReinitialize
            >
              {(formik) => {
                const { handleChange } = formik;
                return (
                  <Form>
                    <div className="row sessionForm">
                      <div className="col-md-12">
                        <FormikControl
                          control="input"
                          type="text"
                          name="title"
                          label="Title"
                        />
                      </div>

                      <div className="col-md-12">
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            label="Curriculum"
                            optname="curriculum"
                            name="curriculum"
                            placeholder="Select Curriculum"
                            options={curriculumOptions}
                            value={data.curriculum}
                            onChange={(e) => {
                              this.setState({ address: {}, facilities: [] });
                              console.log(e.target.value);
                              handleChange(e);
                              this.state.curriculum = e.target.value;
                              this.handleInput(
                                e,
                                "curriculum",
                                formik.values,
                                ""
                              );
                            }}
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            label="Subject"
                            optname="subject"
                            name="subject"
                            placeholder="Select Subject"
                            options={subjectOptions}
                            value={data.subject}
                            onChange={(e) => {
                              handleChange(e);
                              this.getAddresslistingByCurr(e.target.value);
                              this.setState({ address: {}, facilities: [] });

                              this.handleInput(
                                e,
                                "subject",
                                formik.values,
                                e.target.value
                              );
                            }}
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label-heading" htmlFor={"teacher"}>
                            Teachers
                          </label>
                          <div className={styles.relative}>
                            <Field
                              as="select"
                              name="teacher"
                              id="teacher"
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                                this.handleInput(
                                  e,
                                  "teacher",
                                  formik.values,
                                  ""
                                );
                              }}
                            >
                              <option value="">Select</option>
                              {teacherOptions && teacherOptions.length > 0 && (
                                <option value="all">Invite All Teachers</option>
                              )}
                              {this.state.teachersList.map((option) => (
                                <option key={option._id} value={option.email}>
                                  {option.firstName +
                                    " " +
                                    option.lastName +
                                    " - " +
                                    option.email}
                                </option>
                              ))}
                            </Field>
                            <KeyboardArrowDownIcon
                              className={styles.drop_icon}
                            />
                          </div>
                          <ErrorMessage
                            component={TextError}
                            name={"teacher"}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        {/* <label className="label-heading" htmlFor={"Session"}>
                            Session
                          </label> */}
                        {/* <select class="form-control" id="exampleSelect">
                            <option>Classroom</option>
                            <option>Practical</option>
                          </select> */}
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            name="sessionType"
                            label="Session"
                            placeholder="Select Session Type"
                            options={[
                              { _id: 1, name: "Classroom" },
                              { _id: 2, name: "Practical" },
                            ]}
                            // value={data.sessionType}
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        {/* <select class="form-control" id="exampleSelect">
                            <option>Online Session</option>
                            <option>Offine Session</option>
                          </select> */}
                        <div className={styles.relative}>
                          <FormikControl
                            control="select"
                            name="sessionMode"
                            placeholder="Select Session Mode"
                            options={[
                              { _id: 1, name: "Online Session" },
                              { _id: 2, name: "Offine Session" },
                              { _id: 3, name: "Offine + Online Session" },
                            ]}
                            // optname={this.state.sessionMode}

                            onChange={(e) => {
                              this.setState({ sessionMode: e.target.value });
                            }}
                          />
                          <KeyboardArrowDownIcon className={styles.drop_icon} />
                        </div>
                      </div>

                      {this.state.sessionMode === "Offine Session" ||
                      this.state.sessionMode === "Offine + Online Session" ? (
                        <React.Fragment>
                          <div className="row ">
                            <div className="col-md-10 d-flex">
                              <div className="col-md-1 "
                                style={{
                                  marginRight: "27px",
                                  marginTop: "7px",
                                  width:"10%"
                                }}
                              >
                                <label className={`${styles.labelStyle}`}>
                                  Address
                                </label>
                              </div>
                              <div className=" w-100">
                              <select
                                className="form-select ps-4 pe-4 w-100"
                                value={this.state.address._id}
                                onChange={(e) => {
                                  console.log(e.target);
                                  const add = this.state.addressList.filter(
                                    (es) => es._id.toString() === e.target.value
                                  );
                                  // this.state.address = add[0]
                                  this.setState({
                                    address: add[0],
                                    facilities: add[0].facilities,
                                  });

                                  console.log(add[0]);
                                }}
                              >
                                <option value={"Select"} selected disabled>
                                  {"Select "}
                                </option>
                                {this.state.addressList.map((res, index) => (
                                  <option value={res._id} key={index}>
                                    {/* {res.houseNo + ", " + res.area + " near by " + res.nearBy + ", " + res.city + "-" + res.pincode + ", " + res.state + ", " + res.country} */}
                                    {res.title}
                                  </option>
                                ))}
                              </select>
                              </div>
                            </div>
                          </div>
                          <div className="row my-3 m-0 align-items-center">
                            <div
                              className="col-md-1 text-nowrap"
                              style={{
                                marginRight: "27px",
                              }}
                            >
                              <label className={`${styles.labelStyle}`}>
                                Facilities
                              </label>
                            </div>
                            <div className="col-9 ps-2 d-flex gap-3 flex-wrap">
                              {this.state?.facilities?.length > 0 ? (
                                <div
                                  className="border  rounded py-1 px-2"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                    width:"98%"
                                  }}
                                >
                                  {this.state?.facilities?.join(", ")}
                                </div>
                              ) : (
                                // <div className="col-9 ps-2 d-flex gap-3 flex-wrap">
                                <div
                                  className="border  rounded py-3 px-2"
                                  style={{
                                    fontSize: "16px",
                                    textTransform: "capitalize",
                                    width:"98%"
                                  }}
                                ></div>
                                // </div>
                              )}
                              {/* {this.state?.facilities?.map((res) => {
                                return (
                                  <div
                                    style={{

                                      fontSize: "16px",
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    {res}
                                  </div>
                                )
                              })
                              } */}
                            </div>
                          </div>

                          {/* <span
                            style={{
                              marginLeft: "25px",
                              marginRight: "70px",
                              fontSize: "16px",
                            }}
                          >
                            Fan
                            <input
                              type="Checkbox"
                              value="Fan"
                              onChange={(e) => {
                                this.showvalue1(e);
                              }}
                              style={{ marginLeft: "5px" }}
                            ></input>
                          </span>

                          <span
                            style={{
                              marginLeft: "25px",
                              marginRight: "70px",
                              fontSize: "16px",
                            }}
                          >
                            Cooler
                            <input
                              type="Checkbox"
                              value="Cooler"
                              onChange={(e) => {
                                this.showvalue1(e);
                              }}
                              style={{ marginLeft: "5px" }}
                            ></input>
                          </span>

                          <span
                            style={{
                              marginLeft: "25px",
                              marginRight: "70px",
                              fontSize: "16px",
                            }}
                          >
                            Refrigerator
                            <input
                              type="Checkbox"
                              value="Refrigerator"
                              onChange={(e) => {
                                this.showvalue1(e);
                              }}
                              style={{ marginLeft: "5px" }}
                            ></input>
                          </span> */}
                        </React.Fragment>
                      ) : null}

                      <div className="col-md-12">
                        <FormikControl
                          control="textarea"
                          type="text"
                          name="description"
                          label="Description"
                          rows="5"
                        />
                      </div>

                      <div className="col-md-12">
                        <FormikControl
                          control="date"
                          label="Date"
                          name="date"
                          minDate={new Date()}
                        />
                      </div>

                      <div className="col-md-6">
                        <FormikControl
                          control="date"
                          label="Start Time"
                          name="startTime"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time (GMT +5:30)"
                          dateFormat="hh:mm a"
                          timeFormat="hh:mm a"
                        />
                      </div>
                      <div className="col-md-6">
                        <FormikControl
                          control="date"
                          label="End Time"
                          name="endTime"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time (GMT +5:30)"
                          dateFormat="hh:mm a"
                          timeFormat="hh:mm a"
                        />
                      </div>

                      <div className="text-center col-md-12">
                        <button
                          type="submit"
                          className="btn btn-sm mt-4"
                          style={{
                            borderRadius: "40px",
                            backgroundColor: "#4A1D75",
                            border: "2px solid #4A1D75",
                            width: "140px",
                            textAlign: "center",
                            padding: "6px",
                            color: "white",
                            height: "auto",
                          }}
                          onClick={() => this.handleSubmit(formik)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
        {/* <LoadingModal visible={this.state.isLoading} /> */}
        {isLoading && <LoadingModal visible={isLoading} />}
      </React.Fragment>
    );
  }
}

export default CreateSession;
