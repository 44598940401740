import React, { useEffect, useState } from 'react'
import moment from "moment";
import { Link } from "react-router-dom";
import { getAssignments } from '../../../services/Teacher/AssignmentService';
import { assignCurrculum } from '../../../services/Authentication/AuthService';

export default function StudentReoprtFileOne(props) {
    // const { assignmentList } = props;
    // console.log(props.assignmentList);

  const [assignmentList,setAssignmentList] = useState([])

  const  getAllAssignment = ()=>{
      const payload = {
        assignCurrculum: assignCurrculum(),
        type:"report"
      };
      getAssignments(payload)
        .then((res) => {
          console.log(res.data.data);
          setAssignmentList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }
  useEffect(()=>{

    getAllAssignment();
  },[])
  return (
    <div className="row">
      <div className="session_heading mt-2 w-100">
        <h1 className="form-heading" style={{ fontFamily: "roboto" }}>
          Student Report
        </h1>
      </div>
      <div className="col-md-12 p-3 table_container">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ fontFamily: "roboto" }}>ID</th>
                  <th style={{ fontFamily: "roboto" }}>Assessment Name</th>
                  <th style={{ fontFamily: "roboto" }}>Type</th>
                  <th style={{ fontFamily: "roboto" }}>Curriculum</th>
                  <th style={{ fontFamily: "roboto" }}>Start Date</th>
                  <th style={{ fontFamily: "roboto" }}>End Date</th>
                  <th style={{ fontFamily: "roboto" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {assignmentList &&
                  assignmentList.length > 0 &&
                  assignmentList.map((a) => {
                    return (
                      <tr key={a._id}>
                        <td style={{ fontFamily: "roboto" }}>{a.testID}</td>
                        <td style={{ fontFamily: "roboto" }}>
                          {a.assessmentName}
                        </td>
                        <td style={{ fontFamily: "roboto" }}>{a.type}</td>
                        <td style={{ fontFamily: "roboto" }}>{a.curriculum}</td>
                        <td style={{ fontFamily: "roboto" }}>
                          {moment(a.startdate).format("MM/DD/YYYY")}
                        </td>
                        <td style={{ fontFamily: "roboto" }}>
                          {moment(a.enddate).format("MM/DD/YYYY")}{" "}
                        </td>
                        <td>
                          <Link
                            to={`/teacher/student-report/${a.testID}/${a.curriculumCode}`}
                          >
                            <button
                              className="btn btn-sm btn-primary view_icon"
                              style={{ fontFamily: "roboto" }}
                            >
                              <i class="fa-regular fa-eye"></i>
                              View
                            </button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
