import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import routerService from "./TeachersRoutes";
import Sidebar from "../../components/Teachers/Sidebar/Sidebar";
import Topbar from "../../components/Teachers/Topbar/Topbar";
import "../../styles/student.css";

class TeacherLayout extends Component {
  render() {
    const { location, match } = this.props;
    return (
      <React.Fragment>
        {location.pathname === "/student/login" ||
        location.pathname === "/student/register" ||
        location.pathname === "/student/register-success" ? (
          <React.Fragment>
            {routerService &&
              routerService.map((route, key) => {
                return (
                  <Route
                    key={key}
                    path={`${match.url}/${route.path}`}
                    component={route.component}
                  />
                );
              })}
          </React.Fragment>
        ) : (
          <div
            className="content-wrapper"
           /*  style={{ minHeight: window.innerHeight - 60 }} */
           style={{minHeight:'100vh'}}
          >
            <Topbar />
            <Sidebar />
            {routerService &&
              routerService.map((route, key) => {
                return (
                  <Route
                    key={key}
                    path={`${match.url}/${route.path}`}
                    component={route.component}
                  />
                );
              })}
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withRouter(TeacherLayout);
