import style from "./hrModule.css";
export default function HrModule() {
  return (
    <div className="container">
      <div style={{padding:"16px 0px", marginBottom:"10px"}}>
       <h3 className="mb-0" style={{ color: "#4A1D75", fontFamily: "roboto" }}>HR Module</h3>
       </div>
      {/* <div class="input-group row col-12   mb-2 ">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <img
              src="https://img.icons8.com/search"
              style={{ maxWidth: "40%" }}
            />
          </div>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Search Staff/roles"
        />
      </div> */}
      <div class="search" style={{ marginBottom: "10px" }}>
        <span class="fa fa-search"></span>
        <input
          placeholder="Search"
          style={{ borderRadius: "30px" }}
          type="text"
          // value={search}
          // onChange={(text) => searchCurr(text)}
        />
      </div>
      <div className="col-md-12 table_container2">
        {/* <div className="card"> */}
        <div className="card-body">
          <table className="table table-">
            <thead>
              <tr style={{ width: "200%" }}>
                <th
                  style={{
                    color: "#20639B",
                    width: "10%",
                    fontSize: "12px",
                    fontFamily: "roboto",
                  }}
                >
                  staff/ roles
                </th>
                <th
                  style={{
                    color: "#20639B",
                    fontSize: "12px",
                    fontFamily: "roboto",
                  }}
                >
                  issues/complaints
                </th>
                <th
                  style={{
                    color: "#20639B",
                    width: "17%",
                    fontSize: "12px",
                    fontFamily: "roboto",
                  }}
                >
                  {" "}
                  feedback discussion
                </th>
                <th
                  style={{
                    color: "#20639B",
                    fontSize: "12px",
                    fontFamily: "roboto",
                  }}
                >
                  Payment
                </th>
                <th
                  style={{
                    color: "#20639B",
                    width: "35%",
                    fontSize: "12px",
                    fontFamily: "roboto",
                  }}
                >
                  Planing and Event Management
                </th>
                <th
                  style={{
                    color: "#20639B",
                    width: "25%",
                    fontSize: "12px",
                    fontFamily: "roboto",
                  }}
                >
                  Organization Management
                </th>
                <th
                  style={{
                    color: "#20639B",
                    width: "35%",
                    fontSize: "12px",
                    fontFamily: "roboto",
                  }}
                >
                  Alerts and Notification
                </th>
              </tr>
            </thead>
            <tr>
              <td>Student</td>
              <td>No issues</td>
              <td>Good</td>
              <td>Done</td>
              <td>
                Event Planning consists of coordinating every detail of meetings
                and conventions
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Student</td>
              <td>No issues</td>
              <td>Good</td>
              <td>Done</td>
              <td>
                Event Planning consists of coordinating every detail of meetings
                and conventions
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Student</td>
              <td>No issues</td>
              <td>Good</td>
              <td>Done</td>
              <td>
                Event Planning consists of coordinating every detail of meetings
                and conventions
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Student</td>
              <td>No issues</td>
              <td>Good</td>
              <td>Done</td>
              <td>
                Event Planning consists of coordinating every detail of meetings
                and conventions
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Student</td>
              <td>No issues</td>
              <td>Good</td>
              <td>Done</td>
              <td>
                Event Planning consists of coordinating every detail of meetings
                and conventions
              </td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}
