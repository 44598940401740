import DashboardContainer from "./DashboardContainer";
import Calenders from "../../components/Schedule/Calender";
import AssignmentContainer from "./AssignmentContainer";
import TecherProfileConatainer from "./TeacherProfileContainer";
import TeacherQueriesContainer from "./TeacherQueriesContainer";
import TeacherSession from "./TeacherSession";
import TeacherTestImage from "./TeacherTestImage";
import TeacherRepotContainer from "./TeacherRepotContainer";
import TestView from "../../components/Teachers/TestTracking/TestView";
import TestEditor from "../../components/Teachers/Assignment/TestEditor";
import StudentData from "../../components/Teachers/Assignment/StudentData";
import StudentReports from "../../components/Teachers/StudentReport/StudentReports";
// import StudentReportfile from "../../components/Teachers/StudentReport/StudentReportfile";
import StudentReoprtFileOne from "../../components/Teachers/StudentReport/StudentReoprtFileOne";

export default [
  {
    path: "dashboard",
    component: DashboardContainer,
  },
  {
    path: "calender",
    component: Calenders,
  },
  {
    path: "assignment-correction/:testId/:cc",
    component: AssignmentContainer,
  },
  {
    path: "assignments-list",
    component: AssignmentContainer,
  },
  {
    path: "profile",
    component: TecherProfileConatainer,
  },
  {
    path: "queries-list",
    component: TeacherQueriesContainer,
  },
  {
    path: "queries-inprogess",
    component: TeacherQueriesContainer,
  },
  {
    path: "queries-closed",
    component: TeacherQueriesContainer,
  },
  {
    path: "teacher-session",
    component: TeacherSession,
  },
  {
    path: "teacher-test-image",
    component: TeacherTestImage,
  },
  {
    path: "teacher-report-container",
    component: TeacherRepotContainer,
  },
  {
    path: "teacher-test-view/:id",
    component: TestView,
  },
  {
    path: "test-editor",
    component: TestEditor,
  },
  {
    path: "StudentData/:id/:testId",
    component: AssignmentContainer,
  },
  {
    path: "student-report/:testId/:cc",
    component: StudentReports,
  },
  {
    path: "student-report-one",
    component: StudentReoprtFileOne,
  },
];
