import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";

import { sessionUpdate } from "../api";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {
  userCurriculumCode,
  userEmail,
  userID,
  userInfo,
  userRole,
} from "../../../services/Authentication/AuthService";
import { sessionStatus } from "../../../services/Student/SessionService";
import { getMeeting } from "../../../services/Student/AssignmentService";
// import 
class TeacherEventInfoModal extends Component {




  state = {
    modalVisible: false,
    isLoading: false,
    dialogOpen: false,
    view: {}
  };

  constructor(props) {
    super(props);
    this.props.childRef(this);
  }

  handleClose = () => {
    this.setState({ modalVisible: false });
  };

  handleEventShow = () => {
    this.setState({ modalVisible: true });
  };
  statusChange = (data, status) => {
    const event = this.props.eventInfo;
    let startT = moment(event.end).format("YYYY-MM-DD HH:mm")
    //returnmoment(e.end).format("YYYY-MM-DD HH:mm")

    this.setState({ isLoading: true });

    let payload = {
      sessionID: event.sessionID,
      title: event.title,
      start: moment(event.start).format("YYYY-MM-DD HH:mm"),

      end: moment(event.end).format("YYYY-MM-DD HH:mm"),
      studentEmails: data.studentEmails,
      status: status,
      // email: 'support@advstechnosolutions.onmicrosoft.com'
      email: userEmail()
    }
    console.log('sessss upda-' + userID())
    if (status === 'REJECTED') {
      payload['requestedTeacher'] = event.requestedTeacher.filter(el => {

        return Number(el.userID) !== Number(userID())
      })
      payload['rejectedTeacher'] = event.requestedTeacher.filter(el => {

        return Number(el.userID) === Number(userID())
      })
    } else if (status === 'ACCEPTED') {
      payload['requestedTeacher'] = event.requestedTeacher.filter(el => {

        return Number(el.userID) === Number(userID())
      })
    }

    sessionStatus(payload).then(res => {
      if (res.status === 200 && res.data.status) {
        //getSession()
        this.setState({ modalVisible: false, isLoading: false });
        window.location.reload()
      } else {
        if (res.data.error) {
          this.setState({ modalVisible: false, isLoading: false });
          // alert(res.data.error)
          window.location.reload()
        } else {
          // alert(res.data.message)
          this.setState({ modalVisible: false, isLoading: false });
          window.location.reload()
        }

      }
    }).catch(err => {

    })
  }
  teacherAcceptEvent = async (e) => {
    // console.log(e)

   
    const event = this.props.eventInfo;
    event.status = "ACCEPTED";
    event.email = userEmail();
    // event.teamId=  userInfo().teamId
    console.log('user', event.teamId)

    event.acceptedTeacher = event.acceptedTeacher ? event.acceptedTeacher : [];

    event.acceptedTeacher.push(userInfo());
    console.log(event)
    event.role = userRole();
    this.setState({ isLoading: true });
    console.log(event)
    // return
    let payload = {
      sessionID: event.sessionID,
      title: event.title.trim(),
      start: (moment(event.start).format('yyyy-MM-DD hh-mm')).replace(' ','T'),
      end: (moment(event.start).format('yyyy-MM-DD hh-mm')).replace(' ','T'),
      studentEmails: event.studentEmails,
      status: event.status,
      // email: 'support@advstechnosolutions.onmicrosoft.com'
      // email: userEmail()
      email: userEmail(),
      requestedTeacher:event.acceptedTeacher,
      teacherId: userID(),
      curriculumCode: userCurriculumCode()
    }
  console.log(payload)
    // return   

    if (payload.status === 'REJECTED') {
      payload['requestedTeacher'] = payload.requestedTeacher.filter((el) => {

          return Number(el.userID) !== Number(userID())
      })

      // payload['rejectedTeacher'] = data.requestedTeacher.filter(el => {

      //     return Number(el.userID) === Number(userID())
      // })
  } else if (payload.status === 'ACCEPTED') {
      payload['requestedTeacher'] = payload.requestedTeacher.filter(el => {

          return Number(el.userID) === Number(userID())
      })
  }

    // console.log(moment(event.start).format('yyyy-MM-DD hh-mm'))
  

    await sessionStatus(payload).then(async (res) => {
     
      if (payload.status === 'ACCEPTED') {
        console.log(payload)
        // return
       await  getMeeting(payload).then(res => {
          console.log(res)
         
                // getSession()
                // setloading(false)
                this.setState({ modalVisible: false, isLoading: false });
                // window.location.reload()
            
        })
        console.log(payload)
    } else {
        // getSession()
        // setloading(false)
        this.setState({ modalVisible: false, isLoading: false });

    }
      console.log(res)
      window.location.reload()
    }).catch((err) => {
      console.log("err", err);
      this.setState({ modalVisible: false, isLoading: false });

    });


    return

    await sessionUpdate(event)
      .then((res) => {
        this.setState({ modalVisible: false, isLoading: false });
        console.log(res)
        // this.props.history.push("/teacher/calender");
        // eventStyleGetter()
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ modalVisible: false, isLoading: false });

      });
  };

  teacherRejectEvent = () => {
    const event = this.props.eventInfo;
    event.status = "REJECTED";
    event.email = userEmail();
    event.role = userRole();
    event.rejectedTeacher = event.rejectedTeacher ? event.rejectedTeacher : [];
    // console.log('user',info)
    event.rejectedTeacher.push(userInfo());
    this.setState({ isLoading: true });
    console.log('rejkecty')
    sessionUpdate(event)
      .then((res) => {
        this.setState({ modalVisible: false, isLoading: false });
        window.location.reload()

        this.props.history.push("/teacher/calender");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //  async componentDidMount(){
  //   teacherRejectEvent()
  //   teacherAcceptEvent()
  //  }

  render() {


    const e = this.props.eventInfo;

    let timee = new Date(e.end).getTime()
    console.log(timee, "data")
    let timePassed = false
    if (timee < Date.now()) {
      timePassed = true
    }
    return (
      <>
        <Dialog
          open={this.state.dialogOpen}
          onClose={!this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        // size="xl"
        >
          <DialogTitle id="alert-dialog-title">
            {"Location and Facilities"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">

              <h6>
                Building No. :{" "}
                {this.state.view.address ? this.state.view.address.houseNo : ""}{" "}
              </h6>
              <h6>
                Area :{" "}
                {this.state.view.address ? this.state.view.address.area : ""}{" "}
              </h6> <h6>
                Near By :{" "}
                {this.state.view.address ? this.state.view.address.nearBy : ""}{" "}
              </h6> <h6>
                University :{" "}
                {this.state.view.address ? this.state.view.address.university : ""}{" "}
              </h6> <h6>
                Campus :{" "}
                {this.state.view.address ? this.state.view.address.branch : ""}{" "}
              </h6> <h6>
                Room No. :{" "}
                {this.state.view.address ? this.state.view.address.room_no : ""}{" "}
              </h6> <h6>
                Floor :{" "}
                {this.state.view.address ? this.state.view.address.floor : ""}{" "}
              </h6> <h6>
                Block :{" "}
                {this.state.view.address ? this.state.view.address.block : ""}{" "}
              </h6>
              <h6>
                Country :{" "}
                {this.state.view.address ? this.state.view.address.country : ""}{" "}
              </h6>
              <h6>
                State :{" "}
                {this.state.view.address ? this.state.view.address.state : ""}{" "}
              </h6>
              <h6>
                City :{" "}
                {this.state.view.address ? this.state.view.address.city : ""}{" "}
              </h6>
              <h6>
                Facilities :{" "}
                {this.state.view.facilities ? this.state.view.facilities.join(", ") : ""}{" "}
              </h6>
              {this.state.view.mode && this.state.view.mode === "Offine + Online Session" && (<>
                <h6>
                  {console.log(e,"dasdsdad")}
                Meeting Link :{" "}
                      <a href={e.meetingLink} target="_blank"> Meeting Link</a>{" "}
                    </h6>
              </>)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-sm btn-info" onClick={() => this.setState({ dialogOpen: false })} autoFocus>
              Close
            </button>
          </DialogActions>
        </Dialog>





        <Modal
          size="md"
          show={this.state.modalVisible}
          onHide={this.handleClose}
          className="eventModal"
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title style={{ fontFamily: "roboto" }}>
              {e.title}
            </Modal.Title>
            <span
              onClick={this.handleClose}
              style={{
                // border: "2px solid black",
                cursor: "pointer",
                padding: "2px 10px",
                background: "rgb(233, 236, 239)",
              }}
            >
              {" "}
              X
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className="text-view">
              <label style={{ fontFamily: "roboto" }}>Curriculum</label>
              <span style={{ fontFamily: "roboto" }}>{e.curriculum}</span>
            </div>
            <div className="text-view">
              <label style={{ fontFamily: "roboto" }}>Subject</label>
              <span style={{ fontFamily: "roboto" }}>{e.subject}</span>
            </div>
            {/* <div className="text-view">
              <label>Topics</label>
              <span>{e.topics}</span>
            </div> */}
            <div className="text-view">
              <label style={{ fontFamily: "roboto" }}>Description</label>
              <span style={{ fontFamily: "roboto" }}>{e.desc}</span>
            </div>
            <div className="text-view">
              <label style={{ fontFamily: "roboto" }}>Start</label>
              <span style={{ fontFamily: "roboto" }}>
                {moment(e.start).format("YYYY-MM-DD hh:mm A")}
              </span>
            </div>
            <div className="text-view">
              <label style={{ fontFamily: "roboto" }}>End</label>
              <span style={{ fontFamily: "roboto" }}>
                {moment(e.end).format("YYYY-MM-DD hh:mm A")}
              </span>
            </div>
            {/* <div className="text-view">
              <label>Students</label>
              <span>
                <ol>
                  {e.studentEmails &&
                    e.studentEmails.map((item, i) => <li>{item}</li>)}
                </ol>
              </span>
            </div> */}
            <div className="text-view">
              <label style={{ fontFamily: "roboto" }}>Status</label>
              <span style={{ fontFamily: "roboto" }}>{e.status}</span>
            </div>
            <div className="col-md-12 text-center mt-3">
              {this.state.isLoading && (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner>
              )}
            </div>
            {(e.status === "PENDING" || e.status === "REJECTED") &&
              timePassed &&
              !this.state.isLoading && (
                <div className="text-view">
                  <span>Session date has passed</span>
                  <div style={{ width: "" }}>
                    <button
                      onClick={() => {
                        this.teacherAcceptEvent();
                      }}
                      // this.statusChange(e, 'ACCEPTED');
                      className="btn btn-primary btn-sm mr-8"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => {
                        this.statusChange(e, "REJECTED");
                      }}
                      className="btn btn-danger btn-sm ml-8 ms-4"
                      style={{ marginLeft: "40px" }}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              )}

            {(e.status === "PENDING" || e.status === "REJECTED") &&
              !timePassed &&
              !this.state.isLoading && (
                // <div className="text-center mt-3">
                <>
                  <button
                    onClick={() => {
                      this.teacherAcceptEvent(e.sessionMode.mode);
                      console.log(e)

                    }}
                    // this.statusChange(e, 'ACCEPTED');
                    className="btn btn-primary btn-sm mr-2"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => {
                      this.statusChange(e, "REJECTED");
                    }}
                    className="btn btn-danger btn-sm ms-4"
                  >
                    Reject
                  </button>
                </>
                // </div>
              )}

            {e.status === "ACCEPTED" && !this.state.isLoading && (
              <>
                {e.sessionMode.mode === 'Online Session' ? (
                  <>
                    <div className="text-view">
                      <label>Link join</label>
                      <span>
                        <button
                          className="btn btn-info"
                          onClick={() => window.open(e.meetingLink)}
                        >
                          Click to go to the class
                        </button>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-view">
                      <label>Location</label>
                      <span>
                        <button
                          className="btn btn-info"
                          onClick={() => {
                            this.setState({
                              modalVisible: false,
                              dialogOpen: true,
                              view: e.sessionMode
                            })

                          }}
                        >
                          View Location
                        </button>
                      </span>
                    </div>
                  </>
                )}


                
                  
              </>

              


            )}

{/* {e.status === "ACCEPTED" && !this.state.isLoading && (
              <>
                {e.sessionMode.mode === '"Offine + Online Session"' ? (
                  <>
                    <div className="text-view">
                      <label>Link join</label>
                      <span>
                        <button
                          className="btn btn-info"
                          onClick={() => window.open(e.meetingLink)}
                        >
                          Click to go to the class
                        </button>
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-view">
                      <label>Location</label>
                      <span>
                        <button
                          className="btn btn-info"
                          onClick={() => {
                            this.setState({
                              modalVisible: false,
                              dialogOpen: true,
                              view: e.sessionMode
                            })

                          }}
                        >
                          View Location
                        </button>
                      </span>
                    </div>
                  </>
                )}
                  
              </>


            )} */}


          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default TeacherEventInfoModal;
