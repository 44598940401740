import React, { useEffect, useState } from "react";
import moment from "moment";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import styles from "./Dashboard.module.css";
import DisplayErrorMessage from "../../Share/DisplayErrorMessage";
import { userCurriculum } from "../../../services/Authentication/AuthService";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import dash from "../../../assets/Banners/Banner1.png";
import upcomingimg from "../../../assets/Icons/IconAssignments.png";
import assignmentimg from "../../../assets/Icons/IconAssignments.png";
import upcomingsessionimg from "../../../assets/Icons/Iconupcomingsession.png";
import Iconmyqueries from "../../../assets/Icons/Iconmyqueries.png";
import { getUserCurriculum } from "../../../services/Student/CurriculumStudentService";
import image from "../../..//assets/Images/group3.png";
import cardImage from "../../..//assets/Images/Rectangle 5.png";
// import cardImage1 from "../../..//assets/Images/Rectangle 5(1).png";
import cardimg from "../../..//assets/Images/Rectangle 5 (1).png";
import currentImage from "../../..//assets/Images/ractangle.png";
import vector from "../../..//assets/Images/Vector.png";
import { getAllNews } from "../../../services/Admin/commonService";
import delhi from "../../..//assets/Images/delhi.png";
import amity from "../../..//assets/Images/amity.png";
import ChatBot from "../ChatBot/ChatBot";

const Dashboard = ({
  assignmentList,
  allQueries,
  allSessions,
  upcomingSession,
  upcomingSessionLIst,
  userInfo,
  userCurriculumsData,
}) => {
  const handle = useFullScreenHandle();
  const [upComming, setUpComming] = useState();
  const [latest, setLatest] = useState(null);
  const [newsData, setNewsData] = useState([]);


  //  let x=93;
  //   const dataaaaa = getUserCurriculum(x).then((res) => {
  //     console.log(res.data, "curdatatdataatdad");
  //   }).catch((err) => console.log(err))
  console.log("---", userCurriculumsData, "CUR dataaaaaaaaaaa");

  const recentDate = () => {
    let max;
    let date = upcomingSession;
    // console.log('data' + JSON.stringify(date))
    try {
      if (allSessions) {
        const value = date.sort((a, b) => {
          return new Date(b.start) - new Date(a.start);
          // if (a.start > b.start) {
          //   return b
          // } else {
          //   return a
          // }
        });
        let rev = value.reverse();
        setUpComming(rev);
        if (rev.length > 0) {
          setLatest(rev[0]);
        }
      }
    } catch (err) {
      console.log("errorr");
    }
  };
  useEffect(async () => {
    await getAllNews().then(res => setNewsData(res.data.data))
  }, []);
  useEffect(async () => {
    recentDate();
  }, [upcomingSession]);
  return (
    <React.Fragment>
      <div
        className={`col-md-12 ${styles.dashimg} mb-2 `}
        style={{ overflowX: "hidden" }}
      >
        <div className={styles.dashtext}>
          <div className={`${styles.text}`}>
            <span>LET INFORMATION FIND YOU</span>
          </div>
          <div className={`${styles.text1}`}>
            {/* <span className={`${styles.learnMedia}`}> */}
            <spna className={`${styles.learn}`}>Learn Anything</spna>
            &nbsp;&nbsp;&nbsp; <span className={`${styles.in}`}>in </span>
            {/* </span> */}
          </div>
          {/* <div> */}
          <span className={`${styles.new}`}>a New Way</span>
          {/* </div> */}
          <div className={`${styles.text2}`}>
            <span>
              Best learnings platform to find any topic you need, and get access
              to a wide range of master
            </span>
          </div>
          <div className={`${styles.course}`}>
            <span>course from professionals.</span>
          </div>
        </div>
        {/* <img src={image} /> */}
      </div>
      <br />
      <div className={styles.gani}>
        <div
          className="row px-1"
          style={{ marginRight: "35px", marginLeft: "10px" }}
        >
          <div className="col-md-12 ">
            <div className="pb-3">
              <h5 className={styles.data} style={{ fontFamily: "roboto" }}>
                News and Upcoming Events
              </h5>
            </div>
            {newsData.map((e) => (
              <>
                <div className="" style={{ marginBottom: "5px" }}>
                  <p className={styles.para1} style={{ fontFamily: "roboto" }}>
                    {" "}
                    {e.message}
                    <span>
                      <a href={e.path} target="_blank">
                        Click Here
                      </a>
                    </span>
                  </p>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="col-md-12 pad-0" style={{ overflowX: "hidden" }}>
        <div className="row">
          <div
            className={`${styles.studentdashCurrentCurriculum}`}
            // style={{
            //   display: "flex",
            //   justifyContent: "space-between",
            //   marginBottom: "10px",
            //   width: "100%",
            // }}
          >
            <div className="mt-3">
              <h5 className="ps-3" style={{ fontFamily: "roboto" }}>
                Current <span style={{ color: "#A17EF6" }}>Curriculum</span>
              </h5>
            </div>
            <div className="mt-3">
              {userCurriculumsData ? (
                <div className="viewMore ">
                  <Link
                    to={`/student/subcription-file`}
                    className={styles.viewstyle}
                    style={{
                      // marginBottom: "20px",
                      fontFamily: "roboto",
                    }}
                  >
                    Buy Curriculum
                  </Link>
                </div>
              ) : (
                <div className="viewMore">
                  <Link
                    to={`/Subscription`}
                    className={`btn btn-sm btn-primary${styles.buymore}`}
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#4A1D75",
                      border: "2px solid #4A1D75",
                      // width: "140px",
                      textAlign: "center",
                      padding: "6px",
                      color: "white",
                      height: "auto",
                      // marginBottom: "20px",
                      fontFamily: "roboto",
                    }}
                  >
                    Buy More Curriculum
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12  ps-3">
          <div
            className={styles.row1}
            // style={{ marginLeft: "12px", marginRight: "4px" }}
          >
            {userCurriculumsData
              ? userCurriculumsData.map((e, index) => {
                  return (
                    <div className={styles.main_box} key={index}>
                      <div className="d-flex justify-content-center mt-5">
                        <img src={e.imgLink} className={styles.box_img} />
                      </div>
                      <Link
                        to="/student/curriculum"
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className={styles.degname}
                          style={{ fontFamily: "roboto" }}
                        >
                          {e.organizationName}
                        </div>
                        <div className="">
                          <div className={` ${styles.heading}`}>
                            <img src={vector} className="" />
                            <div>
                              <span
                                className={styles.headingT}
                                style={{ fontFamily: "roboto" }}
                              >
                                {e.curriculum}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className="d-flex justify-content-center my-1">
                        <span
                          className={styles.code}
                          style={{ fontFamily: "roboto" }}
                        >
                          {e.curriculumCode}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between px-3 pt-3 pb-5">
                        <div
                          className={styles.datebox}
                          style={{ fontFamily: "roboto" }}
                        >
                          Start Date: {e.startDate && e.startDate.split("T")[0]}
                        </div>
                        <div
                          className={styles.datebox}
                          style={{ fontFamily: "roboto" }}
                        >
                          End Date: {e.startDate && e.endDate.split("T")[0]}
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>

        <br />
        <br />
        <div className="col-md-12 pad-0">
          {/* <div className="row"> */}
          <div className={`${styles.studentDash1}`}>
            <div>
              <h4
                className="text-heading ps-3"
                style={{ fontFamily: "roboto" }}
              >
                {/* <img src={assignmentimg} style={{ height: 24 }} /> */}
                Assessment
              </h4>
            </div>
            <div>
              <p className="viewMore">
                <Link
                  to={"/student/assignments"}
                  style={{ textDecoration: "none", textDecoration: "none" }}
                >
                  <span
                    className={`${styles.viewstyle}`}
                    style={{ fontFamily: "roboto" }}
                  >
                    View More
                  </span>
                  {/* <i className="las la-arrow-circle-right"></i> */}
                </Link>
              </p>
            </div>
          </div>
          <br />
          {/* </div> */}
          <div className={`ps-3 ${styles.row1}`}>
            {assignmentList && assignmentList.length === 0 && (
              <div className="col-md-12">
                <DisplayErrorMessage message="No assignments found" />
              </div>
            )}
            {assignmentList.length > 0 &&
              assignmentList.slice(0, 4).map((a) => {
                return (
                  <div className={styles.main_box} key={a._id}>
                    <div style={{ width: "" }}>
                      <img
                        src={cardImage}
                        style={{ width: "100%", height: "35vh" }}
                      />
                    </div>
                    <div className={`cardDivAss ${styles.assignmentDiv1}`}>
                      <Link
                        to={`/student/assignment-preview/${a.testID}`}
                        style={{ textDecoration: "none" }}
                      >
                        <h5
                          style={{
                            color: "black",
                            fontFamily: "roboto",
                          }}
                        >
                          {a.assessmentName}
                        </h5>
                      </Link>
                      {/* <h5>{a.assessmentName}</h5> */}
                      <p style={{ fontFamily: "roboto" }}>
                        Start Date - {moment(a.startdate).format("DD-MM-YYYY")}
                      </p>
                      <p style={{ fontFamily: "roboto" }}>
                        End Date - {moment(a.enddate).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="col-md-12 pad-0">
          {/* <div className="row"> */}
          <div className={`${styles.studentDash1}`}>
            <div>
              <h4
                className="text-heading ps-3"
                style={{ fontFamily: "roboto" }}
              >
                {" "}
                {/* <img src={upcomingsessionimg} style={{ height: 24 }} />  */}
                Upcoming <span style={{ color: "#A17EF6" }}>Sessions</span>
              </h4>
            </div>
            <div className="ps-3">
              <p className="viewMore">
                <Link
                  to={"/student/sessions"}
                  style={{ textDecoration: "none", fontFamily: "roboto" }}
                >
                  <span className={`${styles.viewstyle}`}> View More</span>
                </Link>
              </p>
            </div>
          </div>
          <br />
          {/* </div> */}
          <div className="row ps-3">
            {upcomingSessionLIst && upcomingSessionLIst.length === 0 && (
              <div className="col-md-12">
                <DisplayErrorMessage message="No sessions found" />
              </div>
            )}
            {upcomingSessionLIst &&
              upcomingSessionLIst.length > 0 &&
              upcomingSessionLIst.map((as) => {
                console.log("upcon000000000000000", as);
                return (
                  <div className="col-md-3">
                    <div>
                      <img
                        src={cardimg}
                        style={{ width: "18vw", height: "35vh" }}
                      />
                    </div>
                    <div className={`cardDivSess ${styles.sessionsDiv}`}>
                      <Link
                        to={`/student/session-preview/${as.sessionID}`}
                        style={{ textDecoration: "none" }}
                      >
                        <h5
                          style={{
                            // marginTop: "45px",
                            color: "black",
                            fontFamily: "roboto",
                          }}
                        >
                          {as.subject}
                        </h5>
                      </Link>
                      <p>{as.title}</p>
                      <span
                        style={{
                          backgroundColor: "none",
                          color: "black",
                          fontFamily: "roboto",
                        }}
                      >
                        {moment(as.start).format("DD-MM-YYYY")}
                      </span>{" "}
                      <br />
                      {/* <br /> */}
                      <span style={{ color: "black", fontFamily: "roboto" }}>
                        {moment(as.start).format("hh:mm A")}
                      </span>
                      -
                      <span style={{ color: "black", fontFamily: "roboto" }}>
                        {moment(as.end).format("hh:mm A")}
                      </span>
                      <br />
                      <Link to={`/student/session-preview/${as.sessionID}`}>
                        <button
                          className={`btn btn-sm ${styles.sessionbutton}`}
                          style={{ fontFamily: "roboto" }}
                        >
                          View
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="row">
          <div className={`${styles.studentDash}`}>
            <div>
              <h4
                className="text-heading1 ps-2"
                style={{ fontFamily: "roboto" }}
              >
                {/* <img src={Iconmyqueries} style={{ height: 24 }} /> */}
                My <span style={{ color: "#A17EF6" }}>Queries</span>
              </h4>
            </div>
            <div>
              <p className="viewMore">
                <Link
                  to={"/student/my-queries/open"}
                  style={{ textDecoration: "none", fontFamily: "roboto" }}
                >
                  <span className={`${styles.viewstyle}`}> View More </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="row ps-3" style={{}}>
          {allQueries && allQueries.length === 0 && (
            <div className="col-md-12">
              <DisplayErrorMessage message="No queries found" />
            </div>
          )}
          {allQueries.length > 0 &&
            allQueries.slice(0, 4).map((q) => {
              return (
                <div className="col-md-3" key={q._id}>
                  <Link to="/student/my-queries/open">
                    <div className={`cardDivMy ${styles.queryDiv}`}>
                      <div className="row">
                        <div title="Subjet" className={styles.subject}>
                          <h5 style={{ fontFamily: "roboto" }}>
                            {" "}
                            {q.subject} {"-"}
                          </h5>
                        </div>
                        <div
                          title="Topic"
                          className={styles.topic}
                          style={{ fontFamily: "roboto" }}
                        >
                          {q.topic}
                        </div>
                      </div>
                      <hr
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          borderTop: "1px solid #e8e8e8",
                        }}
                      />
                      <div
                        className="row"
                        style={{
                          // lineHeight: "1.5em",
                          // height: "3em",
                          overflow: "hidden",
                          fontFamily: "roboto",
                        }}
                      >
                        {parse(q.messages[0].message)}{" "}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      {/* <div style={{
        display:"inline-block",
        float:"right",
        position:"sticky",
        right:"20px",
        bottom:"20px",
        background:"violet",
        padding:"10px 20px",
        borderRadius:"20px",
        cursor:"pointer"
      }}>
        <h4 className="mb-0">Chat Now</h4>
      </div> */}
    </React.Fragment>
  );
};

export default Dashboard;
